import type HlsInstance from 'hls.js';
import type { HlsConfig } from 'hls.js';
import { Store, waitForStateUpdate } from '../store';
import { VideoPlaybackManager, AdEvent, PlaybackEvent, VideoEventMap, VideoEvent } from './index';
import { AdConfig, Settings, VideoData } from '../../types';
import { createMark, loadScript } from '../utils';
import * as loadState from '../loadState';
import {
    setHlsJsLoadState,
    showClickForSound,
    mute,
    setAutoplay,
    setIMALoadState,
    pauseAd,
    pauseVideo,
    playVideo,
    playAd,
    exitFullScreen,
    enterFullScreen,
    endVideoPlaybackInit
} from '../actions';
import { markVideoEvents } from '../perf-utils';
import { isMobile, isIOSDevice, isSafariMac, isIphone, isChromeIOS } from '../userAgentInfo';
import { AdPlaybackManager } from './ads';
// file-loader will make this import return a url to the worker file
//@ts-ignore
import hlsJsWorker from 'hls.js/dist/hls.worker.js';

const RETRY_LOAD_ATTEMPTS_MAX = 2;

let Hls: typeof HlsInstance;

export class HlsPlaybackManager implements VideoPlaybackManager {
    #adContainerEl: HTMLDivElement | null;
    #containerEl: HTMLDivElement | null;
    #videoEl: HTMLVideoElement | null;
    #store: Store;
    #settings: Settings;
    #hlsConfig: Partial<HlsConfig>;
    #hlsPlayer: HlsInstance | null = null;
    #isHlsSupported = false;
    #isRendered = false;
    #retryLoadAttempts: number = 0;
    #adPlaybackManager: AdPlaybackManager | null = null;
    #cmpAbortController: AbortController | null = null;
    #eventTarget: EventTarget = new EventTarget();

    constructor(store: Store, settings: Settings) {
        this.#adContainerEl = null;
        this.#videoEl = null;
        this.#containerEl = null;
        this.#store = store;
        this.#settings = settings;
        this.#hlsConfig = {
            autoStartLoad: true,
            capLevelToPlayerSize: true,
            enableWebVTT: true,
            maxMaxBufferLength: 30
        };
        if (this.#settings.startLevel) {
            this.#hlsConfig.startLevel = this.#settings.startLevel;
        }
        this.#isHlsSupported = settings.useHLS ?? true;
    }

    addEventListener<K extends keyof VideoEventMap>(
        type: K,
        listener: (event: VideoEventMap[K]) => void,
        options?: boolean | AddEventListenerOptions
    ): void {
        this.#eventTarget.addEventListener(type, listener as EventListener, options);
    }

    removeEventListener<K extends keyof VideoEventMap>(
        type: K,
        listener: (event: VideoEventMap[K]) => void,
        options?: boolean | EventListenerOptions
    ): void {
        this.#eventTarget.removeEventListener(type, listener as EventListener, options);
    }

    #dispatchEvent<K extends keyof VideoEventMap>(
        type: K,
        detail?: VideoEventMap[K] extends CustomEvent<infer D> ? D : never
    ) {
        this.#eventTarget.dispatchEvent(new CustomEvent(type, { detail }));
    }

    get bitrate(): number {
        if (this.#hlsPlayer && this.#hlsPlayer.currentLevel !== -1) {
            // @ts-ignore - Not sure why we're accessing a private field for bitrate
            return this.#hlsPlayer.levelController._levels[this.#hlsPlayer.currentLevel].bitrate;
        } else if (this.#videoEl?.videoWidth) {
            const bitrates = [
                { w: 416, b: 464 },
                { w: 640, b: 1264 },
                { w: 960, b: 1864 },
                { w: 1280, b: 2564 },
                { w: 1920, b: 5064 }
            ];
            let bitrate = 0;
            for (const bit of bitrates) {
                if (bit.w <= this.#videoEl.videoWidth) bitrate = bit.b;
            }
            return bitrate;
        }
        return 0;
    }

    get currentTime(): number {
        return this.#videoEl?.currentTime ?? 0;
    }

    get duration(): number {
        return this.#videoEl?.duration ?? 0;
    }

    get fullscreen(): boolean {
        if (!this.#videoEl || !this.#containerEl) return false;
        return this.#shouldUseWebkitFullscreen()
            ? (this.#videoEl as any).webkitDisplayingFullscreen
            : document.fullscreenElement === this.#containerEl;
    }

    get muted(): boolean {
        return this.#videoEl?.muted ?? false;
    }

    get paused(): boolean {
        return this.#videoEl?.paused ?? true;
    }

    get readyState(): number {
        return this.#videoEl?.readyState ?? 0;
    }

    get volume(): number {
        return this.#videoEl?.volume ?? 0;
    }

    attachElements(elements: Record<string, HTMLElement>): void {
        if (!elements.video) {
            throw new Error('Video element is required');
        }
        if (!elements.adContainer) {
            throw new Error('Ad container element is required');
        }
        if (!elements.container) {
            throw new Error('Container element is required');
        }

        this.#videoEl = elements.video as HTMLVideoElement;
        this.#adContainerEl = elements.adContainer as HTMLDivElement;
        this.#containerEl = elements.container as HTMLDivElement;
        this.#isRendered = true;

        if (this.#store.getState().imaLoadState === loadState.FINISHED) {
            this.#initAds();
        }

        // Add props directly to the video element after it's been rendered. This is necessary because some of the props don't get added correctly when hydrating server rendered content.
        if (this.#store.getState().autoplay === 'muted' && (isMobile() || this.#settings.clickForSound == true)) {
            this.#videoEl.autoplay = true;
            this.#videoEl.muted = true;
        }

        if (this.#settings.playInline) this.#videoEl.playsInline = true;
        if (this.#settings.loop) this.#videoEl.loop = true;

        if (this.#store.getState().isMuted) {
            this.#videoEl.muted = true;
        }

        // Hide certain elements before playback starts
        this.#videoEl.style.visibility = 'hidden';

        if (!this.#settings.disableHtmlControls) {
            this.#videoEl.controls = false;
        } else {
            if (!this.#settings.fireTv) this.#videoEl.controls = true;
            else this.#videoEl.controls = false;
        }

        if (this.#shouldUseWebkitFullscreen()) {
            this.#videoEl.addEventListener('webkitendfullscreen', () => {
                this.#dispatchEvent(VideoEvent.EXIT_FULLSCREEN);
            });
            this.#videoEl.addEventListener('webkitbeginfullscreen', () => {
                this.#dispatchEvent(VideoEvent.ENTER_FULLSCREEN);
            });
        } else {
            document.addEventListener('fullscreenchange', () => {
                if (document.fullscreenElement === this.#containerEl) {
                    this.#dispatchEvent(VideoEvent.ENTER_FULLSCREEN);
                } else {
                    this.#dispatchEvent(VideoEvent.EXIT_FULLSCREEN);
                }
            });
        }

        markVideoEvents(this.#videoEl);

        // Map from HTML video events to our custom events
        const videoToCustomEventMap: Record<string, PlaybackEvent> = {
            canplay: PlaybackEvent.CANPLAY,
            canplaythrough: PlaybackEvent.CANPLAYTHROUGH,
            click: PlaybackEvent.VIDEO_CLICK,
            dblclick: PlaybackEvent.VIDEO_DBLCLICK,
            durationchange: PlaybackEvent.DURATION_CHANGE,
            ended: PlaybackEvent.ENDED,
            error: PlaybackEvent.ERROR,
            loadeddata: PlaybackEvent.LOADEDDATA,
            loadedmetadata: PlaybackEvent.LOADEDMETADATA,
            pause: PlaybackEvent.PAUSE,
            play: PlaybackEvent.PLAY,
            playing: PlaybackEvent.PLAYING,
            resize: PlaybackEvent.RESIZE,
            seeked: PlaybackEvent.SEEKED,
            timeupdate: PlaybackEvent.TIMEUPDATE,
            volumechange: PlaybackEvent.VOLUME_CHANGE,
            waiting: PlaybackEvent.WAITING
        };

        // forward video events using our custom event types
        Object.entries(videoToCustomEventMap).forEach(([nativeEvent, customEvent]) => {
            let useCapture = false;
            if (isMobile() && nativeEvent === 'click') {
                useCapture = true;
            }
            this.#videoEl?.addEventListener(
                nativeEvent,
                (e) => {
                    // Ignore video events while in ad mode. This is needed when Google IMA uses our video element for ad playback.
                    if (this.#store.getState().isAdMode) return;
                    // Ignore pause event if video is not actually paused. The spec says pause will be set prior to this event being fired. Observed this behavior on iOS
                    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/pause_event
                    if (nativeEvent === 'paused' && !this.paused) return;
                    // Ignore play event if video is paused. The spec says pause will be set prior to this event being fired. Observed this behavior on iOS
                    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/play_event
                    if (nativeEvent === 'play' && this.paused) return;
                    this.#dispatchEvent(customEvent, e);
                },
                useCapture
            );
        });
    }

    getElements(): {
        video: HTMLVideoElement;
        adContainer: HTMLDivElement;
        container: HTMLDivElement;
    } {
        if (!this.#videoEl || !this.#adContainerEl || !this.#containerEl) {
            throw new Error('Elements not attached');
        }
        return {
            video: this.#videoEl,
            adContainer: this.#adContainerEl,
            container: this.#containerEl
        };
    }

    init(): Promise<any> {
        const promises = [];
        if (this.#settings.adsEnabled) {
            const scriptPromises = [];

            // Ads Lib
            if (typeof google === 'undefined' || typeof google.ima === 'undefined') {
                createMark('imaLoad:start');
                this.#store.dispatch(setIMALoadState(loadState.STARTED));
                scriptPromises.push(loadScript(this.#settings.imaLibrary).finally(() => createMark('imaLoad:end')));
            }

            if (typeof googleImaVansAdapter === 'undefined') {
                createMark('imaIasAdaptorLoad:start');
                scriptPromises.push(
                    loadScript(this.#settings.imaIasAdaptor)
                        .catch((error) => {
                            console.warn('Failed to load imaIasAdaptor script:', error);
                        })
                        .finally(() => createMark('imaIasAdaptorLoad:end'))
                );
            }

            if (typeof __iasPET === 'undefined') {
                createMark('imaIasOptimizationLoad:start');
                scriptPromises.push(
                    loadScript(this.#settings.imaIasOptimization)
                        .catch((error) => {
                            console.warn('Failed to load imaIasOptimization script:', error);
                        })
                        .finally(() => createMark('imaIasOptimizationLoad:end'))
                );
            }

            if (scriptPromises.length > 0) {
                promises.push(
                    Promise.all(scriptPromises)
                        .then(() => {
                            this.#store.dispatch(setIMALoadState(loadState.FINISHED));
                            if (this.#isRendered) {
                                this.#initAds();
                            }
                        })
                        .catch((error) => {
                            this.#dispatchEvent(AdEvent.AD_ERROR, { code: 0, msg: 'Ads Blocked' });
                            this.#store.dispatch(setIMALoadState(loadState.FAILED));
                        })
                );
            } else {
                // Ad scripts are already loaded
                if (this.#store.getState().imaLoadState === loadState.NONE) {
                    this.#store.dispatch(setIMALoadState(loadState.FINISHED));
                    if (this.#isRendered) {
                        this.#initAds();
                    }
                }
            }
        }

        // Hls.js
        if (this.#isHlsSupported) {
            if (typeof Hls === 'undefined') {
                this.#store.dispatch(setHlsJsLoadState(loadState.FINISHED));
                createMark('hlsJsLoad:start');
                const hlsJsPromise = import('hls.js')
                    .then((module) => {
                        Hls = module.default;
                        window.Hls = Hls;
                        this.#isHlsSupported = Hls.isSupported();
                    })
                    .catch((err) => {
                        console.error(err);
                        this.#store.dispatch(setHlsJsLoadState(loadState.FAILED));
                        this.#isHlsSupported = false;
                    });
                const hlsJsWorkerPromise = fetch(hlsJsWorker)
                    .then((res) => {
                        if (!res.ok) throw new Error(`Failed to load hls.js web worker: ${res.status}`);
                        return res.blob();
                    })
                    .then((blob) => {
                        this.#hlsConfig.workerPath = URL.createObjectURL(blob);
                    })
                    .catch((err) => console.error(err));
                promises.push(
                    Promise.all([hlsJsPromise, hlsJsWorkerPromise]).finally(() => {
                        if (this.#store.getState().hlsJsLoadState !== loadState.FAILED) {
                            this.#store.dispatch(setHlsJsLoadState(loadState.FINISHED));
                        }
                        createMark('hlsJsLoad:end');
                    })
                );
            } else if (this.#store.getState().hlsJsLoadState === loadState.NONE) {
                // Hls is already loaded so update the state
                this.#store.dispatch(setHlsJsLoadState(loadState.FINISHED));
            }
        }

        if (this.#store.getState().autoplay === true) {
            promises.push(
                import('../test-autoplay')
                    .then(({ testAutoplay }) =>
                        testAutoplay({ muted: false }).then((test) => {
                            if (!test.result) {
                                // Autoplay with sound is not allowed so we need to autoplay muted
                                const { isMuted, isClickForSoundVisible } = this.#store.getState();
                                if (!isClickForSoundVisible) {
                                    this.#store.dispatch(showClickForSound());
                                }
                                if (!isMuted) {
                                    this.setMute(true);
                                    this.#store.dispatch(mute());
                                }
                                this.#store.dispatch(setAutoplay('muted'));
                            }
                            return test;
                        })
                    )
                    .catch((err) => {
                        console.error('Failed to load autoplay test', err);
                    })
            );
        }

        return Promise.all(promises);
    }

    async loadContent(videoData: VideoData, signal?: AbortSignal): Promise<void> {
        if (!this.#videoEl) {
            throw new Error('Video element is not attached');
        }

        this.#videoEl.style.visibility = 'hidden';

        if (isIOSDevice() || this.#settings.fireTv || isSafariMac()) {
            this.#videoEl.setAttribute('src', videoData.hlsNoCaptions ?? '');
        } else {
            if (
                this.#settings.useWebm &&
                videoData.videoBestQualityWebmUrl &&
                this.#videoEl.canPlayType('video/webm; codecs="vp9"')
            ) {
                this.#videoEl.setAttribute('src', videoData.videoBestQualityWebmUrl);
            } else if (
                this.#settings.useHLS &&
                videoData.hlsNoCaptions &&
                !videoData.hlsNoCaptions.includes('wsjvod-i.akamaihd.net')
            ) {
                if (this.#hlsPlayer) {
                    this.#hlsPlayer.destroy();
                }
                this.#hlsPlayer = this.#createHlsPlayer(videoData);
                this.#hlsPlayer.loadSource(videoData.hlsNoCaptions);
                this.#hlsPlayer.attachMedia(this.#videoEl);
            } else {
                if (videoData.video664kMP4Url) {
                    this.#videoEl.setAttribute('src', videoData.video664kMP4Url);
                } else if (videoData.videoMP4List?.[2]) {
                    this.#videoEl.setAttribute('src', videoData.videoMP4List[2].url);
                } else {
                    this.#videoEl.setAttribute('src', videoData.videoMP4List?.[0].url ?? '');
                }
            }
        }

        const { startPosition } = this.#store.getState();
        if (startPosition > 0) {
            this.#videoEl.currentTime = startPosition;
        }

        if (videoData.captionsVTT && videoData.captionsVTT.length > 0) {
            this.#applyCaptions(videoData.captionsVTT);
        }

        const playContent = async () => {
            const waitForMetadata = () => {
                createMark('videoMetadataLoad:start');
                return new Promise<void>((resolve) => {
                    const innerResolve = () => {
                        createMark('videoMetadataLoad:end');
                        resolve();
                    };

                    if (this.#videoEl!.readyState >= 1) {
                        innerResolve();
                        return;
                    }

                    const handler = () => {
                        innerResolve();
                    };

                    signal?.addEventListener('abort', () => {
                        this.#videoEl!.removeEventListener('loadedmetadata', handler);
                        innerResolve();
                    });
                    this.#videoEl!.addEventListener('loadedmetadata', handler, { once: true });
                    this.#videoEl!.load();
                });
            };

            if (signal?.aborted) return;
            await waitForMetadata();

            if (signal?.aborted) return;
            await this.play();

            this.#videoEl!.style.visibility = 'visible';
        };

        // setup ad configs
        const prerollDelay = this.#settings.prerollDelay ?? 0;
        const adConfigs: AdConfig[] = [
            {
                type: 'preroll',
                time: prerollDelay > 0 ? prerollDelay + startPosition : 0,
                played: false,
                isRequested: this.#store.getState().adInitState !== loadState.NONE // The preroll ad may have been requested already
            }
        ];

        if (videoData.chapterTimes) {
            for (const midroll of videoData.chapterTimes.split(',')) {
                const time = parseInt(midroll, 10);
                // Ignore midrolls before the start position
                if (time < startPosition) continue;
                adConfigs.push({ type: 'midroll', time, played: false, isRequested: false });
            }
        }

        this.#adPlaybackManager?.updateAdConfigs(adConfigs);

        if (this.#shouldPlayAd() && adConfigs[0].time === 0) {
            let isReadyDispatched = false;
            const sendReady = () => {
                isReadyDispatched = true;
                this.#dispatchEvent(PlaybackEvent.READY, { shouldPlayAd: true });
            };

            try {
                this.#cmpAbortController?.abort();
                // Wait to send READY event until ad is just about to play
                this.#adPlaybackManager?.addEventListener(AdEvent.CONTENT_PAUSE_REQUESTED, sendReady, { once: true });
                await this.#adPlaybackManager?.start('preroll', signal);
                return playContent();
            } catch (e) {
                if (!isReadyDispatched) {
                    // Clean up previous event listener if it was not dispatched already
                    this.#adPlaybackManager?.removeEventListener(AdEvent.CONTENT_PAUSE_REQUESTED, sendReady);
                    sendReady();
                }
                return playContent();
            }
        } else {
            this.#dispatchEvent(PlaybackEvent.READY, { shouldPlayAd: false });
            return playContent();
        }
    }

    pause(): void {
        if (!this.#videoEl) return;
        if (this.#store.getState().isAdMode && this.#adPlaybackManager) {
            this.#adPlaybackManager.pause();
            this.#store.dispatch(pauseAd());
        } else {
            this.#videoEl.pause();
            this.#store.dispatch(pauseVideo());
        }
    }

    stop(): void {
        if (!this.#videoEl) return;

        // Pause playback
        this.pause();
        if (this.#adPlaybackManager) {
            this.#adPlaybackManager.destroy();
            this.#initAds(false);
        }

        // Reset video element
        this.#videoEl.style.visibility = 'hidden';
        this.#videoEl.removeAttribute('src');
        this.#videoEl.load();

        // Clean up HLS if it exists
        if (this.#hlsPlayer) {
            this.#hlsPlayer.destroy();
            this.#hlsPlayer = null;
        }

        const { isPlaybackInitializing, isPlaying } = this.#store.getState();
        if (isPlaybackInitializing) {
            this.#store.dispatch(endVideoPlaybackInit());
        }

        if (isPlaying) {
            this.#store.dispatch(pauseVideo());
        }
    }

    play(): Promise<void> {
        if (!this.#videoEl) {
            return Promise.resolve();
        }

        if (this.#store.getState().isAdMode && this.#adPlaybackManager) {
            this.#adPlaybackManager.play();
            this.#store.dispatch(playAd());
            return Promise.resolve();
        } else {
            this.#videoEl!.style.visibility = 'visible';
            const promise = this.#videoEl.play();
            this.#store.dispatch(playVideo());
            return promise;
        }
    }

    seek(time: number): void {
        if (!this.#videoEl) return;
        this.#videoEl.currentTime = time;
    }

    showHideClosedCaptions(show: boolean): void {
        if (!this.#videoEl) return;
        if (this.#videoEl.textTracks.length === 0) return;

        if (show) {
            this.#videoEl.textTracks[0].mode = 'showing';
        } else {
            this.#videoEl.textTracks[0].mode = 'hidden';
        }
    }

    setFullscreen(fullscreen: boolean): void {
        if (!this.#videoEl || !this.#containerEl) return;
        if (fullscreen) {
            this.#shouldUseWebkitFullscreen()
                ? (this.#videoEl as any).webkitEnterFullscreen()
                : this.#containerEl
                      .requestFullscreen()
                      .catch((error) => console.error('Failed to enter fullscreen:', error));
            this.#store.dispatch(enterFullScreen());
        } else {
            if (this.#shouldUseWebkitFullscreen()) {
                (this.#videoEl as any).webkitExitFullscreen();
            } else if (document.fullscreenElement === this.#containerEl) {
                document.exitFullscreen().catch((error) => console.error('Failed to exit fullscreen:', error));
            }
            this.#store.dispatch(exitFullScreen());
        }
    }

    setMute(muted: boolean): void {
        if (!this.#videoEl) return;
        if (this.#store.getState().isAdMode && this.#adPlaybackManager) {
            this.#adPlaybackManager.setMuted(muted);
        } else {
            // Unlike volume, we can't set muted when in ad mode because that causes the content video to start playing on iPad
            this.#videoEl.muted = muted;
        }
    }

    setVolume(level: number): void {
        if (!this.#videoEl) return;
        if (this.#store.getState().isAdMode && this.#adPlaybackManager) {
            this.#adPlaybackManager.setVolume(level);
        }
        this.#videoEl.volume = level;
    }

    #createHlsPlayer(videoData: VideoData): HlsInstance {
        const hlsPlayer = new Hls({ ...this.#hlsConfig, startPosition: this.#store.getState().startPosition });
        this.#retryLoadAttempts = 0;

        hlsPlayer.on(Hls.Events?.ERROR, (e, d) => {
            if (hlsPlayer && (d.fatal || d.details == 'bufferAppendingError')) {
                if (this.#retryLoadAttempts > RETRY_LOAD_ATTEMPTS_MAX) d.type = Hls.ErrorTypes.OTHER_ERROR;

                this.#retryLoadAttempts++;

                switch (d.type) {
                    case Hls.ErrorTypes.MEDIA_ERROR:
                        hlsPlayer.recoverMediaError();
                        break;
                    case Hls.ErrorTypes.NETWORK_ERROR:
                        hlsPlayer.loadSource(videoData.hlsNoCaptions as string);
                        hlsPlayer.startLoad();
                        break;
                    default:
                        this.#dispatchEvent(VideoEvent.FATAL_ERROR, d.details);
                        hlsPlayer.destroy();
                        break;
                }
            }
        });
        hlsPlayer.on(Hls.Events?.LEVEL_LOADED, (e, d) => {
            const newDuration = d.details.totalduration;
            if (
                newDuration > 0 &&
                newDuration !== this.#store.getState().duration &&
                this.#store.getState().videoData.state !== 'live'
            ) {
                this.#dispatchEvent(VideoEvent.DURATION_CHANGE, newDuration);
            }
        });
        hlsPlayer.on(Hls.Events?.LEVEL_SWITCHED, (e, d) => {
            if (hlsPlayer?.levels?.[d.level]) {
                this.#dispatchEvent(VideoEvent.BITRATE_CHANGE, this.bitrate);
            }
        });

        if (Hls.Events) {
            Object.keys(Hls.Events).forEach((event) => {
                // @ts-ignore - Can't figure out the types for Hls.Events
                hlsPlayer.on(Hls.Events[event], () => createMark(`hlsEvent:${event}`));
            });
        }
        return hlsPlayer;
    }

    #applyCaptions(CaptionsVtt: any[]): void {
        if (!this.#videoEl) {
            throw new Error('Video element is not attached');
        }

        const currentTracks = this.#videoEl.querySelectorAll('track');
        currentTracks.forEach((track) => track.remove());

        CaptionsVtt.forEach((caption) => {
            const track = document.createElement('track');
            track.kind = 'subtitles';
            track.label = caption.label;
            track.srclang = caption.lang;
            track.src = caption.url;
            track.addEventListener('load', () => {
                const textTrack = track.track;
                if (textTrack && textTrack.cues) {
                    for (let i = 0; i < textTrack.cues.length; i++) {
                        const cue = textTrack.cues[i] as VTTCue;
                        if (cue.line === 'auto') cue.line = -4;
                    }
                }
            });
            this.#videoEl?.appendChild(track);
        });
        this.#videoEl.textTracks[0].mode = 'showing';
    }

    async #initAds(preloadAd = true): Promise<void> {
        if (!this.#adContainerEl || !this.#videoEl) {
            throw new Error('Ad container or video element is not attached');
        }
        this.#adPlaybackManager = new AdPlaybackManager(this.#store, this.#settings, this);
        this.#adPlaybackManager.addEventListener(
            AdEvent.AD_START_REQUESTED,
            () => (this.#videoEl!.style.visibility = 'hidden')
        );
        this.#adPlaybackManager.addEventListener(AdEvent.AD_STARTED, (evt) => {
            if (evt.detail.isCustomPlayback) {
                this.#videoEl!.style.visibility = 'visible';
            }
        });

        // Forward events from the ad playback manager
        for (const event of Object.values(AdEvent)) {
            this.#adPlaybackManager.addEventListener(event, (e: any) => {
                this.#dispatchEvent(event, e.detail);
            });
        }
        this.#adPlaybackManager.init();

        // Start preloading preroll ad
        if (this.#shouldPlayAd() && preloadAd) {
            // Only wait for CMP if it's currently loading
            if (this.#store.getState().djcmpLoadState === loadState.STARTED) {
                try {
                    this.#cmpAbortController = new AbortController();
                    await waitForStateUpdate(this.#store, (state) => state.djcmpLoadState === loadState.FINISHED, {
                        signal: this.#cmpAbortController.signal
                    });
                } catch (e) {
                    if (e instanceof Error && e.name === 'AbortError') {
                        console.warn('Aborted waiting for CMP');
                    } else {
                        console.error('Unexpected error waiting for CMP', e);
                    }
                }
            }

            try {
                await this.#adPlaybackManager.requestAds();
            } catch (e) {
                console.error('Failed to request ads', e);
            }
        }
    }

    #shouldPlayAd(): boolean {
        const { isAdsBlocked, adInitState, videoData } = this.#store.getState();
        let shouldPlayAd =
            this.#settings.adsEnabled &&
            !isAdsBlocked &&
            adInitState !== loadState.FAILED &&
            videoData.catastrophic !== '1' &&
            videoData.adCategory !== 'catastrophic' &&
            videoData.adsAllowed !== false;
        return !!shouldPlayAd;
    }

    #shouldUseWebkitFullscreen(): boolean {
        return isIphone() || isChromeIOS();
    }
}

export function clearGlobalStateForTesting() {
    (Hls as any) = undefined;
    delete window.Hls;
}
