import { h, FunctionalComponent } from 'preact';
import { useRef, useEffect } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../../../hooks';
import shareIcon from '../../../../../svg/Icons_Share.svg?inline';
import { showShareScreen, hideShareScreen, setFocus } from '../../../../actions';

interface ShareButtonProps {
    videoId: string;
}

const shareIconHtml: { __html: string } = { __html: shareIcon };

const ShareButton: FunctionalComponent<ShareButtonProps> = ({ videoId }) => {
    const dispatch = useDispatch();
    const isShareModalVisible = useSelector((state) => state.isSharescreenVisible);
    const focusElement = useSelector((state) => state.focusElement);
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (focusElement === 'share-btn') {
            buttonRef.current?.focus();
            dispatch(setFocus(null));
        }
    }, [focusElement]);

    const handleClick = () => {
        if (isShareModalVisible) {
            dispatch(hideShareScreen());
        } else {
            dispatch(showShareScreen());
        }
    };

    return (
        <button
            ref={buttonRef}
            className="f-r"
            id={`video-share-b-${videoId}`}
            data-space="false"
            aria-label="Sharing"
            aria-haspopup="true"
            aria-controls={`video-share-${videoId}`}
            type="button"
            data-testid="video-share-button"
            dangerouslySetInnerHTML={shareIconHtml}
            onClick={handleClick}
            aria-pressed={isShareModalVisible}
        />
    );
};

export default ShareButton;
