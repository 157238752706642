import { createAction } from '@reduxjs/toolkit';
import * as actionTypes from './actionTypes';
import { AdConfig } from 'src/types';

export const startAdMode = createAction(actionTypes.AD_STARTED);
export const stopAdMode = createAction(actionTypes.AD_COMPLETE);
export const updateAdProgress = createAction(actionTypes.AD_PROGRESS);
export const updateAdDuration = createAction(actionTypes.AD_DURATION);
export const playAd = createAction(actionTypes.AD_PLAY);
export const pauseAd = createAction(actionTypes.AD_PAUSE);
export const adBuffering = createAction(actionTypes.AD_BUFFERING);
export const adCanPlay = createAction(actionTypes.AD_CAN_PLAY);
export const updateAdPlaysCount = createAction(actionTypes.UPDATE_AD_PLAYS_COUNT);
export const setAdConfigs = createAction<AdConfig[]>(actionTypes.SET_AD_CONFIGS);
