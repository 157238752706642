import { h, Component } from 'preact';
import { connect } from 'react-redux';
import PlayPauseButton from './Buttons/PlayPauseButton';
import VolumeControl from './Buttons/VolumeControl';
import FullScreenButton from './Buttons/FullScreenButton';
import SaveToQueueButton from './Buttons/SaveToQueueButton';
import ShareButton from './Buttons/ShareButton';
import TimeDisplay from './TimeDisplay';
import ScrubBar from './ScrubBar';

import ccInactive from '../../../svg/Icons_CC_Inactive.svg?inline';
import ccActive from '../../../svg/Icons_CC_Active.svg?inline';
import { isChromeIOS, isIpad } from '../../userAgentInfo';

class VideoControls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMounted: false
        };
    }

    componentDidMount() {
        this.setState({ isMounted: true });
    }

    componentWillUnmount() {
        this.setState({ isMounted: false });
    }

    render() {
        const { isMobile, videoData, isAdMode, currentBreakpoint, width } = this.props;
        const hideScrubBar = videoData.state === 'live' || videoData.state === 'flive' || isAdMode;
        const hideShareBtn =
            !this.props.shareEnabled ||
            videoData.state === 'live' ||
            videoData.state === 'flive' ||
            isAdMode ||
            currentBreakpoint === 'tiny';
        const hideFullScreeBtn = isAdMode && isChromeIOS() && isIpad();
        const scrubBarProps = {
            isMobile,
            videoId: this.props.videoId,
            onSeek: this.props.onSeek,
            showControls: this.props.showControls,
            enableScrubPreview: this.props.enableScrubPreview
        };

        const saveToQueueProps = {
            onSaveChange: this.props.onSaveChange,
            saveApiPublication: this.props.saveApiPublication,
            guid: this.props.videoData.guid
        };

        const volumeControlProps = {
            videoId: this.props.videoId,
            onSetVolume: this.props.onSetVolume,
            onMuteUnMute: this.props.onMuteUnMute,
            onVolumeControlVisible: this.props.onVolumeControlVisible
        };

        const ccHtml =
            this.state.isMounted && this.props.isClosedCaptionsVisible ? { __html: ccActive } : { __html: ccInactive };

        return (
            <div
                className={`video-controls-background ${
                    this.props.isControlsVisible ? 'video-fade-in' : 'video-fade-out'
                }`}
            >
                <div className={'video-controls'} id={`video-controls-${this.props.videoId}`}>
                    {!hideScrubBar && <ScrubBar {...scrubBarProps} />}
                    <PlayPauseButton onPlayPause={this.props.onPlayPause} />
                    <VolumeControl {...volumeControlProps} />
                    {width >= 275 && <TimeDisplay videoId={this.props.videoId} />}
                    <div class="filler"></div>
                    <div
                        className={'captions-list'}
                        id={`captions-list-${this.props.videoId}`}
                        data-space="false"
                    ></div>
                    {!hideShareBtn && <ShareButton videoId={this.props.videoId} />}
                    <button
                        className={'video-cc f-r'}
                        id={`video-cc-${this.props.videoId}`}
                        data-space="false"
                        aria-label="Captions"
                        aria-pressed={this.props.isClosedCaptionsVisible}
                        type="button"
                        data-testid={`video-cc-button-${this.props.isClosedCaptionsVisible ? 'on' : 'off'}`}
                        dangerouslySetInnerHTML={ccHtml}
                        onClick={this.props.onClosedCaptionsClicked}
                    />
                    {this.props.saveToQueueEnabled && <SaveToQueueButton {...saveToQueueProps} data-space="false" />}
                    {!hideFullScreeBtn && (
                        <FullScreenButton
                            videoId={this.props.videoId}
                            onToggleFullScreen={this.props.onToggleFullScreen}
                            data-space="false"
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        currentBreakpoint: state.currentBreakpoint,
        isControlsVisible: state.isControlsVisible,
        videoData: state.videoData,
        isAdMode: state.isAdMode,
        isPlaying: state.isPlaying,
        isVolumeControlVisible: state.isVolumeControlVisible,
        isClickForSoundVisible: state.isClickForSoundVisible,
        isClosedCaptionsVisible: state.isClosedCaptionsVisible,
        isSharescreenVisible: state.isSharescreenVisible,
        width: state.width
    }),
    {
        // mapDispatchToProps
    }
)(VideoControls);
