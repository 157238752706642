import { h, Component } from 'preact';

import styles from './styles.css';
import closeIcon from '../../../svg/close.svg?inline';

const closeHtml = { __html: closeIcon };

class Modal extends Component {
    constructor(props) {
        super(props);

        this._setCloseBtnRef = this._setCloseBtnRef.bind(this);
        this._handleTransitionEnd = this._handleTransitionEnd.bind(this);
        this._handleClose = this._handleClose.bind(this);
    }

    componentWillUnmount() {
        clearTimeout(this._closeTimer);
    }

    _setCloseBtnRef(el) {
        this._closeBtnRef = el;
    }

    _handleTransitionEnd() {
        const { onFadeIn, onFadeOut, isVisible } = this.props;
        if (isVisible) {
            onFadeIn();
        } else {
            onFadeOut();
        }
    }

    _handleClose(evt) {
        if (evt) evt.stopPropagation();
        this.props.onClose(evt);
    }

    componentDidMount() {
        if (this.props.hideTimer) this._closeTimer = setTimeout(() => this._handleClose(), this.props.hideTimer, this);
    }

    render() {
        const classNames = [styles.modal];
        const { hideCloseButton, isVisible, onCloseKeyDown, ...otherProps } = this.props;

        if (this.props.className) {
            classNames.push(this.props.className);
        }

        if (!isVisible) {
            classNames.push(styles.fadeOut);
        }

        const props = {
            ...otherProps,
            className: classNames.join(' ')
        };

        if (this.props.onClick) {
            props.onClick = this.props.onClick;
            props.style = { cursor: 'pointer' };
        }

        if (this.props.style && typeof this.props.style === 'object') {
            props.style = Object.assign({}, props.style, this.props.style);
        }

        return (
            <div {...props} onTransitionEnd={this._handleTransitionEnd}>
                {this.props.children}
                {!hideCloseButton && (
                    <button
                        className={styles.closeBtn}
                        ref={this._setCloseBtnRef}
                        onClick={this._handleClose}
                        onKeyDown={onCloseKeyDown}
                        tabIndex={isVisible ? '0' : '-1'}
                        dangerouslySetInnerHTML={closeHtml}
                    />
                )}
            </div>
        );
    }
}

Modal.defaultProps = {
    onClose() {},
    onCloseKeyDown() {},
    onFadeIn() {},
    onFadeOut() {},
    isVisible: true,
    hideCloseButton: false
};

export default Modal;
