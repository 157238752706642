import { Store } from '../store';
import { Settings } from '../../types';
import { createMark, customVendorCheck, waitForCMP } from '../utils';
import { getEventDuration, setCustomAttribute } from '../perf-utils';

export interface Tracker {
    trackInit(): void;
    trackAdClick(ad: google.ima.Ad): void;
    trackAdComplete(ad: google.ima.Ad): void;
    trackAdsManagerLoaded(adsManager: google.ima.AdsManager, adContainer: HTMLElement): void;
    trackAdPause(): void;
    trackAdPlay(): void;
    trackAdProgress(percentage: number, ad: google.ima.Ad): void;
    trackAdRemoved(): void;
    trackAdSkipped(): void;
    trackAdStarted(ad: google.ima.Ad): void;
    trackBitrateChange(): void;
    trackBufferStart(): void;
    trackBufferEnd(): void;
    trackClosedCaption(enabled: boolean): void;
    trackContentStart(): void;
    trackError(msg: string): void;
    trackPause(): void;
    trackProgress(percentage: number, time: number): void;
    trackPlay(): void;
    trackSeekEnd(): void;
    trackSeekStart(): void;
    trackSessionEnd(): void;
    trackShare(shareType: any): void;
    trackMoreVideosSlide(): void;
    trackEndscreenSlide(): void;
    trackThumbnailHover(showing: boolean, progress: number): void;
    trackStickyMode(isSticky?: boolean): void;
    trackInView(): void;
    trackSubscribeClick(): void;
    trackSubscribeShown(): void;
    trackSaveChange(isSaved: boolean): void;
    trackVideoComplete(): void;
    trackMuteUnMute(isMuted: boolean): void;
}

type TrackingMethod = keyof Tracker;
type TrackingArgs = any[];

export class TrackingManager implements Tracker {
    #innerTrackers: Tracker[];
    #isLoaded: boolean;
    #queue: Array<{ method: TrackingMethod; args: TrackingArgs }>;

    constructor(store: Store, settings: Settings) {
        this.#isLoaded = false;
        this.#innerTrackers = [];
        this.#queue = [];

        createMark('videoTrackersLoad:start');
        Promise.all<Tracker | null>([
            customVendorCheck('5ed7a9a9e0e22001da9d52ad').then((isVendorEnabled) => {
                if (isVendorEnabled) {
                    return import('./trackers/heartbeat')
                        .then((module) => new module.HeartbeatTracker(store, settings))
                        .catch((err) => {
                            console.error('Failed to load heartbeat', err);
                            return null;
                        });
                }
                return null;
            }),
            waitForCMP()
                .then(() =>
                    import('./trackers/comscore')
                        .then((module) => new module.ComscoreTracker(store))
                        .catch((err) => {
                            console.error('Failed to load comscore', err);
                            return null;
                        })
                )
                .catch((err) => {
                    console.error('Timed out waiting for djcmp', err);
                    return null;
                }),
            import('./trackers/optimizely')
                .then((module) => new module.OptimizelyTracker())
                .catch((err) => {
                    console.error('Failed to load optimizely tracker', err);
                    return null;
                }),
            customVendorCheck('5eff0d77969bfa03746427eb').then((isVendorEnabled) => {
                if (isVendorEnabled) {
                    return import('./trackers/permutive')
                        .then((module) => new module.PermutiveTracker(store, settings))
                        .catch((err) => {
                            console.error('Failed to load permutive tracker', err);
                            return null;
                        });
                }
                return null;
            }),
            import('./trackers/cxense')
                .then((module) => new module.CxenseTracker(store))
                .catch((err) => {
                    console.error('Failed to load cxense tracker', err);
                    return null;
                }),
            import('./trackers/ias')
                .then((module) => new module.IASTracker(store, settings))
                .catch((err) => {
                    console.error('Failed to load IAS adaptor tracker', err);
                    return null;
                }),
            import('./trackers/parsely')
                .then((module) => new module.ParselyTracker(store))
                .catch((err) => {
                    console.error('Failed to load parsely tracker', err);
                    return null;
                }),
            import('./trackers/newrelic')
                .then((module) => new module.NewRelicTracker(store))
                .catch((err) => {
                    console.error('Failed to load new relic tracker', err);
                    return null;
                }),
            import('./trackers/utag')
                .then((module) => new module.UtagTracker(store, settings))
                .catch((err) => {
                    console.error('Failed to load utag tracker', err);
                    return null;
                })
        ])
            .then((modules) => {
                this.#isLoaded = true;
                this.#innerTrackers = modules.filter((module): module is Tracker => module !== null);
                this.#processQueue();
            })
            .catch((err) => {
                this.#isLoaded = true;
                console.error('Failed to load tracking modules', err);
            })
            .finally(() => {
                createMark('videoTrackersLoad:end');
                setCustomAttribute('video-trackers-load-duration', getEventDuration('videoTrackersLoad'));
            });
    }

    #processQueue() {
        while (this.#queue.length > 0) {
            const item = this.#queue.shift();
            if (item) {
                this.#executeMethod(item.method, ...(item.args as any));
            }
        }
    }

    #executeMethod<M extends TrackingMethod>(method: M, ...args: Parameters<Tracker[M]>) {
        this.#innerTrackers.forEach((tracker) => {
            if (typeof tracker[method] === 'function') {
                try {
                    (tracker[method] as Function).apply(tracker, args);
                } catch (e) {
                    console.error(`Failed to execute ${method} on tracker`, e);
                }
            }
        });
    }

    #track<M extends TrackingMethod>(method: M, ...args: Parameters<Tracker[M]>) {
        if (this.#isLoaded) {
            this.#executeMethod(method, ...args);
        } else {
            this.#queue.push({ method, args });
        }
    }

    trackAdClick(ad: google.ima.Ad): void {
        this.#track('trackAdClick', ad);
    }

    trackAdComplete(ad: google.ima.Ad): void {
        this.#track('trackAdComplete', ad);
    }

    trackAdsManagerLoaded(adsManager: google.ima.AdsManager, adContainer: HTMLElement): void {
        this.#track('trackAdsManagerLoaded', adsManager, adContainer);
    }

    trackAdPause(): void {
        this.#track('trackAdPause');
    }

    trackAdProgress(percentage: number, ad: google.ima.Ad): void {
        this.#track('trackAdProgress', percentage, ad);
    }

    trackAdPlay(): void {
        this.#track('trackAdPlay');
    }

    trackAdRemoved(): void {
        this.#track('trackAdRemoved');
    }

    trackAdSkipped(): void {
        this.#track('trackAdSkipped');
    }

    trackAdStarted(ad: google.ima.Ad) {
        this.#track('trackAdStarted', ad);
    }

    trackInit() {
        this.#track('trackInit');
    }

    trackBitrateChange(): void {
        this.#track('trackBitrateChange');
    }

    trackBufferStart(): void {
        this.#track('trackBufferStart');
    }

    trackBufferEnd(): void {
        this.#track('trackBufferEnd');
    }

    trackClosedCaption(enabled: boolean): void {
        this.#track('trackClosedCaption', enabled);
    }

    trackContentStart(): void {
        this.#track('trackContentStart');
    }

    trackError(msg: string): void {
        this.#track('trackError', msg);
    }

    trackPause(): void {
        this.#track('trackPause');
    }

    trackProgress(percentage: number, time: number) {
        this.#track('trackProgress', percentage, time);
    }

    trackPlay(): void {
        this.#track('trackPlay');
    }

    trackSeekEnd(): void {
        this.#track('trackSeekEnd');
    }

    trackSeekStart(): void {
        this.#track('trackSeekStart');
    }

    trackSessionEnd(): void {
        this.#track('trackSessionEnd');
    }

    trackShare(shareType: any) {
        this.#track('trackShare', shareType);
    }

    trackMoreVideosSlide() {
        this.#track('trackMoreVideosSlide');
    }

    trackEndscreenSlide() {
        this.#track('trackEndscreenSlide');
    }

    trackThumbnailHover(showing: boolean, progress: number) {
        this.#track('trackThumbnailHover', showing, progress);
    }

    trackStickyMode(isSticky?: boolean) {
        this.#track('trackStickyMode', isSticky);
    }

    trackInView() {
        this.#track('trackInView');
    }

    trackSubscribeClick() {
        this.#track('trackSubscribeClick');
    }

    trackSubscribeShown() {
        this.#track('trackSubscribeShown');
    }

    trackSaveChange(isSaved: boolean) {
        this.#track('trackSaveChange', isSaved);
    }

    trackVideoComplete(): void {
        this.#track('trackVideoComplete');
    }

    trackMuteUnMute(isMuted: boolean) {
        this.#track('trackMuteUnMute', isMuted);
    }
}

export default TrackingManager;
