export const RESIZE = 'RESIZE';
export const THUMBNAIL_SHOW = 'THUMBNAIL_SHOW';
export const THUMBNAIL_HIDE = 'THUMBNAIL_HIDE';
export const MORE_VIDEOS_SLIDE_SHOW = 'MORE_VIDEOS_SLIDE_SHOW';
export const MORE_VIDEOS_SLIDE_HIDE = 'MORE_VIDEOS_SLIDE_HIDE';
export const SHOW_ENDSCREEN = 'SHOW_ENDSCREEN';
export const HIDE_ENDSCREEN = 'HIDE_ENDSCREEN';
export const SHOW_SHARESCREEN = 'SHOW_SHARESCREEN';
export const HIDE_SHARESCREEN = 'HIDE_SHARESCREEN';
export const CONTROLS_SHOW = 'CONTROLS_SHOW';
export const CONTROLS_HIDE = 'CONTROLS_HIDE';
export const VIDEO_PLAY = 'VIDEO_PLAY';
export const VIDEO_PAUSE = 'VIDEO_PAUSE';
export const VIDEO_PROGRESS = 'VIDEO_PROGRESS';
export const VIDEO_DURATION = 'VIDEO_DURATION';
export const VIDEO_MUTE = 'VIDEO_MUTE';
export const VIDEO_UNMUTE = 'VIDEO_UNMUTE';
export const VIDEO_ENTER_FULLSCREEN = 'VIDEO_ENTER_FULLSCREEN';
export const VIDEO_EXIT_FULLSCREEN = 'VIDEO_EXIT_FULLSCREEN';
// this is the time between calling play() in startVideo() and when the ad starts
export const VIDEO_START_PLAYBACK_INIT = 'VIDEO_START_PLAYBACK_INIT';
export const VIDEO_END_PLAYBACK_INIT = 'VIDEO_END_PLAYBACK_INIT';
export const VIDEO_BUFFERING = 'VIDEO_BUFFERING';
export const VIDEO_CAN_PLAY = 'VIDEO_CAN_PLAY';
export const VIDEO_VOLUME = 'VIDEO_VOLUME';
export const UPDATE_VIDEO_DATA = 'UPDATE_VIDEO_DATA';
export const REPLACE_PLAYLIST = 'REPLACE_PLAYLIST';
export const DEQUEUE_PLAYLIST = 'DEQUEUE_PLAYLIST';
export const SHOW_COUNTDOWN = 'SHOW_COUNTDOWN';
export const HIDE_COUNTDOWN = 'HIDE_COUNTDOWN';
export const AD_STARTED = 'AD_STARTED';
export const AD_COMPLETE = 'AD_COMPLETE';
export const AD_PROGRESS = 'AD_PROGRESS';
export const AD_DURATION = 'AD_DURATION';
export const AD_PLAY = 'AD_PLAY';
export const AD_PAUSE = 'AD_PAUSE';
export const AD_BUFFERING = 'AD_BUFFERING';
export const AD_CAN_PLAY = 'AD_CAN_PLAY';
export const SET_AD_SKIP_ENABLED = 'SET_AD_SKIP_ENABLED';
export const SHOW_CLICK_FOR_SOUND = 'SHOW_CLICK_FOR_SOUND';
export const HIDE_CLICK_FOR_SOUND = 'HIDE_CLICK_FOR_SOUND';
export const SHOW_LOADING_SPINNER = 'SHOW_LOADING_SPINNER';
export const HIDE_LOADING_SPINNER = 'HIDE_LOADING_SPINNER';
export const UPDATE_VIDEO_API_LOAD_STATE = 'UPDATE_VIDEO_API_LOAD_STATE';
export const UPDATE_AD_ERROR_STATE = 'UPDATE_AD_ERROR_STATE';
export const UPDATE_AD_INIT_STATE = 'UPDATE_AD_INIT_STATE';
export const UPDATE_IMA_LOAD_STATE = 'UPDATE_IMA_LOAD_STATE';
export const UPDATE_HLS_JS_LOAD_STATE = 'UPDATE_HLS_JS_LOAD_STATE';
export const SET_FOCUS = 'SET_FOCUS';
export const SET_STICKY_MODE = 'SET_STICKY_MODE';
export const SHOW_SAVED_MODAL = 'SHOW_SAVED_MODAL';
export const HIDE_SAVED_MODAL = 'HIDE_SAVED_MODAL';
export const SET_VOLUME_CONTROL_VISIBILITY = 'SET_VOLUME_CONTROL_VISIBILITY';
export const SHOW_CLOSED_CAPTIONS = 'SHOW_CLOSED_CAPTIONS';
export const HIDE_CLOSED_CAPTIONS = 'HIDE_CLOSED_CAPTIONS';
export const VISIBILITY_CHANGE = 'VISIBILITY_CHANGE';
export const UPDATE_AUTOPLAY = 'UPDATE_AUTOPLAY';
export const UPDATE_FLOATING = 'UPDATE_FLOATING';
export const PLAYER_INITIALIZED = 'PLAYER_INITIALIZED';
export const UPDATE_FLOATING_BLOCKED = 'UPDATE_FLOATING_BLOCKED';
export const SHOW_SUBSCRIBESCREEN = 'SHOW_SUBSCRIBESCREEN';
export const HIDE_SUBSCRIBESCREEN = 'HIDE_SUBSCRIBESCREEN';
export const UPDATE_BITRATE = 'UPDATE_BITRATE';
export const UPDATE_HAS_CHAINED = 'UPDATE_HAS_CHAINED';
export const UPDATE_HAS_AUTOPLAYED = 'UPDATE_HAS_AUTOPLAYED';
export const UPDATE_FORMAT_OVERRIDE = 'UPDATE_FORMAT_OVERRIDE';
export const UPDATE_LAST_SAVED_PROGRESS = 'UPDATE_LAST_SAVED_PROGRESS';
