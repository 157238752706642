import UAParser from 'ua-parser-js';
import { isIOS } from './utils';

const uaParser = new UAParser();
const cachedResults = uaParser.getResult();

export const isSafariMac = () => cachedResults.browser.name === 'Safari';
export const isIphone = () => cachedResults.ua.toLowerCase().includes('iphone');
export const isIpad = () => {
    return isIOS() && cachedResults.device.type === 'tablet';
};
export const isIOSDevice = () => isIOS();
export const isMobile = () => {
    const deviceType = cachedResults.device.type;
    return deviceType === 'mobile' || deviceType === 'tablet';
};
export const isChromeIOS = () => {
    const isChrome = cachedResults.browser.name === 'Chrome';
    return isIOSDevice() && isChrome && isMobile();
};
export const isCrawlerBot = () => cachedResults.ua.includes('Googlebot');
