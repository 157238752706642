import { createAction } from '@reduxjs/toolkit';
import * as actionTypes from './actionTypes';
import { VideoData } from '../../types';
import { LoadState } from '../loadState';
import { State } from '../store';

export * from './controls';
export * from './ads';

function debugFn() {
    console.trace();
    return {};
}

export function resize(width: number, height: number) {
    return {
        type: actionTypes.RESIZE,
        width,
        height
    };
}

export const showThumbnail = createAction(actionTypes.THUMBNAIL_SHOW);
export const hideThumbnail = createAction(actionTypes.THUMBNAIL_HIDE);

export function updateVideoData(videoData: VideoData) {
    return { type: actionTypes.UPDATE_VIDEO_DATA, videoData };
}

export const showMoreVideosSlide = createAction(actionTypes.MORE_VIDEOS_SLIDE_SHOW);
export const hideMoreVideosSlide = createAction(actionTypes.MORE_VIDEOS_SLIDE_HIDE);

export const showEndscreen = createAction(actionTypes.SHOW_ENDSCREEN);
export const hideEndscreen = createAction(actionTypes.HIDE_ENDSCREEN);

export const showShareScreen = createAction(actionTypes.SHOW_SHARESCREEN);
export const hideShareScreen = createAction(actionTypes.HIDE_SHARESCREEN);

export function replacePlaylist(playlist: VideoData[]) {
    return { type: actionTypes.REPLACE_PLAYLIST, playlist };
}

export const dequeuePlaylist = createAction(actionTypes.DEQUEUE_PLAYLIST);

export const showCountdown = createAction(actionTypes.SHOW_COUNTDOWN);
export const hideCountdown = createAction(actionTypes.HIDE_COUNTDOWN);

export const showClickForSound = createAction(actionTypes.SHOW_CLICK_FOR_SOUND);
export const hideClickForSound = createAction(actionTypes.HIDE_CLICK_FOR_SOUND);

export const showClosedCaptions = createAction(actionTypes.SHOW_CLOSED_CAPTIONS);
export const hideClosedCaptions = createAction(actionTypes.HIDE_CLOSED_CAPTIONS);

export const setFocus = createAction<string | null>(actionTypes.SET_FOCUS);

// TODO: Remove these at some point. Only here to toggle the loading spinner in old code that I don't fully understand
export const showLoadingSpinner = createAction(actionTypes.SHOW_LOADING_SPINNER);
export const hideLoadingSpinner = createAction(actionTypes.HIDE_LOADING_SPINNER);

export const setVideoApiLoadState = createAction(actionTypes.UPDATE_VIDEO_API_LOAD_STATE);
export const setAdInitState = createAction<LoadState>(actionTypes.UPDATE_AD_INIT_STATE);
export const setIMALoadState = createAction<LoadState>(actionTypes.UPDATE_IMA_LOAD_STATE);
export const setAdError = createAction(actionTypes.UPDATE_AD_ERROR_STATE);
export const setHlsJsLoadState = createAction<LoadState>(actionTypes.UPDATE_HLS_JS_LOAD_STATE);
export const startVideoPlaybackInit = createAction(actionTypes.VIDEO_START_PLAYBACK_INIT);
export const endVideoPlaybackInit = createAction(actionTypes.VIDEO_END_PLAYBACK_INIT);
export const buffering = createAction(actionTypes.VIDEO_BUFFERING);
export const canPlay = createAction(actionTypes.VIDEO_CAN_PLAY);

export const showSavedModal = createAction(actionTypes.SHOW_SAVED_MODAL);
export const hideSavedModal = createAction(actionTypes.HIDE_SAVED_MODAL);

export const setVisiblity = createAction(actionTypes.VISIBILITY_CHANGE);
export const setAutoplay = createAction<State['autoplay']>(actionTypes.UPDATE_AUTOPLAY);

export const setFloating = createAction<boolean>(actionTypes.UPDATE_FLOATING);
export const setFloatingBlocked = createAction<boolean>(actionTypes.UPDATE_FLOATING_BLOCKED);

export const initializePlayer = createAction(actionTypes.PLAYER_INITIALIZED);

export const showSubscribeScreen = createAction(actionTypes.SHOW_SUBSCRIBESCREEN);
export const hideSubscribeScreen = createAction(actionTypes.HIDE_SUBSCRIBESCREEN);

export const updateBitrate = createAction<number>(actionTypes.UPDATE_BITRATE);
export const updateHasChained = createAction<boolean>(actionTypes.UPDATE_HAS_CHAINED);
export const updateHasAutoplayed = createAction<boolean>(actionTypes.UPDATE_HAS_AUTOPLAYED);
export const updateFormatOverride = createAction<string | null>(actionTypes.UPDATE_FORMAT_OVERRIDE);
