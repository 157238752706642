import { h, FunctionalComponent } from 'preact';
import { useCallback } from 'preact/hooks';
import { useSelector, useDispatch } from 'react-redux';
import { hideClickForSound } from '../../actions';
import styles from './styles.css';
import clickForSoundIcon from '../../../svg/ClickForSound.svg?inline';

interface ClickForSoundProps {
    onClicked?: (evt: any) => void;
    isLiveButtonVisible?: boolean;
    videoId: string;
    isMobile?: boolean;
}

const ClickForSound: FunctionalComponent<ClickForSoundProps> = (props) => {
    const isAdMode = useSelector<any, boolean>((state) => state.isAdMode);
    const isCountdownVisible = useSelector<any, boolean>((state) => state.isCountdownVisible);
    const isClickForSoundVisible = useSelector<any, boolean>((state) => state.isClickForSoundVisible);
    const isThumbnailVisible = useSelector<any, boolean>((state) => state.isThumbnailVisible);
    const isLive = useSelector<any, boolean>((state) => state.videoData.state === 'live');
    const isControlsVisible = useSelector<any, boolean>((state) => state.isControlsVisible);
    const isMuted = useSelector<any, boolean>((state) => state.isMuted);
    const isStickyModeEnabled = useSelector<any, boolean>((state) => state.isStickyModeEnabled);
    const btnText = props.isMobile ? 'Tap for Sound' : 'Click for Sound';
    const clickForSoundIconHtml = { __html: clickForSoundIcon + btnText };

    const dispatch = useDispatch();

    const handleClick = useCallback(
        (evt: any) => {
            dispatch(hideClickForSound());
            props?.onClicked?.(evt);
        },
        [props.onClicked]
    );

    if (isCountdownVisible || !isClickForSoundVisible || isThumbnailVisible || !isMuted) return null;

    const classNames = [styles.clickForSoundWrapper];
    if (isLive && !isControlsVisible && props.isLiveButtonVisible) {
        classNames.push(styles.clickForSoundWrapperWithLive);
    }

    if (isAdMode && !isStickyModeEnabled) classNames.push(styles.clickForSoundWrapperWithAdvertisment);

    return (
        <button
            onClick={handleClick}
            id={`video-sound-${props.videoId}`}
            className={classNames.join(' ')}
            aria-label="Click for Sound"
            dangerouslySetInnerHTML={clickForSoundIconHtml}
        />
    );
};

ClickForSound.defaultProps = {
    onClicked() {},
    isLiveButtonVisible: false
};

export default ClickForSound;
