import { VideoData } from '../../types';

// Content playback events
export enum PlaybackEvent {
    CANPLAY = 'canplay',
    CANPLAYTHROUGH = 'canplaythrough',
    VIDEO_CLICK = 'click',
    VIDEO_DBLCLICK = 'dblclick',
    DURATION_CHANGE = 'durationchange',
    ENDED = 'ended',
    ERROR = 'error',
    LOADEDDATA = 'loadeddata',
    LOADEDMETADATA = 'loadedmetadata',
    PAUSE = 'pause',
    PLAY = 'play',
    PLAYING = 'playing',
    RESIZE = 'resize',
    SEEKED = 'seeked',
    /** Fired when an ad or the content is just about to start playing */
    READY = 'ready',
    TIMEUPDATE = 'timeupdate',
    VOLUME_CHANGE = 'volumechange',
    WAITING = 'waiting',
    FATAL_ERROR = 'fatalerror',
    BITRATE_CHANGE = 'bitratechange',
    ENTER_FULLSCREEN = 'enterfullscreen',
    EXIT_FULLSCREEN = 'exitfullscreen'
}

// Ad-related events
export enum AdEvent {
    AD_BUFFERING = 'adBuffering',
    AD_LOADED = 'adLoaded',
    AD_STARTED = 'adStarted',
    AD_PAUSED = 'adPaused',
    AD_RESUMED = 'adResumed',
    AD_COMPLETE = 'adComplete',
    AD_SKIPPED = 'adSkipped',
    AD_FIRST_QUARTILE = 'adFirstQuartile',
    AD_MIDPOINT = 'adMidpoint',
    AD_THIRD_QUARTILE = 'adThirdQuartile',
    AD_PROGRESS = 'adProgress',
    AD_CLICK = 'adClick',
    AD_MUTED = 'adMuted',
    AD_VOLUME_CHANGED = 'adVolumeChanged',
    CONTENT_PAUSE_REQUESTED = 'contentPauseRequested',
    CONTENT_RESUME_REQUESTED = 'contentResumeRequested',
    AD_ERROR = 'adError',
    AD_MANAGER_LOADED = 'adManagerLoaded',
    ALL_ADS_COMPLETED = 'allAdsCompleted',
    AD_REQUESTED = 'adRequested',
    AD_START_REQUESTED = 'adStartRequested',
    AD_BREAK_FETCH_ERROR = 'adBreakFetchError',
    AD_BREAK_READY = 'adBreakReady',
    AD_CAN_PLAY = 'adCanPlay',
    AD_METADATA = 'adMetadata',
    AD_DURATION_CHANGE = 'adDurationChange',
    AD_IMPRESSION = 'adImpression'
}

export const VideoEvent = Object.assign({}, PlaybackEvent, AdEvent);
export type VideoEvents = PlaybackEvent | AdEvent;

// Type map for playback events and their payloads
export interface PlaybackEventMap {
    [PlaybackEvent.CANPLAY]: CustomEvent<Event>;
    [PlaybackEvent.CANPLAYTHROUGH]: CustomEvent<Event>;
    [PlaybackEvent.VIDEO_CLICK]: CustomEvent<Event>;
    [PlaybackEvent.VIDEO_DBLCLICK]: CustomEvent<Event>;
    [PlaybackEvent.DURATION_CHANGE]: CustomEvent<number>;
    [PlaybackEvent.ENDED]: CustomEvent<Event>;
    [PlaybackEvent.ERROR]: CustomEvent<Event>;
    [PlaybackEvent.LOADEDDATA]: CustomEvent<Event>;
    [PlaybackEvent.LOADEDMETADATA]: CustomEvent<Event>;
    [PlaybackEvent.PAUSE]: CustomEvent<Event>;
    [PlaybackEvent.PLAY]: CustomEvent<Event>;
    [PlaybackEvent.PLAYING]: CustomEvent<Event>;
    [PlaybackEvent.RESIZE]: CustomEvent<Event>;
    [PlaybackEvent.SEEKED]: CustomEvent<Event>;
    [PlaybackEvent.READY]: CustomEvent<{ shouldPlayAd: boolean }>;
    [PlaybackEvent.TIMEUPDATE]: CustomEvent<Event>;
    [PlaybackEvent.VOLUME_CHANGE]: CustomEvent<Event>;
    [PlaybackEvent.WAITING]: CustomEvent<Event>;
    [PlaybackEvent.FATAL_ERROR]: CustomEvent<string>;
    [PlaybackEvent.BITRATE_CHANGE]: CustomEvent<number>;
    [PlaybackEvent.ENTER_FULLSCREEN]: CustomEvent<void>;
    [PlaybackEvent.EXIT_FULLSCREEN]: CustomEvent<void>;
}

export interface Ad {
    id: string;
    imaAd: google.ima.Ad;
    // TODO: Remove this in the next phase of the refactor
    isCustomPlayback: boolean;
}

export interface AdError {
    code?: number;
    msg?: string;
    ad?: Ad;
}

export interface AdProgressData {
    currentTime: number;
    duration: number;
    imaAd: google.ima.Ad | null;
}

// Type map for ad events and their payloads
export interface AdEventMap {
    [AdEvent.AD_BUFFERING]: CustomEvent<Ad>;
    [AdEvent.AD_LOADED]: CustomEvent<Ad>;
    [AdEvent.AD_STARTED]: CustomEvent<Ad>;
    [AdEvent.AD_PAUSED]: CustomEvent<Ad>;
    [AdEvent.AD_RESUMED]: CustomEvent<Ad>;
    [AdEvent.AD_COMPLETE]: CustomEvent<Ad>;
    [AdEvent.AD_SKIPPED]: CustomEvent<Ad>;
    [AdEvent.AD_FIRST_QUARTILE]: CustomEvent<Ad>;
    [AdEvent.AD_MIDPOINT]: CustomEvent<Ad>;
    [AdEvent.AD_THIRD_QUARTILE]: CustomEvent<Ad>;
    [AdEvent.AD_PROGRESS]: CustomEvent<AdProgressData>;
    [AdEvent.AD_CLICK]: CustomEvent<Ad>;
    [AdEvent.AD_MUTED]: CustomEvent<Ad>;
    [AdEvent.AD_VOLUME_CHANGED]: CustomEvent<Ad>;
    [AdEvent.CONTENT_PAUSE_REQUESTED]: CustomEvent<Ad>;
    [AdEvent.CONTENT_RESUME_REQUESTED]: CustomEvent<Ad>;
    [AdEvent.AD_ERROR]: CustomEvent<AdError>;
    [AdEvent.AD_MANAGER_LOADED]: CustomEvent<{
        adContainer: HTMLDivElement;
        adsManager: google.ima.AdsManager;
    }>;
    [AdEvent.ALL_ADS_COMPLETED]: CustomEvent<Ad>;
    [AdEvent.AD_REQUESTED]: CustomEvent<Ad>;
    [AdEvent.AD_START_REQUESTED]: CustomEvent<void>;
    [AdEvent.AD_BREAK_FETCH_ERROR]: CustomEvent<Ad>;
    [AdEvent.AD_BREAK_READY]: CustomEvent<Ad>;
    [AdEvent.AD_CAN_PLAY]: CustomEvent<Ad>;
    [AdEvent.AD_METADATA]: CustomEvent<Ad>;
    [AdEvent.AD_DURATION_CHANGE]: CustomEvent<Ad>;
    [AdEvent.AD_IMPRESSION]: CustomEvent<Ad>;
}

export type VideoEventMap = PlaybackEventMap & AdEventMap;

export interface TypedEventTarget<EventMap> {
    addEventListener<K extends keyof EventMap>(
        type: K,
        listener: (event: EventMap[K]) => void,
        options?: boolean | AddEventListenerOptions
    ): void;
    removeEventListener<K extends keyof EventMap>(
        type: K,
        listener: (event: EventMap[K]) => void,
        options?: boolean | EventListenerOptions
    ): void;
}

export interface VideoPlaybackManager extends TypedEventTarget<VideoEventMap> {
    readonly bitrate: number;
    readonly currentTime: number;
    readonly duration: number;
    readonly muted: boolean;
    readonly readyState: number;
    readonly volume: number;
    readonly fullscreen: boolean;
    readonly paused: boolean;

    init(): Promise<void>;
    attachElements(elements: Record<string, HTMLElement>): void;

    // Playback Controls
    play(): Promise<void>;
    pause(): void;
    stop(): void;
    setMute(muted: boolean): void;
    setFullscreen(fullscreen: boolean): void;
    showHideClosedCaptions(show: boolean): void;

    // Seeking & Time Controls
    seek(time: number): void;

    // Volume Controls
    setVolume(level: number): void;

    // Content Loading
    loadContent(videoData: VideoData, signal?: AbortSignal): Promise<void>;
}
