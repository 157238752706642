import type { LegacyApiMediaItem } from '@video/common';

export type Breakpoint = '16U' | '12U' | '8U' | '4U' | 'tiny';
export type Stage = 'prod' | 'nonprod';
export type ThumbLayout = string; // TODO: List out the specific strings

export type AdPosition = 'preroll' | 'midroll';

export interface AdConfig {
    type: AdPosition;
    time: number;
    played?: boolean;
    isRequested?: boolean;
}

export const ApiFieldsArray = [
    'adCategory',
    'adTagParams',
    'adZone',
    'adsAllowed',
    'aspectRatio',
    'author',
    'captionsVTT',
    'catastrophic',
    'chapterTimes',
    'column',
    'description',
    'doctypeID',
    'duration',
    'editor',
    'emailURL',
    'episodeNumber',
    'forceClosedCaptions',
    'format',
    'formattedCreationDate',
    'gptCustParams',
    'guid',
    'hls',
    'hlsNoCaptions',
    'isQAEvent',
    'iso8601CreationDate',
    'isSubscriberOnly',
    'keywords',
    'keywordsOmni',
    'linkRelativeURL',
    'linkShortURL',
    'linkURL',
    'mediaLiveChannelId',
    'name',
    'omniProgramName',
    'omniPublishDate',
    'omniVideoFormat',
    'provider',
    'rssURL',
    'secondsUntilStartTime',
    'seriesName',
    'showName',
    'sponsoredVideo',
    'lang',
    'state',
    'suppressAutoplay',
    'thumbnailImageManager',
    'thumbnailList',
    'thumbstripURL',
    'thumbstripVTTURL',
    'titletag',
    'touchCastID',
    'type',
    'video1264kMP4Url',
    'video174kMP4Url',
    'video1864kMP4Url',
    'video2564kMP4Url',
    'video320kMP4Url',
    'video664kMP4Url',
    'videoBestQualityWebmUrl',
    'videoMP4List',
    'videoStillURL',
    'wsj-section',
    'wsj-subsection',
    'wsj-packages',
    'factiva-subjects',
    'factiva-regions'
] as const;

export type ApiFields = (typeof ApiFieldsArray)[number];

export type VideoData = Pick<LegacyApiMediaItem, ApiFields> & { startAction?: string };

export interface UIProps extends Pick<Settings, 'enableStickyPlayer' | 'disableTitle' | 'stickyPlayerTheme'> {
    clickForSoundProps: {
        onClicked?: () => void;
        isCountdownVisible?: boolean;
        isLiveButtonVisible?: boolean;
    };
    controlsProps?: {
        enableScrubPreview: boolean;
        isMobile: boolean;
        onPlayPause?: (e?: any) => void;
        onMuteUnMute?: () => void;
        onSetVolume?: (vol: number) => void;
        onVolumeControlVisible?: (isVisible: boolean) => void;
        onToggleFullScreen?: () => void;
        onSeek?: (perc: number) => void;
        onClosedCaptionsClicked?: () => void;
        saveApi?: string;
        saveApiPublication?: string;
        shareDomain?: Settings['shareDomain'];
        shareEnabled?: Settings['shareEnabled'];
        onShare?: (platform: 'copylink' | 'embed' | 'Facebook' | 'Twitter') => void;
        showControls?: () => void;
        saveToQueueEnabled: boolean;
        onSaveChange?: (isSaved: boolean) => void;
    } | null;
    countdownProps: {
        videoApiUrl?: string;
        onCountdownComplete?: () => void;
    };
    disableTitle?: boolean;
    endscreenProps?: {
        videoApiUrl: string;
        fields: string;
        playerSettings: Record<string, unknown>;
        playVideo?: (e: any) => void;
        onEndsceenShown?: () => void;
    };
    liveButtonEnabled: boolean;
    moreVideosSlideProps?: {
        onSlideShowing?: () => void;
        playVideo?: (guid: string) => void;
    };
    saveToQueueEnabled: boolean;
    serverState?: string;
    thumbProps: {
        layout: ThumbLayout;
        showFlashLine?: boolean;
        suppressHeadline?: boolean;
        thumbnailOverride?: string;
        thumbPreviewEnabled?: boolean;
        onClick?: (progress?: number) => void;
    };
    subscribeScreenProps: {
        onSubscribeClicked: () => void;
        onSubscribeShown: () => void;
    };
    useHydrate?: boolean;
    videoId: string;
    videoProps: {
        disablepictureinpicture?: boolean;
    };
}

export interface Settings {
    adSkipTime?: number;
    adTag?: string;
    adZone?: string;
    adsEnabled?: boolean;
    allowFullScreen?: boolean;
    allowPlayerPopup?: boolean;
    api?: string;
    api2?: string;
    autoplay?: boolean | 'muted';
    callback?: () => void;
    chainVideos?: boolean;
    channel?: string;
    clickForSound?: boolean;
    collapseable?: boolean;
    contentType?: string;
    count?: number;
    directLinks?: boolean;
    disableChainPlay?: boolean;
    disableDVR?: boolean;
    disableHtmlControls?: boolean;
    disableTitle?: boolean;
    enableAutoplayMutedBehavior?: boolean;
    enableEndScreen?: boolean;
    enableLiveButton?: boolean;
    enableMoreVideosSlide?: boolean;
    enableScrubPreview?: boolean;
    enableStickyPlayer?: boolean;
    enableThumbPreview?: boolean;
    fallback?: boolean;
    fireTv?: boolean;
    groupId?: string;
    guid: string;
    hasCMPPermutiveConsent?: boolean;
    imaIasAdaptor?: string;
    imaIasNetworkId?: string;
    imaIasOptimization?: string;
    imaLibrary?: string;
    larsAdId?: string;
    larsId?: string;
    lnid?: string;
    loaderThumb?: boolean;
    loop?: boolean;
    maxBitrateIndex?: number | null;
    moduleId?: string;
    msrc?: string | null;
    noThumb?: boolean;
    oAccount?: string;
    parentUrl?: string;
    playInline?: boolean;
    playlist?: Record<string, unknown>;
    playlistQuery?: string;
    playerid?: string;
    /**
     * Overrides the plcmt value in the ad tag. Defaults to 2
     * @see {@link https://support.google.com/admanager/answer/10661571?sjid=11900967556916741456-NA#plcmt Google Ad Manager PLCMT documentation}
     * @see {@link https://github.com/InteractiveAdvertisingBureau/AdCOM/blob/develop/AdCOM%20v1.0%20FINAL.md#list_plcmtsubtypesvideo IAB AdCOM Placement Subtypes}
     */
    plcmtOverride?: number;
    plid?: string;
    prerollDelay?: number;
    query?: string;
    relativeLinks?: boolean;
    resetOnComplete?: boolean;
    sAccount?: string;
    saveApi?: string;
    saveApiPublication?: string;
    shareDomain?: string;
    shareEnabled?: boolean;
    site?: string;
    startPosition?: number;
    startLevel?: number;
    stickyPlayerTheme?: 'default' | 'wsj-article';
    suggestionsGroupId?: string;
    suggestionsQuery?: string;
    suggestionsType?: string;
    suppressHeadline?: boolean;
    thumb?: string;
    thumbFlashLine?: boolean;
    thumbLayout?: ThumbLayout;
    touchCastID?: string | null;
    trackInView?: boolean;
    type?: string;
    useAllesseh?: boolean;
    useHLS?: boolean;
    useHttps?: boolean;
    useWebm?: boolean;
    videoApiEnv?: Stage | 'dev';
    vpaidEnabled?: boolean;
    disablePictureInPicture?: boolean;
}
