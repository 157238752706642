import { h, Fragment } from 'preact';
import { useRef, useEffect, useReducer, useCallback, useState } from 'preact/hooks';
import { useSelector } from 'react-redux';
import { gTimeFormat, removeZeros } from '../../../utils';
import cn from 'classnames';
import ScrubPreview from '../ScrubPreview';
import AdMarkers from '../AdMarkers';

import styles from './styles.css';

const initialState = {
    isDragging: false,
    showLoadedBar: false,
    showPreview: false,
    scrubBarWidth: 0,
    mouseXCoord: 0,
    loadedPercent: 0
};

const actionTypes = {
    START_DRAG: 'START_DRAG',
    END_DRAG: 'END_DRAG',
    SHOW_LOADED_BAR: 'SHOW_LOADED_BAR',
    HIDE_LOADED_BAR: 'HIDE_LOADED_BAR',
    SHOW_PREVIEW: 'SHOW_PREVIEW',
    HIDE_PREVIEW: 'HIDE_PREVIEW',
    UPDATE_SCRUB_BAR_WIDTH: 'UPDATE_SCRUB_BAR_WIDTH',
    UPDATE_SCRUB_X_COORD: 'UPDATE_SCRUB_X_COORD'
};

const getWidth = (width, xCoord) => Math.min((xCoord / width) * 100, 100);

function reducer(state, action) {
    switch (action.type) {
        case actionTypes.START_DRAG:
            return { ...state, isDragging: true };
        case actionTypes.END_DRAG:
            return { ...state, isDragging: false };
        case actionTypes.SHOW_LOADED_BAR:
            return { ...state, showLoadedBar: true };
        case actionTypes.HIDE_LOADED_BAR:
            return { ...state, showLoadedBar: false };
        case actionTypes.SHOW_PREVIEW:
            return { ...state, showPreview: true };
        case actionTypes.HIDE_PREVIEW:
            return { ...state, showPreview: false };
        case actionTypes.UPDATE_SCRUB_BAR_WIDTH:
            return { ...state, scrubBarWidth: action.width, loadedPercent: getWidth(action.width, state.mouseXCoord) };
        case actionTypes.UPDATE_SCRUB_X_COORD:
            return {
                ...state,
                mouseXCoord: Math.min(Math.max(action.xCoord, 0), state.scrubBarWidth),
                loadedPercent: getWidth(state.scrubBarWidth, action.xCoord)
            };
        default:
            return state;
    }
}

const ScrubBar = (props) => {
    const currentPosition = useSelector((state) => state.currentPosition);
    const duration = useSelector((state) => state.duration);
    const width = useSelector((state) => state.width);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [hasFocus, setHasFocus] = useState(false);
    const scrubRef = useRef(null);

    const { isMobile, videoId, onSeek, showControls, enableScrubPreview } = props;

    const updateScrubXCoord = useCallback(
        ({ pageX, touches }) => {
            const position = pageX ?? touches?.[0]?.pageX;
            if (!position) {
                return;
            }

            const scrubBar = scrubRef.current;
            const parentOffset = scrubBar.getBoundingClientRect();
            const relX = position - parentOffset.left;
            if (state.scrubBarWidth !== scrubBar.offsetWidth) {
                // make sure width is in sync. Could change if the player is resized
                dispatch({ type: actionTypes.UPDATE_SCRUB_BAR_WIDTH, width: scrubBar.offsetWidth });
            }
            dispatch({ type: actionTypes.UPDATE_SCRUB_X_COORD, xCoord: relX });
        },
        [state.scrubBarWidth]
    );

    // TODO: This list of hooks is getting long. Maybe move to a separate function

    // effect for showing the loaded bar
    useEffect(() => {
        const scrubBar = scrubRef.current;
        const handleMouseOver = () => {
            dispatch({ type: actionTypes.SHOW_LOADED_BAR });
            if (!isMobile) dispatch({ type: actionTypes.SHOW_PREVIEW });
        };
        scrubBar.addEventListener('mouseover', handleMouseOver);
        return () => scrubBar.removeEventListener('mouseover', handleMouseOver);
    }, []);

    // effect for hiding loaded bar
    useEffect(() => {
        const scrubBar = scrubRef.current;
        const handleMouseOut = () => {
            dispatch({ type: actionTypes.HIDE_LOADED_BAR });
            dispatch({ type: actionTypes.HIDE_PREVIEW });
        };
        scrubBar.addEventListener('mouseout', handleMouseOut);
        return () => scrubBar.removeEventListener('mouseout', handleMouseOut);
    }, []);

    // effect for updating the progress bar and enabling drag
    useEffect(() => {
        const scrubBar = scrubRef.current;
        const onDrag = () => dispatch({ type: actionTypes.START_DRAG });
        scrubBar.addEventListener('mousedown', onDrag);
        if (isMobile) {
            scrubBar.addEventListener('touchstart', onDrag);
        }
        return () => {
            scrubBar.removeEventListener('mousedown', onDrag);
            if (isMobile) {
                scrubBar.removeEventListener('touchstart', onDrag);
            }
        };
    }, [isMobile]);

    // effect for updating the progress bar while being dragged on mobile
    useEffect(() => {
        if (!isMobile) {
            return;
        }

        const scrubBar = scrubRef.current;
        const handleMove = (evt) => {
            evt.preventDefault();
            // make sure controls stay visible
            showControls();
            if (!state.isDragging) {
                dispatch({ type: actionTypes.START_DRAG });
            }
            updateScrubXCoord(evt);
        };
        scrubBar.addEventListener('touchmove', handleMove);
        return () => scrubBar.removeEventListener('touchmove', handleMove);
    }, [isMobile, state.isDragging, showControls, updateScrubXCoord]);

    // effect for updating the mouse x coord relative to the scrubBar
    useEffect(() => {
        window.addEventListener('mousemove', updateScrubXCoord);
        return () => window.removeEventListener('mousemove', updateScrubXCoord);
    }, [updateScrubXCoord]);

    // effect for disabling drag and seeking the video on mouseup
    useEffect(() => {
        const scrubBar = scrubRef.current;
        const handleMouseUp = () => {
            if (state.isDragging && onSeek) {
                onSeek(state.loadedPercent / 100);
            }
            dispatch({ type: actionTypes.END_DRAG });
            dispatch({ type: actionTypes.HIDE_PREVIEW });
        };

        window.addEventListener('mouseup', handleMouseUp);
        if (isMobile) {
            scrubBar.addEventListener('touchend', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
            if (isMobile) {
                scrubBar.removeEventListener('touchend', handleMouseUp);
            }
        };
    }, [state.isDragging, state.loadedPercent, onSeek, isMobile]);

    const onFocus = (e) => {
        const formattedPosition = removeZeros(gTimeFormat(currentPosition));
        const formattedDuration = removeZeros(gTimeFormat(duration));

        scrubRef.current.setAttribute('aria-valuetext', formattedPosition + ' / ' + formattedDuration);
        scrubRef.current.setAttribute('aria-valuenow', currentPosition);
    };

    const progressPercent = state.isDragging ? state.loadedPercent : (currentPosition / duration) * 100;
    const enablePreview = enableScrubPreview && width >= 250;

    return (
        <Fragment>
            {enablePreview && (
                <ScrubPreview
                    scrubBarWidth={state.scrubBarWidth}
                    mouseXCoord={state.mouseXCoord}
                    show={state.showPreview || state.isDragging}
                />
            )}
            <div
                ref={scrubRef}
                className={cn(styles.scrubBar, { [styles.mobile]: isMobile })}
                aria-valuetext=""
                tabIndex={0}
                aria-label="video timeline"
                role="slider"
                aria-valuemin="0"
                aria-valuemax={duration}
                onFocus={onFocus}
                onMouseEnter={onFocus}
                data-testid="video-scrub-bar"
            >
                <div className={styles.scrubPadding} />
                {state.showLoadedBar && (
                    <div className={styles.scrubLoaded} style={{ width: `${state.loadedPercent}%` }} />
                )}
                <div className={styles.scrubBackground}>
                    <AdMarkers scrubBarWidth={state.scrubBarWidth} />
                </div>
                <div className={styles.scrubProgress} style={{ width: `${progressPercent}%` }}>
                    <span className={styles.dot} />
                </div>
            </div>
        </Fragment>
    );
};

export default ScrubBar;
