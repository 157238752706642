export function addPageAction(action: string, data?: any): void {
    if (typeof newrelic === 'object' && typeof newrelic.addPageAction === 'function') {
        newrelic.addPageAction(action, data);
    }
}

export function setCustomAttribute(key: string, value: any): void {
    if (typeof newrelic === 'object' && typeof newrelic.setCustomAttribute === 'function') {
        newrelic.setCustomAttribute(key, value);
    }
}

export function noticeError(error: any, customAttributes?: any): void {
    if (typeof newrelic === 'object' && typeof newrelic.noticeError === 'function') {
        newrelic.noticeError(error instanceof Error ? error : new Error(error.toString()), customAttributes);
    }
}

export function getEventDuration(measureName: string, startMark?: string, endMark?: string): number {
    try {
        return performance.measure(measureName, startMark || `${measureName}:start`, endMark || `${measureName}:end`)
            .duration;
    } catch (e) {
        return -1;
    }
}

function handleVideoEvent(evt: Event) {
    performance?.mark?.(`videoEvent:${evt.type}`);
}

/**
 * Adds performance markers for video events to track their timing.
 *
 * @param videoEl - The HTML video element to attach event listeners to
 * @returns void
 *
 */
export function markVideoEvents(videoEl: HTMLVideoElement) {
    const events = [
        'abort',
        'canplay',
        'canplaythrough',
        'durationchange',
        'emptied',
        'encrypted',
        'ended',
        'error',
        'interruptbegin',
        'interruptend',
        'loadeddata',
        'loadedmetadata',
        'loadstart',
        'mozaudioavailable',
        'pause',
        'play',
        'playing',
        'progress',
        'ratechange',
        'seeked',
        'seeking',
        'stalled',
        'suspend',
        'timeupdate',
        'volumechange',
        'waiting'
    ];
    for (const e of events) {
        videoEl.removeEventListener(e, handleVideoEvent);
        videoEl.addEventListener(e, handleVideoEvent);
    }
}
