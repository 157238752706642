import { Store } from 'redux';
import { setVisiblity } from './actions';

const setVisiblityAction: (state: boolean) => ReturnType<typeof setVisiblity> = setVisiblity as any;

const threshold = 0.5;

const observerRefs = new WeakMap<Element, VisibilityObserver>();

// The IntersectionObserver is global to avoid race conditions when multiple are created. A single instance will return entries in document order.
const globalObserver = new IntersectionObserver(
    (entries) => {
        for (const entry of entries) {
            const observer = observerRefs.get(entry.target);
            if (!observer) {
                // Remove the observer if the element is no longer found in the map. This can happen if the element is removed from the DOM.
                globalObserver.unobserve(entry.target);
                continue;
            }

            if (entry.isIntersecting && !observer.isVisible()) {
                observer.emitVisible();
            } else if (!entry.isIntersecting && observer.isVisible() && !observer.isFullScreen()) {
                observer.emitHidden();
            }
        }
    },
    { threshold }
);

export class VisibilityObserver extends EventTarget {
    #store: Store;

    constructor(element: HTMLElement, store: Store) {
        super();

        this.#store = store;
        observerRefs.set(element, this);
        globalObserver.observe(element);
    }

    emitVisible() {
        this.#store.dispatch(setVisiblityAction(true));
        this.dispatchEvent(new CustomEvent('visibilitychange', { detail: { visible: true } }));
    }

    emitHidden() {
        this.#store.dispatch(setVisiblityAction(false));
        this.dispatchEvent(new CustomEvent('visibilitychange', { detail: { visible: false } }));
    }

    isVisible() {
        return this.#store.getState().isVisible;
    }

    isFullScreen() {
        return this.#store.getState().isFullScreen;
    }
}

export function isElementVisible(element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const visibleArea = (Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0)) / rect.height;

    return visibleArea >= threshold;
}

export default VisibilityObserver;
