import { createStore as reduxCreateStore, applyMiddleware, Store as ReduxStore } from 'redux';
import { createLogger } from 'redux-logger';
import * as actionTypes from './actions/actionTypes';
import * as loadState from './loadState';
import { calculateBreakpoint } from './utils';
import GlobalStateManager, { globalStateMiddleware } from './globalStateManager';
import { VideoData } from '../types';

export interface State {
    adInitState: string;
    autoplay: boolean | 'muted' | 'mobile';
    bitrate: number;
    containerPosition: {
        left: number;
        top: number;
    };
    currentAdDuration: number;
    currentAdPosition: number;
    isSkipButtonEnabled: boolean;
    currentBreakpoint: string;
    currentPosition: number;
    duration: number;
    focusElement: string | null;
    formatOverride: string | null;
    hasAutoplayed: boolean | null;
    hasChained: boolean;
    height: number;
    hlsJsLoadState: loadState.LoadState;
    id: string;
    imaAdErrorState: boolean;
    imaLoadState: loadState.LoadState;
    isAdBuffering: boolean;
    isAdMode: boolean;
    isAdPlaying: boolean;
    isAdsBlocked: boolean;
    isBuffering: boolean;
    isClickForSoundVisible: boolean;
    isClosedCaptionsVisible: boolean;
    isControlsVisible: boolean;
    isCountdownVisible: boolean;
    isEndscreenVisible: boolean;
    isFloating: boolean;
    isFloatingBlocked: boolean;
    isFullScreen: boolean;
    isInitialized: boolean;
    isLoadingSpinnerVisible: boolean;
    isMoreVideosSlideVisible: boolean;
    isMuted: boolean;
    isPlaybackInitializing: boolean;
    isPlaying: boolean;
    isSavedQueueModalVisible: boolean;
    isSubscribeScreenVisible: boolean;
    isSharescreenVisible: boolean;
    /**
     * @deprecated This is only used for the WSJ article sticky player. We should use isFloating when we move all of that functionality inside the player.
     */
    isStickyModeEnabled: boolean;
    isThumbnailVisible: boolean;
    isVisible: boolean;
    isVolumeControlVisible: boolean;
    lastSavedProgress: number;
    loggingEnabled: boolean;
    playlist: VideoData[];
    videoApiLoadState: loadState.LoadState;
    videoData: VideoData;
    volume: number;
    width: number;
}

export type Store = ReduxStore<State>;

export const defaultState: Readonly<State> = {
    autoplay: false,
    adInitState: loadState.NONE,
    bitrate: 0,
    containerPosition: { left: -1, top: -1 },
    currentAdDuration: 0,
    currentAdPosition: 0,
    isSkipButtonEnabled: false,
    currentBreakpoint: '16U',
    currentPosition: 0,
    duration: 0,
    focusElement: null,
    formatOverride: null,
    hasAutoplayed: null,
    hasChained: false,
    height: 0,
    hlsJsLoadState: loadState.NONE,
    id: '',
    imaAdErrorState: false,
    imaLoadState: loadState.NONE,
    isAdBuffering: false,
    isAdMode: false,
    isAdPlaying: false,
    isAdsBlocked: false,
    isBuffering: false,
    isClickForSoundVisible: false,
    isClosedCaptionsVisible: false,
    isControlsVisible: false,
    isCountdownVisible: false,
    isEndscreenVisible: false,
    isFloating: false,
    isFloatingBlocked: false,
    isFullScreen: false,
    isInitialized: false,
    isLoadingSpinnerVisible: false,
    isMoreVideosSlideVisible: false,
    isMuted: false,
    isPlaybackInitializing: false,
    isPlaying: false,
    isSavedQueueModalVisible: false,
    isSubscribeScreenVisible: false,
    isSharescreenVisible: false,
    isStickyModeEnabled: false,
    isThumbnailVisible: false,
    isVisible: false,
    isVolumeControlVisible: false,
    lastSavedProgress: 0,
    loggingEnabled: false,
    playlist: [],
    videoApiLoadState: loadState.NONE,
    videoData: {
        secondsUntilStartTime: 0
    },
    volume: 1,
    width: 0
};

// TODO: Move this to another file and split the reducer logic as we add more actions
// https://redux.js.org/recipes/structuring-reducers/splitting-reducer-logic
// https://redux.js.org/recipes/structuring-reducers/using-combinereducers
function reducer(state = defaultState, action: any) {
    switch (action.type) {
        case actionTypes.RESIZE: {
            return {
                ...state,
                width: action.width,
                height: action.height,
                currentBreakpoint: calculateBreakpoint(action.width)
            };
        }
        case actionTypes.THUMBNAIL_SHOW:
            return { ...state, isThumbnailVisible: true };
        case actionTypes.THUMBNAIL_HIDE:
            return { ...state, isThumbnailVisible: false };
        case actionTypes.MORE_VIDEOS_SLIDE_SHOW:
            return { ...state, isMoreVideosSlideVisible: true };
        case actionTypes.MORE_VIDEOS_SLIDE_HIDE:
            return { ...state, isMoreVideosSlideVisible: false };
        case actionTypes.CONTROLS_SHOW:
            return { ...state, isControlsVisible: true };
        case actionTypes.CONTROLS_HIDE:
            return { ...state, isControlsVisible: false };
        case actionTypes.SHOW_ENDSCREEN:
            return { ...state, isEndscreenVisible: true };
        case actionTypes.HIDE_ENDSCREEN:
            return { ...state, isEndscreenVisible: false };
        case actionTypes.SHOW_SHARESCREEN:
            return { ...state, isSharescreenVisible: true };
        case actionTypes.HIDE_SHARESCREEN:
            return { ...state, isSharescreenVisible: false };
        case actionTypes.VIDEO_PLAY:
            return { ...state, isPlaying: true };
        case actionTypes.VIDEO_PAUSE:
            return { ...state, isPlaying: false };
        case actionTypes.VIDEO_PROGRESS:
            return { ...state, currentPosition: action.currentPosition };
        case actionTypes.VIDEO_DURATION:
            return { ...state, duration: action.duration };
        case actionTypes.VIDEO_MUTE:
            return { ...state, isMuted: true };
        case actionTypes.VIDEO_UNMUTE:
            return { ...state, isMuted: false };
        case actionTypes.VIDEO_VOLUME:
            return { ...state, volume: action.volume };
        case actionTypes.SET_VOLUME_CONTROL_VISIBILITY:
            return { ...state, isVolumeControlVisible: action.isVolumeControlVisible };
        case actionTypes.VIDEO_ENTER_FULLSCREEN:
            return { ...state, isFullScreen: true, currentBreakpoint: 'full' };
        case actionTypes.VIDEO_EXIT_FULLSCREEN:
            return { ...state, isFullScreen: false, currentBreakpoint: calculateBreakpoint(state.width) };
        case actionTypes.SHOW_SAVED_MODAL:
            return { ...state, isSavedQueueModalVisible: true };
        case actionTypes.HIDE_SAVED_MODAL: {
            return { ...state, isSavedQueueModalVisible: false };
        }
        case actionTypes.UPDATE_VIDEO_DATA:
            return {
                ...state,
                videoData: action.videoData,
                currentPosition: 0,
                duration:
                    action.videoData && action.videoData.duration
                        ? parseInt(action.videoData.duration, 10)
                        : defaultState.duration,
                playlist:
                    state.playlist.length > 0
                        ? removeGuidFromPlaylist(state.playlist, action.videoData.guid)
                        : state.playlist
            };
        case actionTypes.REPLACE_PLAYLIST: {
            if (!action.playlist || action.playlist.length === 0) {
                return state;
            }

            const newPlaylist = removeGuidFromPlaylist(action.playlist, state.videoData.guid ?? '');
            return { ...state, playlist: newPlaylist };
        }
        case actionTypes.DEQUEUE_PLAYLIST: {
            const { playlist } = state;
            if (playlist.length === 0) {
                return state;
            }

            const [, ...rest] = playlist;
            return { ...state, playlist: rest };
        }
        case actionTypes.SET_FOCUS:
            return { ...state, focusElement: action.payload };
        case actionTypes.SHOW_COUNTDOWN:
            return { ...state, isCountdownVisible: true, isControlsVisible: false };
        case actionTypes.HIDE_COUNTDOWN:
            return { ...state, isCountdownVisible: false };
        case actionTypes.SHOW_CLICK_FOR_SOUND:
            return { ...state, isClickForSoundVisible: true };
        case actionTypes.HIDE_CLICK_FOR_SOUND:
            return { ...state, isClickForSoundVisible: false };
        case actionTypes.SHOW_CLOSED_CAPTIONS:
            return { ...state, isClosedCaptionsVisible: true };
        case actionTypes.HIDE_CLOSED_CAPTIONS:
            return { ...state, isClosedCaptionsVisible: false };
        case actionTypes.SHOW_LOADING_SPINNER:
            // Try not to use this state for anything. The spinner should be displayed based on a specific loading state instead of this generic flag.
            // Only used for old code
            return { ...state, isLoadingSpinnerVisible: true };
        case actionTypes.HIDE_LOADING_SPINNER:
            return { ...state, isLoadingSpinnerVisible: false };
        case actionTypes.UPDATE_VIDEO_API_LOAD_STATE:
            return { ...state, videoApiLoadState: action.payload };
        case actionTypes.UPDATE_AD_INIT_STATE:
            return { ...state, adInitState: action.payload };
        case actionTypes.UPDATE_IMA_LOAD_STATE:
            return { ...state, imaLoadState: action.payload, isAdsBlocked: action.payload === loadState.FAILED };
        case actionTypes.UPDATE_AD_ERROR_STATE:
            return { ...state, imaAdErrorState: action.payload };
        case actionTypes.UPDATE_HLS_JS_LOAD_STATE:
            return { ...state, hlsJsLoadState: action.payload };
        case actionTypes.VIDEO_START_PLAYBACK_INIT:
            return { ...state, isPlaybackInitializing: true, currentPosition: 0 };
        case actionTypes.VIDEO_END_PLAYBACK_INIT:
            return { ...state, isPlaybackInitializing: false };
        case actionTypes.VIDEO_BUFFERING:
            return { ...state, isBuffering: true };
        case actionTypes.VIDEO_CAN_PLAY:
            return { ...state, isBuffering: false };
        case actionTypes.AD_STARTED:
            return { ...state, isAdMode: true, isSharescreenVisible: false };
        case actionTypes.AD_COMPLETE:
            return {
                ...state,
                isAdMode: false,
                isPlaybackInitializing: false,
                isAdBuffering: false,
                isAdPlaying: false,
                currentAdPosition: 0,
                currentAdDuration: 0
            };
        case actionTypes.AD_PROGRESS:
            return { ...state, currentAdPosition: action.payload, isAdBuffering: false };
        case actionTypes.AD_DURATION:
            return { ...state, currentAdDuration: action.payload };
        case actionTypes.AD_PLAY:
            return { ...state, isAdPlaying: true };
        case actionTypes.AD_PAUSE:
            return { ...state, isAdPlaying: false };
        case actionTypes.AD_BUFFERING:
            return { ...state, isAdBuffering: true };
        case actionTypes.AD_CAN_PLAY:
            return { ...state, isAdBuffering: false };
        case actionTypes.SET_AD_SKIP_ENABLED:
            return { ...state, skipButtonEnabled: action.isSkipButtonEnabled };
        case actionTypes.SET_STICKY_MODE:
            return { ...state, isStickyModeEnabled: action.isStickyModeEnabled };
        case actionTypes.VISIBILITY_CHANGE:
            return { ...state, isVisible: action.payload };
        case actionTypes.UPDATE_AUTOPLAY:
            return { ...state, autoplay: action.payload };
        case actionTypes.UPDATE_FLOATING:
            return { ...state, isFloating: action.payload };
        case actionTypes.PLAYER_INITIALIZED:
            return { ...state, isInitialized: true };
        case actionTypes.UPDATE_FLOATING_BLOCKED:
            return { ...state, isFloatingBlocked: action.payload };
        case actionTypes.SHOW_SUBSCRIBESCREEN:
            return { ...state, isSubscribeScreenVisible: true };
        case actionTypes.HIDE_SUBSCRIBESCREEN:
            return { ...state, isSubscribeScreenVisible: false };
        case actionTypes.UPDATE_BITRATE:
            return { ...state, bitrate: action.payload };
        case actionTypes.UPDATE_HAS_AUTOPLAYED:
            return { ...state, hasAutoplayed: action.payload };
        case actionTypes.UPDATE_HAS_CHAINED:
            return { ...state, hasChained: action.payload };
        case actionTypes.UPDATE_FORMAT_OVERRIDE:
            return { ...state, formatOverride: action.payload?.length === 0 ? null : action.payload };
        case actionTypes.UPDATE_LAST_SAVED_PROGRESS:
            return { ...state, lastSavedProgress: action.lastSavedProgress };
        default:
            return state;
    }
}

function removeGuidFromPlaylist(playlist: VideoData[], guid: string) {
    const newPlaylist = [...playlist];
    const index = newPlaylist.findIndex((video) => video.guid === guid);
    if (index !== -1) {
        newPlaylist.splice(index, 1);
    }
    return newPlaylist;
}

export function createStore(initialState: Partial<State>, globalStateManager?: GlobalStateManager) {
    const state = { ...defaultState, ...initialState };

    const middleware = [];
    // Whenever a new middleware is added, make sure globalStateMiddleware is the last one (second to last if logger is used). Otherwise the global state will be innacurate if a middleware after modifies the state.
    if (globalStateManager) {
        middleware.push(globalStateMiddleware(globalStateManager));
    }

    // only log locally or on staging with a query param
    const showLogger =
        process.env.SSR_MODE !== 'true' &&
        ((process.env.STAGE === 'nonprod' &&
            (window.location.hostname === 'localhost' || window.location.hostname.includes('local.wsj.com'))) ||
            window.location.search.includes('wsjVideoLogger'));
    if (showLogger) {
        const ignoredActions = [actionTypes.VIDEO_PROGRESS, actionTypes.AD_PROGRESS];
        const logger = createLogger({
            // ignore certain actions here
            predicate: (getState, action) => ignoredActions.indexOf(action.type) === -1,
            diff: true,
            collapsed: true
        });
        middleware.push(logger);
        state.loggingEnabled = true;
    }

    return reduxCreateStore(reducer, state, applyMiddleware(...middleware));
}

/**
 * Wait for a state update that satisfies a condition.
 *
 * @param store Redux store
 * @param selector A function that returns true when the condition is met.
 * @param timeout The maximum time to wait for the condition to be met. If the condition is not met within this time, the promise will be rejected.
 * @returns A promise that resolves when the condition is met.
 * @throws If the timeout is reached before the condition is met.
 */
export function waitForStateUpdate(store: Store, selector: (state: State) => boolean, timeout?: number): Promise<void> {
    return new Promise((resolve, reject) => {
        let timeoutId: NodeJS.Timeout | undefined;
        let unsubscribe = () => {};

        const checkState = () => {
            if (selector(store.getState())) {
                clearTimeout(timeoutId);
                unsubscribe();
                resolve();
            }
        };

        unsubscribe = store.subscribe(checkState);

        if (timeout) {
            timeoutId = setTimeout(() => {
                unsubscribe();
                reject(new Error('Timeout waiting for store update'));
            }, timeout);
        }

        checkState();
    });
}
