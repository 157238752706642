import { FunctionalComponent, h } from 'preact';
import { useSelector } from '../../../hooks';
import styles from './styles.css';

interface Props {
    scrubBarWidth: number;
}

const AdMarkers: FunctionalComponent<Props> = ({ scrubBarWidth }) => {
    const duration = useSelector((state) => state.duration);
    const midRolls = useSelector((state) => state.adConfigs.filter((ad) => ad.type === 'midroll'));

    return (
        <div className={styles.container}>
            {midRolls.map((ad, index) => {
                const markerPos = parseInt(((ad.time / duration) * scrubBarWidth).toString());
                return (
                    <div
                        key={`${ad.time}-${index}`}
                        className={styles.midrollMarker}
                        style={{ left: `${markerPos}px` }}
                    />
                );
            })}
        </div>
    );
};

export default AdMarkers;
