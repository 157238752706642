import { createMark, getRawCookie } from './utils';

export function getRefsecKeyValue() {
    const a = document.createElement('a');
    a.href = document.referrer;
    // return blank value if not wsj || barrons
    if (a.hostname.indexOf('wsj.com') < 0 && a.hostname.indexOf('barrons.com') < 0) {
        return '';
    }

    let rel = a.pathname;
    if (rel[0] === '/') {
        rel = rel.substring(1);
    }

    const path = rel.replace(/\//g, ',').split(',');
    if (path[0] === 'articles') {
        return '';
    }

    return path.join(',');
}

export function getViewMode() {
    try {
        const vMode = google.ima.ViewMode.NORMAL;
        if (screenfull && screenfull.isFullscreen) {
            vMode = google.ima.ViewMode.FULLSCREEN;
        }
        return vMode;
    } catch (e) {
        return 'normal';
    }
}

function iasDFPUtilites() {
    let adSlotDataObj;

    const parseIASResponseData = function (adSlotData) {
        adSlotDataObj = JSON.parse(adSlotData);
    };

    const getIASPageTargetingAsQueryString = function () {
        const params = new URLSearchParams();
        try {
            params.append('fr', adSlotDataObj.fr);
            for (const k in adSlotDataObj.brandSafety) {
                params.append(k, adSlotDataObj.brandSafety[k]);
            }
            if (adSlotDataObj.custom) {
                const kw = adSlotDataObj.custom['ias-kw'];
                if (Array.isArray(kw)) {
                    params.append('ias-kw', kw.join(','));
                }
            }
        } catch (e) {
            console.error('Error parsing IAS response data', e);
        }
        return params.toString();
    };

    const getIASSlotTargetingAsQueryString = function () {
        const iasSlotTargeting = {};
        for (const slotId in adSlotDataObj.slots) {
            const slotIdValues = adSlotDataObj.slots[slotId];
            const params = new URLSearchParams();
            for (const key in slotIdValues) {
                const segments = slotIdValues[key];
                if (Array.isArray(segments)) {
                    params.append(key, segments.join(','));
                } else {
                    params.append(key, segments);
                }
            }
            iasSlotTargeting[slotId] = params.toString();
        }
        return iasSlotTargeting;
    };

    return {
        parseIASResponseData: parseIASResponseData,
        getIASPageTargetingAsQueryString: getIASPageTargetingAsQueryString,
        getIASSlotTargetingAsQueryString: getIASSlotTargetingAsQueryString
    };
}

export class ScriptUnavailableError extends Error {
    constructor(scriptName) {
        super(`${scriptName} not available`);
        this.name = 'ScriptUnavailableError';
    }
}

export function fetchIASParams(adTag, settings, log = console.log) {
    if (typeof __iasPET === 'undefined' || typeof __iasPET.queue === 'undefined') {
        return Promise.reject(new ScriptUnavailableError('__iasPET.queue'));
    }

    createMark('videoFetchIASParams:start');
    const timeoutPromise = new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 1000));
    const fetchIASPromise = new Promise((resolve, reject) => {
        try {
            log('Calling __iasPET');
            const iasPET = window.__iasPET || {};
            iasPET.queue = iasPET.queue || [];
            iasPET.pubId = settings.imaIasNetworkId;
            const iasDataHandler = function (adSlotData) {
                const iasUtils = iasDFPUtilites();
                iasUtils.parseIASResponseData(adSlotData);
                const globalAdRequestParams = iasUtils.getIASPageTargetingAsQueryString();
                const slotAdRequestParamsMap = iasUtils.getIASSlotTargetingAsQueryString();
                resolve(encodeURIComponent(`&${globalAdRequestParams}&${slotAdRequestParamsMap['ad-1']}`));
            };
            var iasPETSlots = [];
            var adSlots = ['ad-1'];
            for (let i = 0; i < adSlots.length; i++) {
                const adUnit = getAdUnitPathByDomain(settings.site, log);
                iasPETSlots.push({
                    adSlotId: adSlots[i],
                    size: [1, 1],
                    adUnitPath: `/2/${adUnit}`,
                    type: 'video'
                });
            }
            iasPET.queue.push({
                adSlots: iasPETSlots,
                dataHandler: iasDataHandler
            });
        } catch (e) {
            reject(e);
        }
    });
    return Promise.race([timeoutPromise, fetchIASPromise]).finally(() => createMark('videoFetchIASParams:end'));
}

// These static sizes were given to us by the TAM team
const tamVideoSizes = {
    standard: [
        [3840, 2160],
        [2560, 1440],
        [1920, 1080],
        [1280, 720],
        [1024, 576],
        [854, 480],
        [640, 360],
        [426, 240],
        [400, 225],
        [320, 180]
    ],
    vertical: [
        [2160, 3840],
        [1440, 2560],
        [1080, 1920],
        [720, 1280],
        [576, 1024],
        [480, 854],
        [360, 640],
        [240, 426],
        [225, 400],
        [180, 320]
    ],
    square: [
        [1920, 1920],
        [1080, 1080],
        [720, 720],
        [640, 640],
        [360, 360]
    ]
};

/**
 * Get the best sizes for the given width and height
 * @param {number} width CSS pixel width
 * @param {number} height CSS pixel height
 * @returns {Array<number>}
 */
function getBestSize(width, height) {
    // Convert CSS pixels to physical pixels
    const dpr = window.devicePixelRatio || 1;
    const physicalWidth = width * dpr;
    const physicalHeight = height * dpr;

    const aspectRatio = width / height;
    let sizeKey = 'standard';
    if (aspectRatio < 1) {
        sizeKey = 'vertical';
    } else if (aspectRatio === 1) {
        sizeKey = 'square';
    }

    const sizes = tamVideoSizes[sizeKey];
    return sizes.find((size) => size[0] <= physicalWidth && size[1] <= physicalHeight) ?? sizes[sizes.length - 1];
}

/**
 * Fetch ad tag params from Prebid library
 * @returns {Promise<string>}
 */
export function fetchBidsPrebid(width, height, plcmt) {
    if (typeof pbjs === 'undefined' || typeof pbjs.fetchVideoBids !== 'function') {
        return Promise.reject(new ScriptUnavailableError('pbjs.fetchVideoBids'));
    }
    const size = getBestSize(width, height);
    createMark('videoFetchPrebidBids:start');
    const timeoutPromise = new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 1000));
    const fetchBidsPromise = pbjs.fetchVideoBids(size[0], size[1], plcmt);
    return Promise.race([timeoutPromise, fetchBidsPromise]).finally(() => createMark('videoFetchPrebidBids:end'));
}

/**
 * Fetch ad tag params from Amazon TAM
 * @param {number} width
 * @param {number} height
 * @returns {Promise<string>}
 */
export function fetchBidsTAM(width, height, log = console.log) {
    if (typeof apstag === 'undefined' || typeof apstag.fetchBids !== 'function') {
        return Promise.reject(new ScriptUnavailableError('apstag.fetchBids'));
    }

    createMark('videoFetchTAMBids:start');
    const timeoutPromise = new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 1000));
    const fetchBidsPromise = new Promise((resolve, reject) => {
        try {
            log('Calling apstag.fetchBids');
            apstag.fetchBids(
                {
                    slots: [
                        {
                            slotID: 'DowJones_Instream_Video',
                            mediaType: 'video',
                            sizes: [getBestSize(width, height)]
                        }
                    ],
                    timeout: 1000
                },
                (bids) => {
                    log('fetchBids result', bids);
                    if (bids.length === 0) {
                        return resolve('');
                    }

                    const params = bids
                        .filter((bid) => bid.mediaType === 'video')
                        .map((bid) => bid.helpers.encodedQsParams())
                        .join('');
                    resolve(params);
                },
                reject
            );
        } catch (e) {
            reject(e);
        }
    });
    return Promise.race([timeoutPromise, fetchBidsPromise]).finally(() => createMark('videoFetchTAMBids:end'));
}

export function getVXID() {
    return window.utag_data?.user_ref || getRawCookie('TR');
}

// Code inspired by https://github.dowjones.net/ace/universal-ad-script/blob/c8ef9823ecd05ecfb48e5b8b151287d04189265b/src/lib/setPublisherProviderId.js
export function getPPID() {
    const vxid = getVXID();
    if (vxid) {
        return vxid.replace('-', '');
    }

    const networkId = getRawCookie('_ncg_g_id_');
    if (!networkId) {
        return null;
    }

    const prefix = networkId.split('.')[0];

    // Reference for PPID format: https://support.google.com/admanager/answer/2880055#setting
    const regex =
        /^([0-9a-zA-Z]{32,150})$|^([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})$/;
    return regex.test(prefix) ? prefix : null;
}

/**
 * Gets the ad unit path based on the site domain or provided site name
 * @param {string} [site] - Optional site name (e.g., 'video.wsj.com'). If not provided, will be determined from current domain
 * @param {Console['log']} [log] - Logging function
 * @returns {string} The complete ad unit path (e.g., 'video.wsj.com/markets' or 'video.barrons.com/video_center')
 */
export function getAdUnitPathByDomain(site, log = console.log) {
    if (!site) {
        try {
            const url = window.top === window.self ? window.location.hostname : window.top.location.hostname; // if iframe
            if (url.indexOf('barrons.com') !== -1) {
                site = 'video.barrons.com';
            } else if (url.indexOf('marketwatch.com') !== -1) {
                site = 'video.marketwatch.com';
            } else {
                site = 'video.wsj.com';
            }
        } catch (e) {
            log('Error getting adUnitPath by domain', e);
            site = 'video.wsj.com';
        }
    }

    if (window.utag_data?.page_id === 'Video Center' || window.utag_data?.page_section === 'Video Center') {
        return `${site}/video_center`;
    } else if (window.utag_data?.page_content_type_detail === 'livecoverage') {
        return `${site}/live_coverage`;
    } else if (typeof __ace === 'function') {
        let pageAdUnit;
        try {
            pageAdUnit = __ace('dataLayer', 'getData', ['siteAdUnitPath'])?.siteAdUnitPath?.[0];
        } catch (e) {
            log('Error getting siteAdUnitPath', e);
        }

        /**
         * Extract the path portion from the page's ad unit path to append to the video ad unit.
         * This preserves the section/subsection targeting from the page.
         *
         * Examples:
         * Input: "2/interactive.wsj.com/markets"          Output: "video.wsj.com/markets"
         * Input: "/2/interactive.wsj.com/markets"         Output: "video.wsj.com/markets"
         * Input: "2/interactive.wsj.com/markets/stocks"   Output: "video.wsj.com/markets/stocks"
         * Input: "2/interactive.wsj.com"                  Output: "video.wsj.com" (no change)
         * Input: "2/interactive.wsj.com/"                 Output: "video.wsj.com" (no change)
         *
         */
        if (pageAdUnit) {
            const normalizedAdUnit = pageAdUnit.startsWith('/') ? pageAdUnit.substring(1) : pageAdUnit;
            const secondSlashIndex = normalizedAdUnit.indexOf('/', normalizedAdUnit.indexOf('/') + 1);
            if (secondSlashIndex !== -1) {
                const path = normalizedAdUnit.substring(secondSlashIndex).replace(/\/$/, '');
                site += path;
            }
        }
    }
    return site;
}

export function appendArticlePermutiveValues(log = console.log) {
    try {
        if (typeof window.__ace === 'function') {
            const permutiveValues = window.__ace('dataProvider', 'getData', ['pageTargeting', { source: 'permutive' }]);
            let rtn = permutiveValues?.data?.permutive
                ? '%26permutive%3D' + permutiveValues.data.permutive.join('%2C')
                : '';
            rtn += permutiveValues?.data?.puid ? '%26puid%3D' + permutiveValues.data.puid : '';
            rtn += permutiveValues?.data?.ptime ? '%26ptime%3D' + permutiveValues.data.ptime : '';
            return rtn;
        }
    } catch (e) {
        log('failed applying permutive ad params.', e);
        return '';
    }
    return '';
}

export function checkAndMergeArticleProximicValues(gptCustParams, log = console.log) {
    try {
        if (typeof window.__ace === 'function') {
            const decoded = decodeURIComponent(gptCustParams);
            const urlParams = new URLSearchParams(decoded);
            const articleProximicArray = window.__ace('dataLayer', 'getData', ['psg']).psg;
            if (urlParams.has('psg') && Array.isArray(articleProximicArray)) {
                const videoPsgArray = urlParams.get('psg').split(',');
                const mergedPsgArray = [...new Set([...videoPsgArray, ...articleProximicArray])];
                const mergedPsgString = mergedPsgArray.map(encodeURIComponent).join(',');
                urlParams.set('psg', mergedPsgString);
                return encodeURIComponent(urlParams.toString()); // return merged psg value string
            }
        }
    } catch (e) {
        log('Error merging video proximic values', e);
        return gptCustParams;
    }
    return gptCustParams;
}
