import { h } from 'preact';
import classNames from 'classnames';
import AdCountdownCircle from '../AdCountdownCircle';
import styles from './styles.css';
import { useSelector } from '../../hooks';

interface AdContainerProps {
    videoId: string;
    showCountdown?: boolean;
}

const AdContainer = ({ videoId, showCountdown = true }: AdContainerProps) => {
    const isAdMode = useSelector((state) => state.isAdMode);
    return (
        <div className={classNames(styles.container, { [styles.visible]: isAdMode })} id={`adContainer-${videoId}`}>
            <div className={styles.topBackground}></div>
            {showCountdown && <AdCountdownCircle videoId={videoId} />}
        </div>
    );
};

export default AdContainer;
