import { Store } from '../store';
import { getPPID, fetchBidsPrebid, fetchBidsTAM, fetchIASParams, getAdTag } from '../ad-utils';
import { AdPosition, Settings, AdConfig } from '../../types';
import { AdScheduler } from './adScheduler';
import type { HlsPlaybackManager } from './hlsJsPlayback';
import { PlaybackEvent } from './index';
import { createMark } from '../utils';
import { setAdInitState, startAdMode, stopAdMode } from '../actions';
import * as loadState from '../loadState';
import { addPageAction, noticeError } from '../perf-utils';
import { AdEvent, AdError, AdEventMap, TypedEventTarget, Ad, AdProgressData } from './index';

function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
    if (val === undefined || val === null) {
        throw new Error(`Expected 'val' to be defined, but received ${val}`);
    }
}

// Map from Google IMA events to our custom events
const imaToCustomEventMap: Record<string, AdEvent> = {
    AD_BUFFERING: AdEvent.AD_BUFFERING,
    STARTED: AdEvent.AD_STARTED,
    PAUSED: AdEvent.AD_PAUSED,
    RESUMED: AdEvent.AD_RESUMED,
    COMPLETE: AdEvent.AD_COMPLETE,
    SKIPPED: AdEvent.AD_SKIPPED,
    FIRST_QUARTILE: AdEvent.AD_FIRST_QUARTILE,
    MIDPOINT: AdEvent.AD_MIDPOINT,
    THIRD_QUARTILE: AdEvent.AD_THIRD_QUARTILE,
    CLICK: AdEvent.AD_CLICK,
    VOLUME_MUTED: AdEvent.AD_MUTED,
    VOLUME_CHANGED: AdEvent.AD_VOLUME_CHANGED,
    CONTENT_PAUSE_REQUESTED: AdEvent.CONTENT_PAUSE_REQUESTED,
    CONTENT_RESUME_REQUESTED: AdEvent.CONTENT_RESUME_REQUESTED,
    ALL_ADS_COMPLETED: AdEvent.ALL_ADS_COMPLETED,
    AD_BREAK_FETCH_ERROR: AdEvent.AD_BREAK_FETCH_ERROR,
    AD_BREAK_READY: AdEvent.AD_BREAK_READY,
    AD_CAN_PLAY: AdEvent.AD_CAN_PLAY,
    AD_METADATA: AdEvent.AD_METADATA,
    DURATION_CHANGE: AdEvent.AD_DURATION_CHANGE,
    IMPRESSION: AdEvent.AD_IMPRESSION
};

const AD_REQUEST_TIMEOUT_MS = 8000;

export class AdPlaybackManager implements TypedEventTarget<AdEventMap> {
    #adDisplayContainer: google.ima.AdDisplayContainer | null = null;
    #adsLoader: google.ima.AdsLoader | null = null;
    #adsManager: google.ima.AdsManager | null = null;
    #currentAd: Ad | null = null;
    #settings;
    #store;
    #playbackManager: HlsPlaybackManager;
    #isInitialized = false;
    #resizeUnsubscribe: (() => void) | null = null;
    #eventTarget: EventTarget = new EventTarget();
    #adScheduler: AdScheduler | null = null;
    #playbackManagerListeners: Array<() => void> = [];

    constructor(store: Store, settings: Settings, hlsPlaybackManager: HlsPlaybackManager) {
        this.#eventTarget = new EventTarget();
        this.#store = store;
        this.#settings = settings;
        this.#playbackManager = hlsPlaybackManager;
    }

    addEventListener<K extends keyof AdEventMap>(
        type: K,
        listener: (event: AdEventMap[K]) => void,
        options?: boolean | AddEventListenerOptions
    ): void {
        this.#eventTarget.addEventListener(type, listener as EventListener, options);
    }

    removeEventListener<K extends keyof AdEventMap>(
        type: K,
        listener: (event: AdEventMap[K]) => void,
        options?: boolean | EventListenerOptions
    ): void {
        this.#eventTarget.removeEventListener(type, listener as EventListener, options);
    }

    init() {
        const { adContainer, video } = this.#playbackManager.getElements();
        this.#adDisplayContainer = new google.ima.AdDisplayContainer(adContainer, video);
        this.#adsLoader = new google.ima.AdsLoader(this.#adDisplayContainer);
        if (this.#settings.vpaidEnabled) google.ima.settings.setVpaidMode(google.ima.ImaSdkSettings.VpaidMode.ENABLED);
        else google.ima.settings.setVpaidMode(google.ima.ImaSdkSettings.VpaidMode.DISABLED);

        const ppid = getPPID();
        if (ppid) {
            google.ima.settings.setPpid(getPPID());
        }

        const endedHandler = () => {
            this.#adsLoader?.contentComplete();
            this.#store.dispatch(setAdInitState(loadState.NONE));
        };
        this.#playbackManager.addEventListener(PlaybackEvent.ENDED, endedHandler);
        this.#playbackManagerListeners.push(() =>
            this.#playbackManager.removeEventListener(PlaybackEvent.ENDED, endedHandler)
        );

        this.#adsLoader.addEventListener(
            google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
            (evt: google.ima.AdsManagerLoadedEvent) => {
                this.#setupAdsManager(evt);
            },
            false
        );
        this.#adsLoader.addEventListener(
            google.ima.AdErrorEvent.Type.AD_ERROR,
            (evt: google.ima.AdErrorEvent) => {
                this.#dispatchEvent(AdEvent.AD_ERROR, this.#createAdError(evt.getError()));
            },
            false
        );
    }

    pause() {
        assertIsDefined(this.#adsManager);
        this.#adsManager.pause();
    }

    play() {
        assertIsDefined(this.#adsManager);
        this.#adsManager.resume();
    }

    setVolume(volume: number) {
        assertIsDefined(this.#adsManager);
        this.#adsManager.setVolume(volume);
    }

    setMuted(muted: boolean) {
        assertIsDefined(this.#adsManager);
        this.#adsManager.setVolume(muted ? 0 : 1);
    }

    destroy() {
        // Clean up ads manager
        if (this.#adsManager) {
            this.#adsManager.destroy();
            this.#adsManager = null;
        }

        // Clean up ads loader
        if (this.#adsLoader) {
            this.#adsLoader.destroy();
            this.#adsLoader = null;
        }

        // Clean up ad display container
        if (this.#adDisplayContainer) {
            this.#adDisplayContainer.destroy();
            this.#adDisplayContainer = null;
        }

        // Clean up playback manager listeners
        this.#playbackManagerListeners.forEach((remove) => remove());
        this.#playbackManagerListeners = [];

        // Reset state
        const { adInitState, isAdMode } = this.#store.getState();
        if (isAdMode) {
            this.#store.dispatch(stopAdMode());
        }

        if (adInitState !== loadState.NONE) {
            this.#store.dispatch(setAdInitState(loadState.NONE));
        }

        this.#currentAd = null;
    }

    async requestAds(position: AdPosition = 'preroll', signal?: AbortSignal) {
        assertIsDefined(this.#adsLoader);
        const isMidroll = position === 'midroll';
        if (this.#adsManager && !isMidroll) {
            this.#adsLoader.contentComplete();
            this.#adsManager.destroy();
            this.#adsManager = null;
        }

        // Mark current ad as requested
        this.#adScheduler?.markCurrentRequested();

        addPageAction('video-ad-request');
        createMark('videoRequestAds:start');
        const { height, width } = this.#store.getState();
        this.#store.dispatch(setAdInitState(loadState.STARTED));
        const adsRequest = getAdTag(isMidroll, this.#settings, this.#store);
        if (this.#settings.adTag === '') {
            try {
                // this.log('Fetching Amazon TAM bids, IAS params, and prebid params');
                const [tamResult, iasResult, prebidResult] = await Promise.allSettled([
                    fetchBidsTAM(width, height),
                    fetchIASParams(adsRequest.adTagUrl, this.#settings),
                    fetchBidsPrebid(width, height, this.#settings.plcmtOverride ?? 2)
                ]);

                if (tamResult.status === 'fulfilled') {
                    const tamParams = tamResult.value;
                    // this.log('Amazon TAM params', tamParams);
                    if (tamParams && tamParams.length > 0) {
                        adsRequest.adTagUrl += tamParams;
                    } else {
                        addPageAction('video-missing-tam-bids');
                    }
                } else {
                    noticeError(tamResult.reason, { 'video-error-type': 'fetchTAM' });
                    console.error('Failed to fetch tam bids', tamResult.reason);
                }

                if (iasResult.status === 'fulfilled') {
                    const iasParams = iasResult.value;
                    // this.log('IAS params', iasParams);
                    if (iasParams && iasParams.length > 0) {
                        adsRequest.adTagUrl += iasParams;
                    } else {
                        addPageAction('video-missing-ias-params');
                    }
                } else {
                    noticeError(iasResult.reason, { 'video-error-type': 'fetchIAS' });
                    console.error('Failed to fetch ias', iasResult.reason);
                }

                if (prebidResult.status === 'fulfilled') {
                    const preBidParams = prebidResult.value;

                    if (preBidParams && preBidParams.length) {
                        adsRequest.adTagUrl += encodeURIComponent(preBidParams);
                    }
                    // length > 100 because prebid sometimes adds a/b testing strings to results that are applied even if no bids are returned e.g. &hb_test=control
                    if (!preBidParams || preBidParams.length < 100) {
                        addPageAction('video-missing-prebid-params');
                    }
                } else {
                    noticeError(prebidResult.reason, { 'video-error-type': 'fetchPrebid' });
                    console.error('Failed to fetch prebid', prebidResult.reason);
                }
            } catch (e) {
                console.error('Unexpected error occured fetching TAM, IAS, and prebid params in ad call', e);
            }
        }

        if (signal?.aborted) {
            return adsRequest;
        }

        adsRequest.linearAdSlotWidth = width;
        adsRequest.linearAdSlotHeight = height;
        adsRequest.nonLinearAdSlotWidth = width;
        adsRequest.nonLinearAdSlotHeight = height;
        this.#adsLoader.requestAds(adsRequest);

        return new Promise<google.ima.AdsRequest>((resolve, reject) => {
            if (signal?.aborted) {
                resolve(adsRequest);
                return;
            }

            const timeoutId = setTimeout(() => {
                this.#dispatchEvent(AdEvent.AD_ERROR, {
                    code: 1,
                    msg: `No IMA Loader Response After ${AD_REQUEST_TIMEOUT_MS / 1000} Seconds`
                });
                cleanup();
                reject(new Error('Timeout'));
            }, AD_REQUEST_TIMEOUT_MS);

            const cleanup = () => {
                createMark('videoRequestAds:end');
                clearTimeout(timeoutId);
                this.#adsLoader!.removeEventListener(
                    google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
                    loadedListener
                );
                this.#adsLoader!.removeEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, errorListener);
            };

            const loadedListener = () => {
                cleanup();
                resolve(adsRequest);
            };

            const errorListener = (evt: google.ima.AdErrorEvent) => {
                cleanup();
                reject(evt.getError());
            };

            signal?.addEventListener('abort', loadedListener);
            this.#adsLoader!.addEventListener(google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED, loadedListener);
            this.#adsLoader!.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, errorListener);
        });
    }

    /**
     * Starts ad playback. Promise resolves when content resumes or rejects if an error occurs.
     * @param position - The position of the ad to play
     * @param signal - An optional AbortSignal to cancel the ad start
     */
    async start(position: AdPosition = 'preroll', signal?: AbortSignal) {
        this.#dispatchEvent(AdEvent.AD_START_REQUESTED);
        const { height, width, adInitState } = this.#store.getState();
        if (!this.#isInitialized) {
            this.#adDisplayContainer?.initialize();
            this.#isInitialized = true;
        }

        if (adInitState === loadState.NONE) {
            await this.requestAds(position, signal);
        }

        if (signal?.aborted) {
            return;
        }

        await this.#waitForAd();
        createMark('videoAdLoad:start');
        this.#store.dispatch(startAdMode());
        this.#adsManager!.init(width, height, google.ima.ViewMode.NORMAL);
        this.#adsManager!.start();
        return new Promise<void>((resolve, reject) => {
            if (signal?.aborted) {
                resolve();
                return;
            }

            const cleanup = () => {
                this.#adsManager!.removeEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, loadedListener);
                this.#adsManager!.removeEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, errorListener);
            };

            const loadedListener = () => {
                cleanup();
                resolve();
            };

            const errorListener = (evt: google.ima.AdErrorEvent) => {
                cleanup();
                reject(evt.getError());
            };

            signal?.addEventListener('abort', loadedListener);
            this.#adsManager!.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, loadedListener);
            this.#adsManager!.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, errorListener);
        });
    }

    /**
     * Updates ad configurations and initializes scheduler
     */
    updateAdConfigs(adConfigs: AdConfig[]) {
        this.#adScheduler = new AdScheduler(adConfigs, this.#store);
    }

    #createAdError(error: google.ima.AdError | null): AdError {
        const errorObj: AdError = {};
        errorObj.code = error?.getErrorCode();
        errorObj.msg = error?.getMessage();
        if (this.#currentAd) {
            errorObj.ad = this.#currentAd;
        }

        if (typeof errorObj.msg !== 'undefined' && errorObj.msg !== null) {
            const innerErr = error?.getInnerError();
            if (typeof innerErr !== 'undefined' && innerErr !== null) {
                errorObj.msg = errorObj.msg + ' - ' + innerErr;
            }
        } else {
            errorObj.msg = 'A null was recieved from ima getMessage';
        }
        return errorObj;
    }

    #dispatchEvent<K extends keyof AdEventMap>(
        type: K,
        detail?: (AdEventMap[K] extends CustomEvent<infer D> ? D : never) | null
    ) {
        this.#eventTarget.dispatchEvent(new CustomEvent(type, { detail }));
    }

    #setupAdsManager(evt: google.ima.AdsManagerLoadedEvent) {
        const { adContainer, video } = this.#playbackManager.getElements();
        this.#store.dispatch(setAdInitState(loadState.FINISHED));

        const adsRenderingSettings = new google.ima.AdsRenderingSettings();
        adsRenderingSettings.enablePreloading = true;
        adsRenderingSettings.loadVideoTimeout = 8000;
        adsRenderingSettings.mimeTypes = ['video/mp4'];
        adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
        adsRenderingSettings.uiElements = [google.ima.UiElements.AD_ATTRIBUTION]; // hide countdown as it interferes with the skip button
        this.#adsManager = evt.getAdsManager(video, adsRenderingSettings);

        // #region Resize the ad manager when the player resizes
        const resizeAd = () => {
            const { width, height, isFullScreen } = this.#store.getState();
            this.#adsManager?.resize(
                width,
                height,
                isFullScreen ? google.ima.ViewMode.FULLSCREEN : google.ima.ViewMode.NORMAL
            );
        };
        let previousWidth = this.#store.getState().width;
        let previousHeight = this.#store.getState().height;
        const handleResize = () => {
            const { width, height } = this.#store.getState();
            if (width !== previousWidth || height !== previousHeight) {
                previousWidth = width;
                previousHeight = height;
                resizeAd();
            }
        };
        this.#resizeUnsubscribe?.();
        this.#resizeUnsubscribe = this.#store.subscribe(handleResize);
        // Make sure ad is the correct size when the ad starts
        this.#adsManager.addEventListener(google.ima.AdEvent.Type.STARTED, () => resizeAd());
        //#endregion

        this.#dispatchEvent(AdEvent.AD_MANAGER_LOADED, {
            adContainer,
            adsManager: this.#adsManager
        });

        //#region Handle state updates to the ad schedule
        this.#adsManager.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, () => {
            this.#adScheduler?.markCurrentPlayed();
        });

        const timeUpdateHandler = async () => {
            if (!this.#adScheduler || this.#store.getState().isAdMode) return;

            const currentTime = this.#playbackManager.currentTime;
            const nextAd = this.#adScheduler.getNextAd(currentTime);
            if (nextAd) {
                this.#playbackManager.pause();
                try {
                    this.requestAds(nextAd.type);
                    await this.start(nextAd.type);
                } catch (error: any) {
                    console.error('Failed to play midroll ad:', error);
                } finally {
                    this.#playbackManager.play();
                }
            }
        };
        this.#playbackManager.addEventListener(PlaybackEvent.TIMEUPDATE, timeUpdateHandler);
        this.#playbackManagerListeners.push(() =>
            this.#playbackManager.removeEventListener(PlaybackEvent.TIMEUPDATE, timeUpdateHandler)
        );

        const seekedHandler = () => {
            if (!this.#adScheduler || this.#store.getState().isAdMode) return;
            const currentTime = this.#playbackManager.currentTime;
            this.#adScheduler.handleSeek(currentTime);
        };
        this.#playbackManager.addEventListener(PlaybackEvent.SEEKED, seekedHandler);
        this.#playbackManagerListeners.push(() =>
            this.#playbackManager.removeEventListener(PlaybackEvent.SEEKED, seekedHandler)
        );
        //#endregion

        // Make sure video muted state is in sync with ad muted state
        this.#adsManager.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, () => {
            this.#playbackManager.setMute(this.#store.getState().isMuted);
        });

        //#region Forward ad events to consumers of this class

        // Store info about the current ad when it is loaded
        this.#adsManager.addEventListener(google.ima.AdEvent.Type.LOADED, (e: google.ima.AdEvent) => {
            createMark('videoAdLoad:end');
            const ad = e.getAd();
            if (!ad) {
                return;
            }

            let id = '';
            try {
                const wrappers = ad.getWrapperAdIds().join(',');
                if (ad.getAdId()) {
                    id = `${wrappers},${ad.getAdId()}`;
                } else {
                    id = 'none';
                }
            } catch (e) {
                id = 'none';
            }

            this.#currentAd = {
                id,
                imaAd: ad,
                isCustomPlayback: this.#adsManager!.isCustomPlaybackUsed()
            };
            this.#dispatchEvent(AdEvent.AD_LOADED, this.#currentAd);
        });

        this.#adsManager.addEventListener(google.ima.AdEvent.Type.AD_PROGRESS, (e: google.ima.AdEvent) => {
            const { currentTime, duration } = e.getAdData() as any;
            const data: AdProgressData = {
                currentTime,
                duration,
                imaAd: e.getAd()
            };
            this.#dispatchEvent(AdEvent.AD_PROGRESS, data);
        });

        // Forward IMA events that are mapped
        for (const eventType of Object.keys(google.ima.AdEvent.Type)) {
            this.#adsManager.addEventListener(
                google.ima.AdEvent.Type[eventType as keyof typeof google.ima.AdEvent.Type],
                () => {
                    // Map IMA event to our custom event if we have a mapping
                    const customEventType = imaToCustomEventMap[eventType];
                    if (customEventType) {
                        this.#dispatchEvent(customEventType, this.#currentAd);
                    }
                }
            );
        }

        // Handle ad errors separately
        this.#adsManager.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, (e: google.ima.AdErrorEvent) => {
            const omnitureErrorObj = this.#createAdError(e.getError());
            this.#dispatchEvent(AdEvent.AD_ERROR, omnitureErrorObj);
        });
        //#endregion
    }

    #waitForAd() {
        return new Promise<void>((resolve, reject) => {
            const { adInitState } = this.#store.getState();
            if (adInitState === loadState.STARTED) {
                // Ad is still loading. So subscribe to the store and resolve promise when finished
                let unsubscribe: (() => void) | null = null;
                const handleStateChange = () => {
                    const { adInitState } = this.#store.getState();
                    if (adInitState !== loadState.STARTED) {
                        unsubscribe?.();
                        if (adInitState === loadState.FINISHED) {
                            resolve();
                        } else {
                            reject();
                        }
                    }
                };
                unsubscribe = this.#store.subscribe(handleStateChange);
            } else if (adInitState === loadState.FAILED) {
                reject();
            } else {
                resolve();
            }
        });
    }
}
