import type HlsInstance from 'hls.js';
import type { HlsConfig } from 'hls.js';
import { Store } from '../store';
import { VideoPlaybackManager } from './index';
import { Settings } from '../../types';
import { createMark } from '../utils';
import * as loadState from '../loadState';
import { setHlsJsLoadState, showClickForSound, mute, setAutoplay, exitFullScreen, enterFullScreen } from '../actions';
import { VideoData } from '../../types';
import { markVideoEvents } from '../perf-utils';
import { isMobile, isIOSDevice, isSafariMac, isIphone, isChromeIOS } from '../userAgentInfo';
// file-loader will make this import return a url to the worker file
//@ts-ignore
import hlsJsWorker from 'hls.js/dist/hls.worker.js';

const RETRY_LOAD_ATTEMPTS_MAX = 2;

let Hls: typeof HlsInstance;

export class HlsPlaybackManager extends EventTarget implements VideoPlaybackManager {
    #adContainerEl: HTMLDivElement | null;
    #containerEl: HTMLDivElement | null;
    #videoEl: HTMLVideoElement | null;
    #store: Store;
    #settings: Settings;
    #hlsConfig: Partial<HlsConfig>;
    #hlsPlayer: HlsInstance | null = null;
    #isHlsSupported = false;
    #retryLoadAttempts: number = 0;

    constructor(store: Store, settings: Settings) {
        super();
        this.#adContainerEl = null;
        this.#videoEl = null;
        this.#containerEl = null;
        this.#store = store;
        this.#settings = settings;
        this.#hlsConfig = {
            autoStartLoad: true,
            capLevelToPlayerSize: true,
            enableWebVTT: true,
            maxMaxBufferLength: 30,
            startPosition: this.#settings.startPosition
        };
        if (this.#settings.startLevel) {
            this.#hlsConfig.startLevel = this.#settings.startLevel;
        }
        this.#isHlsSupported = settings.useHLS ?? true;
    }

    get bitrate(): number {
        if (this.#hlsPlayer && this.#hlsPlayer.currentLevel !== -1) {
            // @ts-ignore - Not sure why we're accessing a private field for bitrate
            return this.#hlsPlayer.levelController._levels[this.#hlsPlayer.currentLevel].bitrate;
        } else if (this.#videoEl?.videoWidth) {
            const bitrates = [
                { w: 416, b: 464 },
                { w: 640, b: 1264 },
                { w: 960, b: 1864 },
                { w: 1280, b: 2564 },
                { w: 1920, b: 5064 }
            ];
            let bitrate = 0;
            for (const bit of bitrates) {
                if (bit.w <= this.#videoEl.videoWidth) bitrate = bit.b;
            }
            return bitrate;
        }
        return 0;
    }

    get currentTime(): number {
        return this.#videoEl?.currentTime ?? 0;
    }

    get duration(): number {
        return this.#videoEl?.duration ?? 0;
    }

    get fullscreen(): boolean {
        if (!this.#videoEl || !this.#containerEl) return false;
        return this.#shouldUseWebkitFullscreen()
            ? (this.#videoEl as any).webkitDisplayingFullscreen
            : document.fullscreenElement === this.#containerEl;
    }

    get muted(): boolean {
        return this.#videoEl?.muted ?? false;
    }

    get paused(): boolean {
        return this.#videoEl?.paused ?? true;
    }

    get readyState(): number {
        return this.#videoEl?.readyState ?? 0;
    }

    get volume(): number {
        return this.#videoEl?.volume ?? 0;
    }

    attachElements(elements: Record<string, HTMLElement>): void {
        if (!elements.video) {
            throw new Error('Video element is required');
        }
        if (!elements.adContainer) {
            throw new Error('Ad container element is required');
        }
        if (!elements.container) {
            throw new Error('Container element is required');
        }

        this.#videoEl = elements.video as HTMLVideoElement;
        this.#adContainerEl = elements.adContainer as HTMLDivElement;
        this.#containerEl = elements.container as HTMLDivElement;

        // Add props directly to the video element after it's been rendered. This is necessary because some of the props don't get added correctly when hydrating server rendered content.
        if (this.#store.getState().autoplay === 'muted' && (isMobile() || this.#settings.clickForSound == true)) {
            this.#videoEl.autoplay = true;
            this.#videoEl.muted = true;
        }

        if (this.#settings.playInline) this.#videoEl.playsInline = true;
        if (this.#settings.loop) this.#videoEl.loop = true;

        if (this.#store.getState().isMuted) {
            this.#videoEl.muted = true;
        }

        // Hide certain elements before playback starts
        this.#videoEl.style.visibility = 'hidden';
        this.#adContainerEl.style.visibility = 'hidden';

        if (!this.#settings.disableHtmlControls) {
            this.#videoEl.controls = false;
        } else {
            if (!this.#settings.fireTv) this.#videoEl.controls = true;
            else this.#videoEl.controls = false;
        }

        if (this.#shouldUseWebkitFullscreen()) {
            this.#videoEl.addEventListener('webkitendfullscreen', () => {
                this.dispatchEvent(new CustomEvent('exitfullscreen'));
            });
            this.#videoEl.addEventListener('webkitbeginfullscreen', () => {
                this.dispatchEvent(new CustomEvent('enterfullscreen'));
            });
        } else {
            document.addEventListener('fullscreenchange', () => {
                if (document.fullscreenElement === this.#containerEl) {
                    this.dispatchEvent(new CustomEvent('enterfullscreen'));
                } else {
                    this.dispatchEvent(new CustomEvent('exitfullscreen'));
                }
            });
        }

        markVideoEvents(this.#videoEl);

        // forward for consumers of this class
        const events = [
            'canplay',
            'canplaythrough',
            'click',
            'dblclick',
            'durationchanged',
            'ended',
            'error',
            'loadeddata',
            'loadedmetadata',
            'pause',
            'play',
            'playing',
            'resize',
            'seeked',
            'timeupdate',
            'volumechange',
            'waiting'
        ];
        events.forEach((event) => {
            let useCapture = false;
            if (isMobile() && event === 'click') {
                useCapture = true;
            }
            this.#videoEl?.addEventListener(
                event,
                (e) => {
                    this.dispatchEvent(new CustomEvent(event, { detail: e }));
                },
                useCapture
            );
        });
    }

    init(): Promise<any> {
        const promises = [];
        // Hls.js
        if (this.#isHlsSupported) {
            if (typeof Hls === 'undefined') {
                this.#store.dispatch(setHlsJsLoadState(loadState.FINISHED));
                createMark('hlsJsLoad:start');
                const hlsJsPromise = import('hls.js')
                    .then((module) => {
                        Hls = module.default;
                        window.Hls = Hls;
                        this.#isHlsSupported = Hls.isSupported();
                    })
                    .catch((err) => {
                        console.error(err);
                        this.#store.dispatch(setHlsJsLoadState(loadState.FAILED));
                        this.#isHlsSupported = false;
                    });
                const hlsJsWorkerPromise = fetch(hlsJsWorker)
                    .then((res) => {
                        if (!res.ok) throw new Error(`Failed to load hls.js web worker: ${res.status}`);
                        return res.blob();
                    })
                    .then((blob) => {
                        this.#hlsConfig.workerPath = URL.createObjectURL(blob);
                    })
                    .catch((err) => console.error(err));
                promises.push(
                    Promise.all([hlsJsPromise, hlsJsWorkerPromise]).finally(() => {
                        if (this.#store.getState().hlsJsLoadState !== loadState.FAILED) {
                            this.#store.dispatch(setHlsJsLoadState(loadState.FINISHED));
                        }
                        createMark('hlsJsLoad:end');
                    })
                );
            } else if (this.#store.getState().hlsJsLoadState === loadState.NONE) {
                // Hls is already loaded so update the state
                this.#store.dispatch(setHlsJsLoadState(loadState.FINISHED));
            }
        }

        if (this.#store.getState().autoplay === true) {
            promises.push(
                import('../test-autoplay')
                    .then(({ testAutoplay }) =>
                        testAutoplay({ muted: false }).then((test) => {
                            if (!test.result) {
                                // Autoplay with sound is not allowed so we need to autoplay muted
                                const { isMuted, isClickForSoundVisible } = this.#store.getState();
                                if (!isClickForSoundVisible) {
                                    this.#store.dispatch(showClickForSound());
                                }
                                if (!isMuted) {
                                    this.setMute(true);
                                    this.#store.dispatch(mute());
                                }
                                this.#store.dispatch(setAutoplay('muted'));
                            }
                            return test;
                        })
                    )
                    .catch((err) => {
                        console.error('Failed to load autoplay test', err);
                    })
            );
        }

        return Promise.all(promises);
    }

    loadContent(videoData: VideoData): void {
        if (!this.#videoEl) {
            throw new Error('Video element is not attached');
        }

        this.#videoEl.style.visibility = 'hidden';

        if (isIOSDevice() || this.#settings.fireTv || isSafariMac()) {
            if (this.#settings.startPosition !== -1)
                this.#videoEl.setAttribute('src', videoData.hlsNoCaptions + '#t=' + this.#settings.startPosition);
            else this.#videoEl.setAttribute('src', videoData.hlsNoCaptions ?? '');
        } else {
            if (
                this.#settings.useWebm &&
                videoData.videoBestQualityWebmUrl &&
                this.#videoEl.canPlayType('video/webm; codecs="vp9"')
            ) {
                this.#videoEl.setAttribute('src', videoData.videoBestQualityWebmUrl);
            } else if (
                this.#settings.useHLS &&
                videoData.hlsNoCaptions &&
                !videoData.hlsNoCaptions.includes('wsjvod-i.akamaihd.net')
            ) {
                if (this.#hlsPlayer) {
                    this.#hlsPlayer.destroy();
                }
                this.#hlsPlayer = this.#createHlsPlayer(videoData);
                this.#hlsPlayer.loadSource(videoData.hlsNoCaptions);
                this.#hlsPlayer.attachMedia(this.#videoEl);
            } else {
                if (videoData.video664kMP4Url) {
                    this.#videoEl.setAttribute('src', videoData.video664kMP4Url);
                } else if (videoData.videoMP4List?.[2]) {
                    this.#videoEl.setAttribute('src', videoData.videoMP4List[2].url);
                } else {
                    this.#videoEl.setAttribute('src', videoData.videoMP4List?.[0].url ?? '');
                }
            }
        }
        if (videoData.captionsVTT && videoData.captionsVTT.length > 0) {
            this.applyCaptions(videoData.captionsVTT);
        }
    }

    applyCaptions(CaptionsVtt: any[]): void {
        if (!this.#videoEl) {
            throw new Error('Video element is not attached');
        }

        const currentTracks = this.#videoEl.querySelectorAll('track');
        currentTracks.forEach((track) => track.remove());

        CaptionsVtt.forEach((caption) => {
            const track = document.createElement('track');
            track.kind = 'subtitles';
            track.label = caption.label;
            track.srclang = caption.lang;
            track.src = caption.url;
            track.addEventListener('load', () => {
                const textTrack = track.track;
                if (textTrack && textTrack.cues) {
                    for (let i = 0; i < textTrack.cues.length; i++) {
                        const cue = textTrack.cues[i] as VTTCue;
                        if (cue.line === 'auto') cue.line = -4;
                    }
                }
            });
            this.#videoEl?.appendChild(track);
        });
        this.#videoEl.textTracks[0].mode = 'showing';
    }

    pause(): void {
        if (!this.#videoEl) return;
        this.#videoEl.pause();
    }

    play(): Promise<void> {
        if (!this.#videoEl) {
            return Promise.resolve();
        }
        this.#videoEl!.style.visibility = 'visible';
        return this.#videoEl.play();
    }

    seek(time: number): void {
        if (!this.#videoEl) return;
        this.#videoEl.currentTime = time;
    }

    showHideClosedCaptions(show: boolean): void {
        if (!this.#videoEl) return;
        if (this.#videoEl.textTracks.length === 0) return;

        if (show) {
            this.#videoEl.textTracks[0].mode = 'showing';
        } else {
            this.#videoEl.textTracks[0].mode = 'hidden';
        }
    }

    setFullscreen(fullscreen: boolean): void {
        if (!this.#videoEl || !this.#containerEl) return;
        if (fullscreen) {
            this.#shouldUseWebkitFullscreen()
                ? (this.#videoEl as any).webkitEnterFullscreen()
                : this.#containerEl
                      .requestFullscreen()
                      .catch((error) => console.error('Failed to enter fullscreen:', error));
            this.#store.dispatch(enterFullScreen());
        } else {
            if (this.#shouldUseWebkitFullscreen()) {
                (this.#videoEl as any).webkitExitFullscreen();
            } else if (document.fullscreenElement === this.#containerEl) {
                document.exitFullscreen().catch((error) => console.error('Failed to exit fullscreen:', error));
            }
            this.#store.dispatch(exitFullScreen());
        }
    }

    setMute(muted: boolean): void {
        if (!this.#videoEl) return;
        this.#videoEl.muted = muted;
    }

    setVolume(level: number): void {
        if (!this.#videoEl) return;
        this.#videoEl.volume = level;
    }

    async start(): Promise<void> {
        if (!this.#videoEl) {
            return Promise.reject(new Error('Video element is not attached'));
        }

        const waitForMetadata = () => {
            createMark('videoMetadataLoad:start');
            return new Promise<void>((resolve) => {
                const innerResolve = () => {
                    createMark('videoMetadataLoad:end');
                    resolve();
                };

                if (this.#videoEl!.readyState >= 1) {
                    innerResolve();
                    return;
                }

                const handler = () => {
                    innerResolve();
                };
                this.#videoEl!.addEventListener('loadedmetadata', handler, { once: true });
                this.#videoEl!.load();
            });
        };

        try {
            await waitForMetadata();
            await this.play();
            this.#videoEl!.style.visibility = 'visible';
            this.dispatchEvent(new CustomEvent('playbacksuccess'));
        } catch (error) {
            this.dispatchEvent(new CustomEvent('playbackfailed', { detail: error }));
        }
    }

    #createHlsPlayer(videoData: VideoData): HlsInstance {
        const hlsPlayer = new Hls({ ...this.#hlsConfig, startPosition: this.#settings.startPosition });

        hlsPlayer.on(Hls.Events.ERROR, (e, d) => {
            if (hlsPlayer && (d.fatal || d.details == 'bufferAppendingError')) {
                if (this.#retryLoadAttempts > RETRY_LOAD_ATTEMPTS_MAX) d.type = Hls.ErrorTypes.OTHER_ERROR;

                this.#retryLoadAttempts++;

                switch (d.type) {
                    case Hls.ErrorTypes.MEDIA_ERROR:
                        hlsPlayer.recoverMediaError();
                        break;
                    case Hls.ErrorTypes.NETWORK_ERROR:
                        hlsPlayer.loadSource(videoData.hlsNoCaptions as string);
                        hlsPlayer.startLoad();
                        break;
                    default:
                        this.dispatchEvent(new CustomEvent('fatalerror', { detail: d.details }));
                        hlsPlayer.destroy();
                        break;
                }
            }
        });
        hlsPlayer.on(Hls.Events.LEVEL_LOADED, (e, d) => {
            const newDuration = d.details.totalduration;
            if (
                newDuration > 0 &&
                newDuration !== this.#store.getState().duration &&
                this.#store.getState().videoData.state !== 'live'
            ) {
                this.dispatchEvent(new CustomEvent('durationchange', { detail: newDuration }));
            }
        });
        hlsPlayer.on(Hls.Events.LEVEL_SWITCHED, (e, d) => {
            if (hlsPlayer?.levels?.[d.level]) {
                this.dispatchEvent(new CustomEvent('bitratechange', { detail: this.bitrate }));
            }
        });

        Object.keys(Hls.Events).forEach((event) => {
            // @ts-ignore - Can't figure out the types for Hls.Events
            hlsPlayer.on(Hls.Events[event], () => createMark(`hlsEvent:${event}`));
        });
        return hlsPlayer;
    }

    #shouldUseWebkitFullscreen(): boolean {
        return isIphone() || isChromeIOS();
    }
}
