import { h, FunctionalComponent, Fragment, JSX } from 'preact';
import { useCallback, useState, useRef, useMemo } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { hideShareScreen, setFocus } from '../../actions';
import Modal from '../Modal';
import { useSelector } from '../../hooks';
import styles from './styles.css';
import copyLinkSvg from '../../../svg/link.svg';
import embedSvg from '../../../svg/embed.svg';
import facebookSvg from '../../../svg/Facebook.svg';
import twitterSvg from '../../../svg/Twitter.svg';
import checkBoxSvg from '../../../svg/checkbox-circular-stroke.svg';
import { Settings } from '../../../types';

interface ShareModalProps extends Pick<Settings, 'shareDomain'> {
    onShare?: (platform: 'copylink' | 'embed' | 'Facebook' | 'Twitter') => void;
}

type NotificationType = 'copylink' | 'embed' | null;

const ShareModal: FunctionalComponent<ShareModalProps> = ({ shareDomain, onShare }) => {
    const videoData = useSelector((state) => state.videoData);
    const videoId = useSelector((state) => state.id);
    const { linkURL, linkRelativeURL, name } = videoData;
    const isVisible = useSelector((state) => state.isSharescreenVisible);
    const currentBreakpoint = useSelector((state) => state.currentBreakpoint);
    const dispatch = useDispatch();
    const [notification, setNotification] = useState<NotificationType>(null);
    const copyLinkButtonRef = useRef<HTMLButtonElement>(null);

    const shareURL = useMemo(() => {
        if (typeof window === 'undefined') return linkURL;

        const url = window.location != window.parent.location ? document.referrer : document.location.href;
        if (shareDomain) {
            return shareDomain + linkRelativeURL;
        } else if (url.indexOf('marketwatch.com') !== -1) {
            return 'http://www.marketwatch.com' + linkRelativeURL;
        } else if (url.indexOf('barrons.com') !== -1) {
            return 'http://www.barrons.com' + linkRelativeURL;
        }
        return linkURL;
    }, [linkURL, linkRelativeURL, shareDomain]);

    const hideShareModal = useCallback(() => {
        dispatch(hideShareScreen());
    }, []);

    const handleCloseClick = useCallback(() => {
        hideShareModal();
        dispatch(setFocus('share-btn'));
    }, [hideShareModal]);

    // close the modal and focus on the previous control when tabbing out of the modal
    const handleCopyLinkKeyDown = useCallback(
        (e: JSX.TargetedKeyboardEvent<HTMLButtonElement>) => {
            if (e.key === 'Tab' && e.shiftKey) {
                e.preventDefault();
                hideShareModal();
                dispatch(setFocus('volume-slider'));
            }
        },
        [hideShareModal]
    );

    // close the modal and focus on the next control when tabbing out of the modal
    const handleCloseKeyDown = useCallback(
        (e: JSX.TargetedKeyboardEvent<HTMLButtonElement>) => {
            if (e.key === 'Tab' && !e.shiftKey) {
                e.preventDefault();
                hideShareModal();
                dispatch(setFocus('share-btn'));
            }
        },
        [hideShareModal]
    );

    // focus on the first button when the modal is visible
    const handleFadeIn = useCallback(() => {
        if (copyLinkButtonRef.current) {
            copyLinkButtonRef.current.focus();
        }
    }, []);

    const handleFadeOut = useCallback(() => {
        setNotification(null);
    }, []);

    const handleCopyLink = useCallback(() => {
        navigator.clipboard.writeText(shareURL!).then(() => {
            setNotification('copylink');
            onShare?.('copylink');
        });
    }, [shareURL, onShare]);

    const handleCopyEmbed = useCallback(() => {
        const embedCode = `<iframe allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="512" height="288" src="https://video-api.wsj.com/api-video/player/v3/iframe.html?guid=${
            videoData.guid
        }${shareDomain ? `&shareDomain=${shareDomain}` : ''}"></iframe>`;
        navigator.clipboard.writeText(embedCode).then(() => {
            setNotification('embed');
            onShare?.('embed');
        });
    }, [videoData, shareDomain, onShare]);

    const showNotification = isVisible && notification;
    const notificationId = notification === 'copylink' ? 'copy-link-notification' : 'copy-embed-notification';
    const notificationText =
        notification === 'copylink' ? 'Link copied to clipboard' : 'Embed code copied to clipboard';
    const copyNotifictation = (
        <>
            <img src={checkBoxSvg} loading="lazy" alt="Checkbox" />
            <span className={styles.notificationText} aria-live="polite">
                {notificationText}
            </span>
        </>
    );

    return (
        <>
            <Modal
                id={notificationId}
                className={styles.notification}
                isVisible={showNotification && (currentBreakpoint === '16U' || currentBreakpoint === '12U')}
                hideCloseButton
            >
                {copyNotifictation}
            </Modal>
            <Modal
                className={styles.modal}
                isVisible={isVisible}
                onClose={handleCloseClick}
                onCloseKeyDown={handleCloseKeyDown}
                onFadeIn={handleFadeIn}
                onFadeOut={handleFadeOut}
                id={`video-share-${videoId}`}
                tabIndex={isVisible ? 0 : -1}
                role="menu"
                aria-expanded="false"
                aria-labelledby={`video-share-b-${videoId}`}
            >
                {showNotification && currentBreakpoint !== '16U' && currentBreakpoint !== '12U' && (
                    <div id={notificationId} className={styles.notification}>
                        {copyNotifictation}
                    </div>
                )}
                <div className={styles.btns}>
                    <div className={styles.btn}>
                        <button
                            ref={copyLinkButtonRef}
                            onKeyDown={handleCopyLinkKeyDown}
                            title="Copy Link"
                            aria-label="Copy Link"
                            id="copy-link-url-image"
                            data-space="false"
                            data-testid="video-share-copy-link"
                            onClick={handleCopyLink}
                            role="menuitem"
                        >
                            <img src={copyLinkSvg} loading="lazy" alt="Copy Link" />
                            Copy Link
                        </button>
                    </div>
                    <div className={styles.btn}>
                        <button
                            id="copy-embed"
                            data-space="false"
                            data-testid="video-share-embed"
                            onClick={handleCopyEmbed}
                            role="menuitem"
                        >
                            <img src={embedSvg} loading="lazy" alt="Copy Embed" />
                            Copy Embed
                        </button>
                    </div>
                    <div className={styles.btn}>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Share on Facebook"
                            href={`https://www.facebook.com/sharer/sharer.php?u=${shareURL}&t=${name}`}
                            data-space="false"
                            data-testid="video-share-facebook"
                            onClick={() => onShare?.('Facebook')}
                            role="menuitem"
                        >
                            <img src={facebookSvg} loading="lazy" alt="Share on Facebook" />
                            Facebook
                        </a>
                    </div>
                    <div className={styles.btn}>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Share on Twitter"
                            href={`https://twitter.com/intent/tweet?url=${shareURL}&text=${name}`}
                            data-space="false"
                            data-testid="video-share-twitter"
                            onClick={() => onShare?.('Twitter')}
                            role="menuitem"
                        >
                            <img src={twitterSvg} loading="lazy" alt="Share on Twitter" />
                            Twitter
                        </a>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ShareModal;
