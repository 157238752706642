import { h } from 'preact';
import { useSelector } from '../../hooks';
import * as loadState from '../../loadState';
import styles from './styles.css';

const MidrollLoadingScreen = () => {
    const isMidRollLoading = useSelector((state) => {
        return (
            state.adInitState === loadState.STARTED &&
            state.adConfigs.some((config) => config.type === 'midroll' && config.isRequested)
        );
    });

    if (!isMidRollLoading) return null;

    return (
        <div className={styles.loadingScreen}>
            <div className={styles.videoLoading}>
                <div className={styles.videoLoadingInner} />
            </div>
            <span>Advertisement loading. Please Wait.</span>
        </div>
    );
};

export default MidrollLoadingScreen;
