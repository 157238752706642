import { Store } from '../store';
import { AdConfig } from '../../types';
import { setAdConfigs } from '../actions';

/**
 * Manages ad scheduling and tracking during video playback
 */
export class AdScheduler {
    #lastKnownTime = 0;
    #adConfigs: AdConfig[];
    #store: Store;
    #currentAd: AdConfig | null = null; // new property to track current ad

    /**
     * Creates a new AdScheduler with the provided ad configurations
     */
    constructor(adConfigs: AdConfig[], store: Store) {
        this.#adConfigs = adConfigs;
        this.#store = store;
        this.#validateAndSort();
        this.#syncToStore();
        this.#setCurrentAd(adConfigs[0]);
    }

    /**
     * Sets the current ad and updates related state
     */
    #setCurrentAd(ad: AdConfig | null) {
        this.#currentAd = ad;
    }

    /**
     * Validates and sorts ad configurations by time
     */
    #validateAndSort() {
        this.#adConfigs = this.#adConfigs
            .filter((ad) => Number.isFinite(ad.time) && ad.time >= 0 && ['preroll', 'midroll'].includes(ad.type))
            .sort((a, b) => a.time - b.time);
    }

    /**
     * Syncs the current ad configurations to the store
     */
    #syncToStore() {
        this.#store.dispatch(setAdConfigs(this.#adConfigs.map((ad) => ({ ...ad }))));
    }

    /**
     * Gets the next ad that should be played based on current playback time
     */
    getNextAd(currentTime?: number): AdConfig | undefined {
        if (!currentTime) {
            currentTime = this.#store.getState().currentPosition;
        }

        for (let i = this.#adConfigs.length - 1; i >= 0; i--) {
            const ad = this.#adConfigs[i];
            if (!ad.played && !ad.isRequested && currentTime >= ad.time) {
                this.#setCurrentAd(ad);
                return ad;
            }
        }
        return undefined;
    }

    /**
     * Handles seek operations, marking ads as played when seeking forward
     * @returns Array of ads that were marked as played
     */
    handleSeek(newTime: number): AdConfig[] {
        const isForwardSeek = newTime > this.#lastKnownTime;
        const markedAds: AdConfig[] = [];

        if (isForwardSeek) {
            // Process ads in reverse order to mark ads that were skipped as played
            for (let i = this.#adConfigs.length - 1; i >= 0; i--) {
                const ad = this.#adConfigs[i];
                if (ad.type === 'midroll' && !ad.played && ad.time > this.#lastKnownTime && ad.time <= newTime) {
                    ad.played = true;
                    markedAds.push(ad);
                    this.#setCurrentAd(ad);
                }
            }

            if (markedAds.length > 0) {
                this.#syncToStore();
            }
        }

        this.#lastKnownTime = newTime;
        return markedAds;
    }

    /**
     * Resets ad states when seeking backward
     * Only resets midroll ads that come after the new seek position
     */
    resetForSeek(newTime: number) {
        // TODO: This isn't used yet, but could be useful in the future
        let changed = false;
        this.#adConfigs.forEach((ad) => {
            if (ad.time > newTime && ad.type === 'midroll') {
                ad.played = false;
                ad.isRequested = false;
                changed = true;
            }
        });
        if (changed) {
            this.#syncToStore();
        }
    }

    markCurrentPlayed() {
        if (this.#currentAd) {
            this.#currentAd.played = true;
            this.#syncToStore();
        }
    }

    markCurrentRequested() {
        if (this.#currentAd) {
            this.#currentAd.isRequested = true;
            this.#syncToStore();
        }
    }
}
