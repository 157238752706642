import { createAction } from '@reduxjs/toolkit';
import * as actionTypes from './actionTypes';

export const startAdMode = createAction(actionTypes.AD_STARTED);
export const stopAdMode = createAction(actionTypes.AD_COMPLETE);
export const updateAdProgress = createAction(actionTypes.AD_PROGRESS);
export const updateAdDuration = createAction(actionTypes.AD_DURATION);
export const playAd = createAction(actionTypes.AD_PLAY);
export const pauseAd = createAction(actionTypes.AD_PAUSE);
export const adBuffering = createAction(actionTypes.AD_BUFFERING);
export const adCanPlay = createAction(actionTypes.AD_CAN_PLAY);
export function setIsSkipButtonEnabled(isSkipButtonEnabled) {
    return { type: actionTypes.SET_AD_SKIP_ENABLED, isSkipButtonEnabled };
}
