import '../css/video.css';
import { bindActionCreators } from 'redux';
import * as utils from './utils';
import { renderUI } from './ui';
import { ApiFieldsArray } from '../types';
import { createStore, waitForStateUpdate } from './store';
import * as actions from './actions';
import * as loadState from './loadState';
import { getProgress, saveProgress } from './save-content-api';
import { findAllVideos, getPlayerConfig } from './video-api';
import { trackView } from './seshat';
import { GlobalStateManager } from './globalStateManager';
import VisibilityObserver, { isElementVisible } from './visibilityObserver';
import TrackingManager from './tracking';
import { addPageAction, getEventDuration, noticeError } from './perf-utils';
import { VideoEvent } from './playback';
import { HlsPlaybackManager } from './playback/hlsJsPlayback';
import * as userAgentInfo from './userAgentInfo';

let playerCount = 0;
const videoHistoryPublication = 'video_history';
const saveProgressInterval = 10; // seconds

var WSJVideo = function (element, settings) {
    playerCount++;
    this._self = this;
    this._container = element;
    this._container.setAttribute('aria-label', 'video player');
    this._container.classList.add('video-player', this._isMobile ? 'mobile-player' : null);
    this._container.tabIndex = '-1';
    this._height = this._container.offsetHeight;
    this._width = this._container.offsetWidth;
    if (!this._container.getAttribute('id')) {
        this._container.setAttribute('id', `video-player-${playerCount}-${Math.random().toString(36).slice(2, 7)}`);
    }
    this._videoId = this._container.getAttribute('id');
    this._video;
    this._wrapper;
    this._controls;
    this._videodata;
    this._volume = 1;

    // TODO: Remove these properties and use userAgentInfo directly
    this._isMobile = userAgentInfo.isMobile();
    Object.defineProperties(this, {
        _isIphone: {
            get: () => userAgentInfo.isIphone()
        },
        _iOS: {
            get: () => userAgentInfo.isIOSDevice()
        },
        _isSafariMac: {
            get: () => userAgentInfo.isSafariMac()
        },
        _isCrawlerBot: {
            get: () => userAgentInfo.isCrawlerBot()
        }
    });

    // Controls //
    this._configLoaded = !('playerid' in settings);

    // Ads //
    this._adContainer;
    this._currentAd;
    this._adId;
    this._adErrorTracked = false;
    this._adErrorEvent = false;

    // Progress state
    this._saveProgressEnabled = utils.isUserLoggedIn() && utils.getSaveApiByDomain() !== null;
    this._lastSaveProgressTimestamp = 0;
    this._initialStartPosition = 0;

    // Tracking
    this._contentStartTracked = false;
    this._trackInViewListenerReference;

    // state
    this._contentInitialized = false;
    this._playRequested = false;

    var defaults = {
        autoplay: false,
        loop: false,
        adsEnabled: true,
        vpaidEnabled: true,
        shareEnabled: true,
        allowPlayerPopup: false,
        chainVideos: false,
        disableHtmlControls: false,
        enableLiveButton: false,
        shareDomain: null,
        useAllesseh: false,
        api2: process.env.VIDEO_API_2 + '/api/',
        saveApiPublication: null,
        clickForSound: false,
        resetOnComplete: true,
        type: '',
        query: '',
        contentType: 'article',
        enableEndScreen: false,
        enableMoreVideosSlide: false,
        disableTitle: false,
        noThumb: false,
        thumbFlashLine: false,
        thumbLayout: '',
        larsId: '91',
        larsAdId: '1259',
        trackInView: false,
        channel: null,
        enableScrubPreview: true,
        allowFullScreen: true,
        adTag: '',
        useHttps: true,
        count: 1,
        moduleId: '',
        adZone: '',
        lnid: '',
        plid: '',
        msrc: null,
        collapseable: false,
        disableDVR: null,
        maxBitrateIndex: null,
        enableCaptions: true,
        loaderThumb: false,
        plcmtOverride: 2,
        adSkipTime: 15,
        playlist: {},
        suggestionsType: 'wsj-section',
        suggestionsQuery: '',
        suggestionsGroupId: '',
        sAccount: false,
        imaLibrary: 'https://imasdk.googleapis.com/js/sdkloader/ima3.js',
        imaIasAdaptor: 'https://static.adsafeprotected.com/vans-adapter-google-ima.js',
        imaIasOptimization: 'https://static.adsafeprotected.com/iasPET.1.js',
        imaIasNetworkId: '931676',
        site: null,
        directLinks: false,
        playlistQuery: '',
        touchCastID: null,
        relativeLinks: false,
        disableChainPlay: false,
        thumb: null,
        callback: null,
        fireTv: false,
        useHLS: true,
        playInline: this._isIphone,
        suppressHeadline: false,
        startLevel: false,
        startPosition: 0,
        hasCMPPermutiveConsent: true,
        disablePictureInPicture: false
    };

    if (this._isIphone && !defaults.playInline) defaults.disableHtmlControls = true;

    // check if server side state exists
    const serverState = this._container.querySelector(`#wrapper-${this._videoId}`)?.dataset.serverState;
    if (typeof serverState === 'string' && serverState.length > 0) {
        try {
            this._serverState = JSON.parse(serverState);
        } catch (e) {
            console.error('Failed to parse server state attribute', e);
        }
    }

    this._settings = { ...defaults, ...this._serverState?.settings, ...settings };
    // TODO: Handle defaults better. undefined or null should not override the default value
    if (typeof this._settings.resetOnComplete === 'undefined' || this._settings.resetOnComplete === null) {
        this._settings.resetOnComplete = defaults.resetOnComplete;
    }
    if (typeof this._settings.larsId === 'undefined' || this._settings.larsId === null) {
        this._settings.larsId = defaults.larsId;
    }

    const startParam = parseInt(utils.getUrlParameter('startPosition'), 10);
    if (!isNaN(startParam) && this._settings.startPosition === 0) {
        this._settings.startPosition = startParam;
    }

    if (typeof this._settings.prerollDelay === 'string' && this._settings.prerollDelay.length > 0) {
        this._settings.prerollDelay = parseInt(this._settings.prerollDelay, 10);
    }
    this._settings.prerollDelay = Math.max(this._settings.prerollDelay, 0);

    if (typeof this._settings.plcmtOverride === 'string' && this._settings.plcmtOverride.length > 0) {
        this._settings.plcmtOverride = parseInt(this._settings.plcmtOverride, 10);
    }

    if (navigator.userAgent.indexOf('amazon-fireos') != -1) {
        // allows testing amazon fire on desktop
        this._settings.useHLS = false;
        this._isMobile = false;
    } else {
        this._settings.fireTv = false;
    }

    if (this._isCrawlerBot) this._settings.autoplay = false; // Crawler causing tracking issues on autoplay
    if (this._settings.clickForSound === true || this._settings.autoplay == 'mobile') {
        // click for sound implies autoplay muted
        this._settings.autoplay = 'muted';
    }

    if (this._settings.enableAutoplayMutedBehavior && this._settings.autoplay !== 'muted') {
        // enableAutoplayMutedBehavior implies autoplay muted
        this._settings.autoplay = 'muted';
    }

    if (this._iOS || this._isSafariMac) {
        this._settings.useHLS = false;
    }

    const apiUrlOverride = utils.getApiUrlOverride();
    if (apiUrlOverride) this._settings.api2 = apiUrlOverride;
};

WSJVideo.prototype = {
    log: function (...args) {
        if (this._store && !this._store.getState().loggingEnabled) return;
        // Make log entries look similar to redux-logger
        const now = new Date();
        const formattedTime = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(
            2,
            '0'
        )}:${String(now.getSeconds()).padStart(2, '0')}.${String(now.getMilliseconds()).padStart(3, '0')}`;
        console.group(
            ` %cevent %c${args[0]} %c@ ${formattedTime}`,
            'color: #ccc; font-weight: lighter;',
            'color: unset; font-weight: unset;',
            'color: gray; font-weight: lighter;'
        );
        args.slice(1).forEach((arg) => console.log(arg));
        console.groupEnd();
    },
    init: async function () {
        if (!this._configLoaded) {
            try {
                const config = await getPlayerConfig(this._settings.playerid);
                this._settings = { ...this._settings, ...config };
            } catch (e) {
                this.log('Failed to fetch player config', e.message);
            } finally {
                this._configLoaded = true;
            }
        }

        // #region Global state manager
        this._globalStateManager = new GlobalStateManager(this._videoId);
        // Listen for changes to the global state. This fires anytime another player on the pages updates its state.
        this._globalStateManager.addEventListener('change', (evt) => {
            if (!this._settings.enableAutoplayMutedBehavior) return;

            const { id, newState, prevState } = evt.detail;
            const { isPlaying, isAdPlaying, isMuted } = this._store.getState();
            const currentVideoPlayingMuted = (isPlaying || isAdPlaying) && isMuted;
            const newVideoPlaying =
                (!prevState.isPlaying && newState.isPlaying) || (!prevState.isAdPlaying && newState.isAdPlaying);
            if (id !== this._videoId && currentVideoPlayingMuted && newVideoPlaying) {
                // pause current video if another video starts playing while this one is playing muted
                this.playPause();
            }
        });

        // Handle closing out the docked player when another player starts playing
        this._globalStateManager.addEventListener('change', (evt) => {
            if (!this._settings.enableStickyPlayer) return;
            const { id, newState, prevState } = evt.detail;
            const { isAdMode, isPlaying, isAdPlaying, isFloating, isMuted } = this._store.getState();
            const currentVideoPlaying = isAdMode ? isAdPlaying : isPlaying;
            const newVideoPlaying = newState.isAdMode
                ? !prevState.isAdPlaying && newState.isAdPlaying
                : !prevState.isPlaying && newState.isPlaying;

            if (id !== this._videoId && newVideoPlaying) {
                let shouldPlayPause = currentVideoPlaying;
                if (this._settings.enableAutoplayMutedBehavior) {
                    // Only toggle play/pause if the current video is not muted otherwise it will conflict with the global state listener above that also handles play/pause
                    shouldPlayPause = currentVideoPlaying && !isMuted;
                }

                if (shouldPlayPause) {
                    this.playPause();
                }

                if (isFloating) {
                    this._actions.setFloating(false);
                }
            }
            // TODO: This shouldn't go here. We don't want this player to track sticky mode changes of other players on the page. Each player should handle this on their own.
            if (newState.isFloating !== prevState.isFloating) this._tracker.trackStickyMode(newState.isFloating);
        });
        //#endregion

        // #region Create the Redux store
        const initialState = this._serverState?.initialState ?? {
            isThumbnailVisible: !this._settings.autoplay,
            id: this._videoId
        };

        if (initialState?.videoData?.suppressAutoplay === true) {
            this._settings.autoplay = false;
            this._settings.enableAutoplayMutedBehavior = false;
            this._settings.prerollDelay = null;
        }

        const containerRect = this._container.getBoundingClientRect();
        this._store = createStore(
            {
                ...initialState,
                autoplay: this._settings.autoplay,
                containerPosition: {
                    left: containerRect.left + window.screenX,
                    top: containerRect.top + window.scrollY
                },
                currentBreakpoint: utils.calculateBreakpoint(this._width),
                height: this._height,
                isClickForSoundVisible: this._settings.clickForSound === true,
                isMuted: this._settings.autoplay === 'muted' || this._settings.clickForSound === true,
                isVisible: isElementVisible(this._container),
                width: this._width
            },
            this._globalStateManager
        );

        this._actions = bindActionCreators(actions, this._store.dispatch);
        // #endregion

        if (typeof __ace !== 'undefined') {
            __ace('djcmp', 'djcmp', ['onReady', this.onLoadDjcmp.bind(this)]);
            __ace('djcmp', 'executeOnCmpReady', [{ cb: this.onDjcmpReadyForAds.bind(this) }]);
        }

        // #region Initialize some state from cookies
        const adPlayNum = utils.getCookie('djadplaynum');
        if (adPlayNum != '') {
            let parsedNum = parseInt(adPlayNum);
            if (Number.isInteger(parsedNum)) {
                this._actions.updateAdPlaysCount(parsedNum);
            }
        }

        var cVolume = utils.getCookie('djvideovol');
        if (cVolume != '') {
            this.setVolume(cVolume, false);
        }

        var cMute = utils.getCookie('djvideomute');
        if (cMute != '') {
            if (cMute == 1) {
                this._actions.mute();
            }
        }

        var cCC = utils.getCookie('djvideocaptions');
        if (cCC == 1 || this._settings.enableAutoplayMutedBehavior) this._actions.showClosedCaptions();
        else this._actions.hideClosedCaptions();
        // #endregion

        // #region Load dependencies
        const depPromises = [];

        // MARK: Init tracking libraries
        this._tracker = new TrackingManager(this._store, this._settings);

        // #region Create playback manager
        this._playbackManager = new HlsPlaybackManager(this._store, this._settings, this._tracker);
        this._playbackManager.addEventListener(VideoEvent.DURATION_CHANGE, this.onDurationChange.bind(this));
        this._playbackManager.addEventListener(VideoEvent.LOADEDMETADATA, this.onLoadedMetadata.bind(this));
        this._playbackManager.addEventListener(VideoEvent.TIMEUPDATE, this.onProgress.bind(this));
        this._playbackManager.addEventListener(VideoEvent.WAITING, this.onBuffering.bind(this));
        this._playbackManager.addEventListener(VideoEvent.PLAYING, this.onPlaying.bind(this));
        this._playbackManager.addEventListener(VideoEvent.PAUSE, this.onPaused.bind(this));
        this._playbackManager.addEventListener(VideoEvent.ENDED, this.onVideoComplete.bind(this));
        this._playbackManager.addEventListener(VideoEvent.SEEKED, this.onVideoSeeked.bind(this));
        this._playbackManager.addEventListener(VideoEvent.PLAY, this.onPlay.bind(this));
        this._playbackManager.addEventListener(VideoEvent.CANPLAY, this.onCanPlay.bind(this));
        this._playbackManager.addEventListener(VideoEvent.CANPLAYTHROUGH, this.onCanPlay.bind(this));
        this._playbackManager.addEventListener(VideoEvent.ENTER_FULLSCREEN, this.onScreenChange.bind(this));
        this._playbackManager.addEventListener(VideoEvent.EXIT_FULLSCREEN, this.onScreenChange.bind(this));
        this._playbackManager.addEventListener(VideoEvent.BITRATE_CHANGE, this.onBitrateChange.bind(this));
        // Ad events
        this._playbackManager.addEventListener(VideoEvent.AD_BUFFERING, this.onAdBuffering.bind(this));
        this._playbackManager.addEventListener(VideoEvent.AD_CAN_PLAY, () => {
            if (this._store.getState().isAdBuffering) {
                this._actions.adCanPlay();
            }
        });
        this._playbackManager.addEventListener(VideoEvent.AD_LOADED, this.onAdLoaded.bind(this));
        this._playbackManager.addEventListener(VideoEvent.AD_STARTED, this.onAdStarted.bind(this));
        this._playbackManager.addEventListener(VideoEvent.AD_PAUSED, this.onAdPaused.bind(this));
        this._playbackManager.addEventListener(VideoEvent.AD_RESUMED, this.onAdResumed.bind(this));
        this._playbackManager.addEventListener(VideoEvent.AD_COMPLETE, this.onAdComplete.bind(this));
        this._playbackManager.addEventListener(VideoEvent.AD_SKIPPED, this.onAdSkipped.bind(this));
        this._playbackManager.addEventListener(VideoEvent.AD_PROGRESS, this.onAdProgress.bind(this));
        this._playbackManager.addEventListener(VideoEvent.AD_CLICK, this.onAdClicked.bind(this));
        this._playbackManager.addEventListener(
            VideoEvent.CONTENT_PAUSE_REQUESTED,
            this.onContentPauseRequested.bind(this)
        );
        this._playbackManager.addEventListener(
            VideoEvent.CONTENT_RESUME_REQUESTED,
            this.onContentResumeRequested.bind(this)
        );
        this._playbackManager.addEventListener(VideoEvent.AD_ERROR, this.onAdError.bind(this));
        this._playbackManager.addEventListener(VideoEvent.AD_MANAGER_LOADED, this.onAdsManagerLoaded.bind(this));
        this._playbackManager.addEventListener(VideoEvent.ALL_ADS_COMPLETED, this.onAdRemoved.bind(this));

        depPromises.push(this._playbackManager.init());

        // Start loading video data and saved progress. Only load progress if video data is passed in from the server.
        // TODO: This probably shouldn't be a condition here. If we don't have a way to fetch a video then we should show an error message
        let loadVideoPromise = Promise.resolve();
        if (this._settings.guid || (this._settings.type !== '' && this._settings.query !== '')) {
            loadVideoPromise = this.loadVideo({ progressOnly: !!this._serverState?.initialState?.videoData }).catch(
                (err) => console.error(err)
            );
        }
        // #endregion

        // #region Visibility manager
        this._visibilityManager = new VisibilityObserver(this._container, this._store);
        this._visibilityManager.addEventListener('visibilitychange', (evt) => {
            const {
                isPlaying: isContentPlaying,
                isAdMode,
                isAdPlaying,
                isInitialized,
                isMuted,
                isThumbnailVisible
            } = this._store.getState();
            if (
                !this._settings.enableAutoplayMutedBehavior ||
                this._globalStateManager.isOtherPlayerPlaying({ checkUnmuted: true }) ||
                !isInitialized ||
                !this._globalStateManager.isTopPriority({ checkPlaying: true })
            )
                return;

            const isPlaying = isAdMode ? isAdPlaying : isContentPlaying;
            if (
                ((!evt.detail.visible && isPlaying) || (evt.detail.visible && !isPlaying)) &&
                isMuted &&
                !isThumbnailVisible
            ) {
                // Play/pause the video when visibility changes while muted
                this.playPause();
            } else if (evt.detail.visible && !isPlaying && isThumbnailVisible) {
                if (this._settings.enableAutoplayMutedBehavior && !this._store.getState().isClickForSoundVisible)
                    this._actions.showClickForSound();
                this._actions.updateHasAutoplayed(true);
                this._actions.setAutoplay('muted');
                this.startVideo({ trigger: 'visibilitychange' });
            }
        });

        // Handle floating mode when visibility changes
        this._visibilityManager.addEventListener('visibilitychange', (evt) => {
            if (!this._settings.enableStickyPlayer) return;

            const isVisible = evt.detail.visible;
            const {
                isAdMode,
                isAdPlaying,
                isFloatingBlocked,
                isMuted,
                isPlaybackInitializing,
                isPlaying: isContentPlaying
            } = this._store.getState();
            if (isFloatingBlocked) return;

            const isPlaying = (isAdMode ? isAdPlaying : isContentPlaying) || isPlaybackInitializing;
            if (!isVisible && isPlaying && !isMuted) {
                this._actions.setFloating(true);
            } else if (isVisible) {
                this._actions.setFloating(false);
            }
        });
        // #endregion

        if (typeof this._settings.callback == 'function') {
            this._settings.callback();
        }

        window.onbeforeunload = this.onPageUnload.bind(this);

        //#region Setup props
        const props = utils.propsFromSettings(this._settings);
        props.useHydrate = typeof this._serverState !== 'undefined';
        props.videoId = this._videoId;

        props.thumbProps.layout = this._width < this._height ? 'vertical' : this._settings.layout;

        props.thumbProps.onClick = (progress) => {
            if (typeof progress === 'number' && this._store.getState().startPosition === 0) {
                this._actions.updateFormatOverride('user initiated thumbnail preview');
                this._actions.updateStartPosition(progress);
            }

            const { adInitState, autoplay } = this._store.getState();
            if (autoplay && adInitState !== loadState.NONE) {
                // Ad was initially requested for autoplay but is actually click to play. We need to request the ad again so the autoplay related VAST params are accurate.
                this._actions.setAdInitState(loadState.NONE);
                this._actions.setAutoplay(false);
            }

            this._actions.updateHasAutoplayed(false);
            this.startVideo();
            this.muteUnMute(false, false, false);
        };

        props.thumbProps.onHoverChange = (show, progress) => {
            this._tracker.trackThumbnailHover(show, progress);
        };

        props.countdownProps = {
            videoApiUrl: this._settings.api2,
            onCountdownComplete: () => {
                this.onLiveStateChanged('live');
                this.handleLiveUpdates();
                this.startVideo();
            }
        };

        if (this._settings.enableMoreVideosSlide) {
            props.moreVideosSlideProps = {
                onSlideShowing: this.trackMoreVideosSlide.bind(this),
                playVideo: (guid) => {
                    this._actions.updateFormatOverride('user initiated recommendation');
                    this._settings.guid = guid;
                    this.loadVideo().then(() => this.startVideo());
                    // TODO: Communicate with the page the new video that is playing
                }
            };
        }

        props.clickForSoundProps = {
            onClicked: this.clickForSoundHandler.bind(this),
            isMobile: this._isMobile
        };

        props.subscribeScreenProps = {
            onSubscribeClicked: this.onSubscribeClicked.bind(this),
            onSubscribeShown: this.onSubscribeShown.bind(this)
        };

        if (props.endscreenProps) {
            props.endscreenProps.videoApiUrl = this._settings.api2;
            props.endscreenProps.fields = ApiFieldsArray.join(',');
            props.endscreenProps.playVideo = this.playSuggestion.bind(this);
            props.endscreenProps.onEndsceenShown = this.trackEndscreenSlide.bind(this);
        }

        if (props.controlsProps) {
            props.controlsProps = {
                ...props.controlsProps,
                isMobile: this._isMobile,
                onPlayPause: this.playPause.bind(this),
                onMuteUnMute: this.muteUnMute.bind(this, null, true, true),
                onSetVolume: this.onSetVolume.bind(this),
                onVolumeControlVisible: this.onVolumeControlVisible.bind(this),
                onToggleFullScreen: this.toggleFullScreen.bind(this),
                onSeek: this.onSeek.bind(this),
                onShare: this.trackShare.bind(this),
                showControls: this.showControls.bind(this, 3000),
                onSaveChange: this.onSaveChange.bind(this),
                onClosedCaptionsClicked: this.onClosedCaptionsClicked.bind(this)
            };
        }
        //#endregion

        // MARK: Render player
        renderUI(this._container, this._store, props);

        Object.defineProperties(this, {
            _videodata: {
                get: () => this._store.getState().videoData
            },
            // Preact may recreate DOM elements so we need to make sure these references never get stale
            _video: {
                get: () => {
                    const el = document.getElementById('videoplayer-' + this._videoId);
                    if (el && !el.scope) el.scope = this;
                    return el;
                }
            },
            _videoInner: {
                get: () => document.querySelector(`#wrapper-${this._videoId} .video-inner`)
            },
            _wrapper: {
                get: () => {
                    const el = document.getElementById('wrapper-' + this._videoId);
                    if (el && !el.scope) el.scope = this;
                    return el;
                }
            },
            _adContainer: {
                get: () => document.getElementById('adContainer-' + this._videoId)
            }
        });

        // After the player is rendered we need to pass in the DOM elements to the playback manager.
        this._playbackManager.attachElements({
            adContainer: this._adContainer,
            container: this._wrapper,
            video: this._video
        });

        this._resizeObserver = new ResizeObserver(this.onResize.bind(this));
        this._resizeObserver.observe(this._videoInner);

        if (!this._settings.disableHtmlControls) {
            Object.defineProperties(this, {
                _controls: {
                    get: () => {
                        const el = document.getElementById('video-controls-container-' + this._videoId);
                        if (el && !el.hideTime) el.hideTime = 0;
                        return el;
                    }
                },
                _volumeSlider: {
                    get: () => document.getElementById('video-volume-slider-' + this._videoId)
                }
            });

            this.addUserEventListeners();
        } else {
            document.getElementById('video-controls-container-' + this._videoId).style.display = 'none';
        }

        this._height = this._videoInner.offsetHeight;
        this._width = this._videoInner.offsetWidth;
        this.onResize();

        // #region Setup external methods
        this._container._self = this;
        this._container.resumeVideo = this.eResumeVideo.bind(this);
        this._container.pauseVideo = this.ePauseVideo;
        this._container.playVideo = this.ePlayVideo.bind(this);
        this._container.playPauseVideo = this.ePlayPauseVideo;
        this._container.killVideo = this.eKillVideo;
        this._container.loadVideo = this.eLoadVideo;
        this._container.setMute = this.eMute;
        this._container.updatePlaylist = this.eUpdatePlaylist;
        this._container.isPlaying = this.eIsPlaying;
        this._container.getStatus = this.eGetStatus;
        this._container.vidoraShownList = () => null; // Noop since Vidora is no longer supported
        this._container.seek = this.eSeek.bind(this);
        this._container.setStickyMode = this.setStickyMode.bind(this);
        //#endregion

        this._trackInViewListenerReference = this.trackInView.bind(this);
        window.addEventListener('scroll', this._trackInViewListenerReference);

        // We need video data before we can move forward.
        await loadVideoPromise;

        const isPremiumPaywall = !utils.checkSubscriberStatusForPremiumVideo(
            this._store.getState().videoData.isSubscriberOnly ||
                this._store.getState().videoData.doctypeID === '227' ||
                this._store.getState().videoData.doctypeID === '30133'
        );

        if (isPremiumPaywall) {
            this._actions.showThumbnail();
            this._settings.enableAutoplayMutedBehavior = false;
        }

        this.setupTracking();

        if (this._videodata.doctypeID == '469') this._settings.chainVideos = false;

        // Subscribe to updates for the live state
        if (this._videodata.state === 'future' || this._videodata.state === 'live') {
            // Lazy load LiveEventController stuff since the AppSync lib is pretty large
            this.createMark('liveEventControllerLoad:start');
            import('./liveEventController')
                .then((module) => {
                    const LiveEventController = module.default;
                    this._liveStateController = new LiveEventController();

                    // countdown is already finished
                    if (this._videodata.state === 'live' && !this._store.getState().isCountdownVisible) {
                        this.handleLiveUpdates();
                    }
                })
                .catch((err) => console.error(err))
                .finally(() => this.createMark('liveEventControllerLoad:end'));
        }

        if (this._videodata.suppressAutoplay === true) {
            this._settings.enableAutoplayMutedBehavior = false;
            this._settings.prerollDelay = null;
            this._actions.setAutoplay(false);
        }

        await Promise.all(depPromises);

        const { autoplay, isAdsBlocked, isVisible } = this._store.getState();

        let shouldAutoplay = autoplay !== false && !isPremiumPaywall;
        if (this._settings.enableAutoplayMutedBehavior && shouldAutoplay) {
            // If the player is visible and no other player on the page is playing unmuted then we should autoplay
            const isOtherPlayingUnmuted = this._globalStateManager.isOtherPlayerPlaying({ checkUnmuted: true });
            const isTopPriority = this._globalStateManager.isTopPriority();
            shouldAutoplay = isVisible && !isOtherPlayingUnmuted && isTopPriority;
            this.log(
                `enableAutoplayMutedBehavior: shouldAutoplay=${shouldAutoplay}, isVisible=${isVisible}, isOtherPlayingUnmuted=${isOtherPlayingUnmuted}, isTopPriority=${isTopPriority}, id=${this._videoId}`
            );
        }

        // Make sure click for sound button is visible when autoplaying muted
        // TODO: Move to player specific UI component
        if (this._store.getState().autoplay === 'muted' && !this._store.getState().isClickForSoundVisible) {
            this._actions.showClickForSound();
        }

        if (
            this._settings.adsEnabled &&
            !isAdsBlocked &&
            typeof __ace !== 'undefined' &&
            window?.djcmp?.tcData?.eventStatus &&
            djcmp?.tcData?.eventStatus === 'cmpuishown'
        ) {
            // cmp not set so wait for it
            this._actions.updateDjcmpLoadState(loadState.STARTED);
        }

        this.trigger(this._wrapper, 'onInitialize', this._videodata);
        this._actions.initializePlayer();
        this.createMark('wsj-video-playerReady');
        addPageAction('video-player-ready', {
            'video-api-request-duration': getEventDuration('videoApiRequest'),
            'video-saved-progress-request-duration': getEventDuration('savedProgressApiRequest'),
            'video-ima-sdk-load-duration': getEventDuration('imaLoad'),
            'video-ias-adaptor-script-load-duration': getEventDuration('imaIasAdaptorLoad'),
            'video-ias-optimization-script-load-duration': getEventDuration('imaIasOptimizationLoad'),
            'video-hls-js-load-duration': getEventDuration('hlsJsLoad'),
            'video-live-event-controller-load-duration': getEventDuration('liveEventControllerLoad'),
            'video-preact-render-duration': getEventDuration('videoRender'),
            'video-player-ready-duration': getEventDuration('video-playerReady', 'video-init', 'wsj-video-playerReady')
        });

        if (shouldAutoplay) {
            this.startVideo();
        } else {
            if (this._videodata.state !== 'future') {
                this.setupThumbnail();
            } else if (this._store.getState().isThumbnailVisible) {
                // future video so hide thumbnail if visible
                this._actions.hideThumbnail();
            }
        }
    },

    onLoadDjcmp: function (event) {
        try {
            const permutiveConsent = __ace('djcmp', 'customVendorIsEnabled', ['5eff0d77969bfa03746427eb']);
            if (typeof permutiveConsent === 'boolean') {
                this._settings.hasCMPPermutiveConsent = permutiveConsent;
            }
        } catch (e) {
            this.log('Error setting cmp in onLoadDjcmp');
        }
    },

    onDjcmpReadyForAds: function (event) {
        try {
            this.log('DJCMP: Djcmp Ready');
            if (djcmp?.tcData?.eventStatus === 'useractioncomplete')
                this._actions.updateDjcmpLoadState(loadState.FINISHED);
        } catch (e) {
            this.log('Error getting djcmp event status');
        }
    },

    setupTracking: function () {
        this.trackInView();
    },

    setStickyMode: function (isSticky) {
        this._actions.setFloating(true);
    },

    // MARK: startVideo
    startVideo: async function (params = {}) {
        const { signal, trigger } = params;
        const { hasChained, autoplay, videoData, isClosedCaptionsVisible } = this._store.getState();

        if (this._videodata.state == 'future')
            // prevent any start attempt from occuring if the live event has not started
            return;

        if (signal?.aborted) {
            this.log('Playback aborted');
            if (this._store.getState().isPlaybackInitializing) this._actions.endVideoPlaybackInit();
            return;
        }

        if (
            !utils.checkSubscriberStatusForPremiumVideo(
                videoData.isSubscriberOnly || videoData.doctypeID === '227' || videoData.doctypeID === '30133'
            )
        ) {
            this._actions.showSubscribeScreen();
            this._actions.showThumbnail();
            return; // prevent playback behind paywall
        } else {
            this._actions.hideSubscribeScreen();
        }

        this._playRequested = true;

        this.createMark('videoStartup:start');
        addPageAction('video-start', {
            'video-guid': videoData.guid,
            'video-autoplay': autoplay,
            'video-has-chained': hasChained,
            'video-ads-disabled-from-bce': !videoData.adsAllowed
        });

        this.onNewVideo();
        this._actions.hideThumbnail();
        this._actions.startVideoPlaybackInit();

        this.showHideClosedCaptions(isClosedCaptionsVisible, false);

        try {
            this._playbackManager.addEventListener(
                VideoEvent.READY,
                (evt) => {
                    this.createMark('videoStartup:end');
                    const { autoplay, hasChained } = this._store.getState();
                    const attributes = {
                        'video-startup-duration': getEventDuration('videoStartup'),
                        'video-metadata-load-duration': getEventDuration('videoMetadataLoad'),
                        'video-ad-wait-duration': getEventDuration('videoAdWait'),
                        'video-autoplay': autoplay,
                        'video-should-play-ad': evt.detail.shouldPlayAd,
                        'video-has-chained': hasChained
                    };

                    if (!hasChained && trigger !== 'visibilitychange') {
                        // Don't record when video is started from chaining or visibility change. In those cases, there can be a large gap between wsj-video-init and video start
                        attributes['video-total-playback-setup-duration'] = getEventDuration(
                            'videoTotalPlaybackSetup',
                            'video-init',
                            'videoStartup:end'
                        );
                    }

                    addPageAction('video-playback-init', attributes);
                },
                { once: true }
            );
            await this._playbackManager.loadContent(videoData, signal);
            const { autoplay, hasAutoplayed, isPlaybackInitializing } = this._store.getState();
            if (autoplay && hasAutoplayed === null) this._actions.updateHasAutoplayed(true);

            this._actions.setFocus('play-button');
            if (isPlaybackInitializing) {
                this._actions.endVideoPlaybackInit();
            }
        } catch (e) {
            this.onFailedPlayback(e);
        }
    },

    onFailedPlayback: function (error) {
        const { guid } = this._store.getState();
        this._actions.setAdError(false);
        this._actions.endVideoPlaybackInit();
        this.createMark('videoStartup:end');
        noticeError(error, {
            'video-guid': guid,
            'video-error-type': 'startVideo'
        });

        this.onNonFatalVideoError('Failed to autostart', error);
        this.setupThumbnail();
    },

    onLiveStateChanged: function (newState) {
        const { videoData } = this._store.getState();
        videoData.state = newState;
        this._actions.updateVideoData(videoData);
        this.trigger(this._wrapper, 'onLiveStateChanged', videoData);
    },

    loadVideo: function ({ progressOnly, signal } = { progressOnly: false }) {
        this.log('loadVideo');
        if (signal?.aborted) {
            this.log('Playback aborted');
            return;
        }

        this._playbackManager.pause();

        // reset ad loading state when loading a new video
        const { adInitState } = this._store.getState();
        if (adInitState !== loadState.NONE) {
            this._actions.setAdInitState(loadState.NONE);
        }

        let videoLoadPromise = Promise.resolve();
        if (!progressOnly) {
            this.createMark('videoApiRequest:start');
            this._actions.setVideoApiLoadState(loadState.STARTED);
            const params = {
                fb: this._settings.fallback,
                stage: this._settings.videoApiEnv,
                signal
            };
            if (this._settings.guid) {
                (params.type = 'guid'), (params.query = this._settings.guid);
            } else if (this._settings.type !== '' && this._settings.query !== '') {
                params.type = this._settings.type;
                params.query = this._settings.query;
                if (this._settings.groupId !== '') {
                    params.groupid = this._settings.groupId;
                }
            }

            videoLoadPromise = findAllVideos(params)
                .then((videoData) => {
                    const item = videoData[0] ?? {};
                    if (item.error) {
                        throw new Error(item.error);
                    }

                    this._container.setAttribute('aria-label', item.name);

                    if (this._settings.msrc) {
                        item.msrc = this._settings.msrc;
                    }

                    this._actions.updateVideoData(item);
                    if (item.format == 'vr') {
                        this.displayVrMsg().bind(this);
                        throw new Error('VR not supported');
                    }
                    this._actions.setVideoApiLoadState(loadState.FINISHED);
                })
                .catch((err) => {
                    if (err.name === 'AbortError') {
                        this.log('Playback aborted');
                        // Ignore abort errors
                        return;
                    }
                    this.log(`Failed to fetch video data: ${err.message}`);
                    noticeError(err, { 'video-error-type': 'loadVideo' });
                    this.onFatalVideoError(err.message);
                    this._actions.setVideoApiLoadState(loadState.FAILED);
                })
                .finally(() => this.createMark('videoApiRequest:end'));
        }

        let loadProgressPromise = Promise.resolve();
        if (this._saveProgressEnabled) {
            this.createMark('savedProgressApiRequest:start');
            this.log('Fetching current progress');
            loadProgressPromise = getProgress(this._settings.guid, videoHistoryPublication, { signal })
                .then(({ progress }) => {
                    this.log('Current progress = ', progress);
                    this._actions.updateStartPosition(progress);
                    this._actions.updateLastSavedProgress(progress);
                })
                .catch((err) => {
                    if (err.name === 'AbortError') {
                        this.log('Playback aborted');
                        // Ignore abort errors
                        return;
                    }
                    noticeError(err, { 'video-error-type': 'loadSaveProgress' });
                    this.log('Failed to load progress', err);
                })
                .finally(() => this.createMark('savedProgressApiRequest:end'));
        }

        return Promise.all([videoLoadPromise, loadProgressPromise]);
    },

    setupThumbnail: function (e) {
        if (!this._store.getState().isThumbnailVisible) {
            // unhide the thumbnail
            this._actions.showThumbnail();
        }

        this.onResize();
    },

    showHideClosedCaptions: function (show, setCookie) {
        if (show) {
            this._actions.showClosedCaptions();
            if (setCookie) utils.setCookie('djvideocaptions', '1');
            this._playbackManager.showHideClosedCaptions(true);
        } else {
            this._actions.hideClosedCaptions();
            if (setCookie) utils.setCookie('djvideocaptions', '0');
            this._playbackManager.showHideClosedCaptions(false);
        }
    },

    onAdsManagerLoaded: function (evt) {
        const { adContainer, adsManager } = evt.detail;
        this._tracker.trackAdsManagerLoaded(adsManager, adContainer);
    },

    onBuffering: function (e) {
        if (!this._store.getState().isBuffering) {
            this._actions.buffering();
        }

        if (e.type == 'stalled' || e.type === 'waiting') {
            this._tracker.trackBufferStart();
        }
    },

    onAdBuffering: function () {
        this._actions.adBuffering();
        this._tracker.trackBufferStart();
    },

    /**
     * @param {number} progress
     * @returns {Promise<void>}
     */
    async saveProgress(progress) {
        this._actions.updateLastSavedProgress(progress);

        const { guid, state, isMuted } = this._store.getState().videoData;
        if (isMuted) return; // don't save progress if video is muted

        const saveTimeThreshold = 5000; // the amount of time that must elapse before saving progress again in milliseconds
        if (
            this._saveProgressEnabled &&
            state === 'vod' && // Don't save progress of live videos
            Date.now() - this._lastSaveProgressTimestamp > saveTimeThreshold // in case this method is called too frequently
        ) {
            try {
                this.log('Saving progress', progress);
                await saveProgress(guid, videoHistoryPublication, progress);
            } catch (e) {
                noticeError(e, { 'video-error-type': 'saveProgress' });
                this.log('Failed to save progress', e);
            }
            this._lastSaveProgressTimestamp = Date.now();
        }
    },

    onProgress: function (e) {
        if (!this._playbackManager || this._playbackManager.currentTime === 0) return;

        const { isBuffering, isAdMode } = this._store.getState();

        if (isBuffering) {
            this._actions.canPlay();
            this._tracker.trackBufferEnd();
        }

        if (!isAdMode) {
            if (isBuffering && this._playbackManager.readyState === 4) {
                // still in buffering state while video is progressing. It seems IE11 doesn't emit canplay when buffer is healthy
                this._actions.canPlay();
            }

            this._actions.updateProgress(this._playbackManager.currentTime);
            const { currentPosition, duration, isMuted, lastSavedProgress } = this._store.getState();
            var percProg = (currentPosition / duration) * 100;
            this.trackProgress(percProg, currentPosition);

            // save progress on an interval when unmuted before or after the interval amount
            if (Math.abs(lastSavedProgress - currentPosition) > saveProgressInterval && !isMuted) {
                if (currentPosition >= duration - 30) {
                    if (lastSavedProgress > 0) {
                        // reset progress if video is at the end only once
                        this.saveProgress(0);
                    }
                } else {
                    this.saveProgress(currentPosition);
                }
            }

            if ((this._videodata.state == 'live' || this._videodata.state == 'flive') && this._controls) {
                return;
            }
        }
    },

    onLoadedMetadata: function (evt) {
        this.onDurationChange(evt);
        this._actions.updateBitrate(this._playbackManager.bitrate);
    },

    onDurationChange: function (evt) {
        if (
            this._playbackManager.duration > 0 &&
            this._playbackManager.duration !== this._store.getState().duration &&
            !this._store.getState().isAdMode
        ) {
            this._actions.updateDuration(this._playbackManager.duration);
        }
    },

    onNewVideo: function () {
        this._completeCalled = false;
        this._contentStartTracked = false;
        this._contentInitialized = true;
        this._adErrorTracked = false;
        this._retryLoadAttempts = 0;

        this.trigger(this._wrapper, 'newVideo', this._videodata);
        this.trigger(this._wrapper, 'onNewVideo', this._videodata);
        this.trackInit();

        this._settings.thumb = false;
        if (this._controls) {
            this._controls.hideTime = 0;
        }
    },

    videoClickEvent: function (e) {
        this.playPause(e);
    },

    checkSpaceInteraction: function (evt) {
        return evt.target.dataset.space !== 'false';
    },

    addUserEventListeners: function () {
        var self = this;
        self._container.onkeydown = function (evt) {
            evt = evt || window.event;
            switch (evt.key) {
                case 'Escape':
                    this.onEscapeKey();
                    break;
                case 'ArrowLeft':
                    this.onLeftKey(evt);
                    break;
                case 'ArrowRight':
                    this.onRightKey(evt);
                    break;
                case ' ':
                    if (this.checkSpaceInteraction(evt)) {
                        evt.preventDefault();
                        this.playPause();
                    }
                    break;
                case 'ArrowUp':
                    evt.preventDefault();
                    self.setVolume(self._volume + 0.1, true);
                    break;
                case 'ArrowDown':
                    evt.preventDefault();
                    self.setVolume(self._volume - 0.1, true);
                    break;
            }
        }.bind(this);
        if (this._controls) {
            if (this._isMobile) {
                this._playbackManager.addEventListener(VideoEvent.VIDEO_CLICK, this.mobileClick.bind(this));
            } else {
                this._playbackManager.addEventListener(VideoEvent.VIDEO_CLICK, this.videoClickEvent.bind(this));
                this._playbackManager.addEventListener(VideoEvent.VIDEO_DBLCLICK, this.toggleFullScreen.bind(this));
            }
        }
        document.addEventListener('mousedown', function (e) {
            self._container.classList.remove('show-focus');
        });
        document.addEventListener('keydown', function (e) {
            self._container.classList.add('show-focus');
        });
        this._container.addEventListener(
            'keyup',
            function (e) {
                this.showControls(4000);
            }.bind(this)
        );
        this._container.addEventListener('onMuteUnMute', function (e) {
            self.onMuteUnMute(self);
        });

        // TODO: Move this to the specific UI component
        if (!this._isMobile) {
            this._wrapper.addEventListener('mouseleave', this.onRollOut);
            this._wrapper.addEventListener('mousemove', this.onMouseMove);
        }
    },

    mobileClick: function (e) {
        if (this._controls) {
            const { isControlsVisible } = this._store.getState();
            if (isControlsVisible) {
                this.playPause();
                this._actions.hideControls();
            } else {
                this.showControls(3000);
            }
        }
    },

    onSetVolume: function (v) {
        this.setVolume(v, true);
    },

    onVolumeControlVisible: function (isVisible) {
        this._actions.setVolumeControlVisibility(isVisible);
    },

    onEscapeKey: function () {
        if (this._store.getState().isSharescreenVisible) {
            this._actions.hideShareScreen();
            this._actions.setFocus('share-btn');
        }
    },

    onLeftKey: function (evt) {
        var time = 0;
        if (evt.shiftKey) {
            if (this._playbackManager.currentTime - 15 <= 0) time = 0;
            else time = this._playbackManager.currentTime - 15;
        } else {
            if (this._playbackManager.currentTime - 5 <= 0) time = 0;
            else time = this._playbackManager.currentTime - 15;
        }
        this.eSeek(time);
    },

    onRightKey: function (evt) {
        var time = 0;
        if (evt.shiftKey) {
            if (this._playbackManager.currentTime + 15 >= this._playbackManager.duration)
                time = this._playbackManager.duration - 5;
            else time = this._playbackManager.currentTime + 15;
        } else {
            if (this._playbackManager.currentTime + 5 <= this._playbackManager.duration)
                time = this._playbackManager.currentTime + 5;
            else time = this._playbackManager.duration;
        }
        this.eSeek(time);
    },

    onSpaceBarKey: function (evt) {
        this.playPause();
        evt.preventDefault();
    },

    onPlay() {
        if (!this._store.getState().isPlaying) {
            this._actions.playVideo();
        }
    },

    onCanPlay() {
        if (this._store.getState().isBuffering) {
            this._actions.canPlay();
        }
    },

    showControls: function (delay = 0) {
        clearTimeout(this._hideControlsTimer);
        const { isControlsVisible, isCountdownVisible } = this._store.getState();
        if (!isControlsVisible && !isCountdownVisible) {
            this._actions.showControls();
        }

        const hideControls = () => {
            const { isPlaying, isAdMode } = this._store.getState();
            if (!isPlaying || (isAdMode && this._isMobile)) {
                // don't hide controls when paused
                return;
            }
            this._actions.hideControls();
            if (this._wrapper) this._wrapper.classList.add('nocursor');
        };

        if (delay === 0) {
            hideControls();
            return;
        }

        this._hideControlsTimer = setTimeout(hideControls, delay);
    },

    onMouseMove: function (evt) {
        this.scope.showControls(3000);
        if (this.scope._wrapper) this.scope._wrapper.classList.remove('nocursor');
    },

    onRollOut: function (evt) {
        clearTimeout(this.scope._hideControlsTimer);
        const { isPlaying, isAdMode, isAdPlaying } = this.scope._store.getState();
        if (isPlaying || (isAdMode && isAdPlaying)) {
            this.scope._actions.hideControls();
        }
    },

    onVideoSeeked: function (evt) {
        this._tracker.trackSeekEnd();
    },

    onVideoComplete: function (evt) {
        this._tracker.trackVideoComplete();

        if (!this._store.getState().isAdMode) {
            // reset saved progress to 0 when the video ends
            this.saveProgress(0);
            this._actions.updateStartPosition(0);

            if (this._settings.chainVideos) {
                this._playbackManager.pause();
                if (this._iOS) {
                    this._playbackManager.setFullscreen(false);
                }
                this.chainVideo();
            } else if (this._settings.resetOnComplete) {
                this.setupThumbnail();
                if (this._iOS) {
                    this._playbackManager.setFullscreen(false);
                }
                this._contentInitialized = false;
            } else {
                this._playbackManager.seek(0);
                this._playbackManager.pause();
            }
        }
        this.trigger(this._wrapper, 'videoComplete', {
            guid: this._videodata.guid
        });
        this.trigger(this._wrapper, 'onVideoComplete', {
            guid: this._videodata.guid
        });
    },

    chainVideo: function () {
        if (!this._store.getState().hasChained) {
            this._actions.updateHasChained(true);
        }
        const { playlist } = this._store.getState();
        if (playlist.length > 0) {
            const videoData = playlist[0];
            this._actions.updateVideoData(videoData);
            this._container.setAttribute('aria-label', videoData.name);
            this._settings.guid = videoData.guid;
            if (!this._settings.disableChainPlay) {
                this.loadVideo()
                    .then(() => {
                        this.startVideo();
                        this._actions.dequeuePlaylist();
                    })
                    .catch(console.error);
            } else {
                this.trigger(this._wrapper, 'onSuggestionPlay', videoData);
            }
        } else {
            utils
                .getNewPlaylist(
                    this._settings.api2,
                    this._settings,
                    this._store.getState().videoData,
                    ApiFieldsArray.join(',')
                )
                .then((playlist) => {
                    if (playlist && playlist.length > 1) {
                        this._actions.replacePlaylist(playlist);
                        this.chainVideo();
                    }
                })
                .catch(console.error);
        }
    },

    onPlaying: function (e) {
        // make sure duration is correct here too
        // durationchange and loadedmetadata are flaky on iOS
        // iOS uses the video tag for ads so the duration will change to the duration of an ad
        const { videoData, isAdMode, isPlaying, duration } = this._store.getState();
        if (videoData.state !== 'live' && !isAdMode && duration !== this._playbackManager.duration) {
            this._actions.updateDuration(this._playbackManager.duration);
        }

        if (this._store.getState().isBuffering) {
            this._actions.canPlay();
        }

        if (!isPlaying) {
            this._actions.playVideo();
        }

        if (!this._playRequested) {
            // prevents video playing if pause requested during buffering
            this._playbackManager.pause();
            this._playRequested = true;
            return;
        }

        if (this._controls && this._isMobile) this.showControls(3000);

        if (this._contentStartTracked) {
            this._tracker.trackPlay();
        }

        try {
            this.trigger(this._wrapper, 'onPlayerStateChange', {
                isPlaying: this.eIsPlaying(),
                isFullscreen: this._playbackManager.fullscreen
            });
        } catch (e) {
            this.log(e);
        }
    },

    onBitrateChange: function (e) {
        try {
            if (e.detail) {
                this._actions.updateBitrate(e.detail);
                this._tracker.trackBitrateChange();
            }
        } catch (e) {
            this.log(e);
        }
    },

    onPageUnload: function () {
        const { currentPosition, isMuted } = this._store.getState();
        if (this._lastSaveProgressTimestamp > 0 && currentPosition > 0 && !isMuted) {
            if (currentPosition >= duration - 30) this.saveProgress(0);
            else this.saveProgress(currentPosition);
        }
    },

    onPaused: function () {
        if (this._store.getState().isPlaying) {
            this._actions.pauseVideo();
        }
        if (this._controls && this._isMobile) this._actions.showControls();

        this._tracker.trackPause();

        try {
            this.trigger(this._wrapper, 'onPlayerStateChange', {
                isPlaying: this.eIsPlaying(),
                isFullscreen: this._playbackManager.fullscreen
            });
        } catch (e) {
            this.log(e);
        }
    },

    onSeek: function (perc) {
        var sec = Math.round(this._store.getState().duration * perc);

        if (sec > this._store.getState().duration - 10) sec = this._store.getState().duration - 5;

        this._tracker.trackSeekStart();
        this._actions.updateProgress(sec);
        // update last save time so we don't try to save progress if you seek ahead of the interval
        this._actions.updateLastSavedProgress(sec);
        this._playbackManager.seek(sec);
    },

    onNonFatalVideoError: function (msg, err) {
        if (typeof msg !== 'string') msg = 'Nonfatal video error';

        console.warn('non fatal video error', msg, err);
    },

    onFatalVideoError: function (e) {
        this.displayErrorScreen('An error occurred.  Please try again later.');

        this._retryLoadAttempts = 0;

        if (typeof e === 'string') this._tracker.trackError('Content_' + e);
        else this._tracker.trackError('Content_' + this._videodata.guid);

        this._tracker.trackSessionEnd();
    },

    displayErrorScreen(msg) {
        this._container.innerHTML = `<div class="videoErrorMsg"><div>${msg}</div></div>`;
    },

    onResize: function () {
        if (!this._wrapper)
            // Error screen showing
            return;

        // update sizes for important elements
        this._height = this._videoInner.offsetHeight;
        this._width = this._videoInner.offsetWidth;

        const { width: oldWidth, height: oldHeight } = this._store.getState();

        if (this._width === oldWidth && this._height === oldHeight) {
            // width and height didn't actually change
            return;
        }

        this._actions.resize(this._width, this._height);
    },

    onScreenChange: function (e) {
        const isFullScreen = this._playbackManager.fullscreen;
        const { isFullScreen: fullScreenState } = this._store.getState();
        if (isFullScreen) {
            if (!fullScreenState) {
                this._actions.enterFullScreen();
            }
        } else {
            if (fullScreenState) {
                this._actions.exitFullScreen();
            }

            // make sure muted state is in sync when exiting full screen
            if (this._store.getState().isMuted !== this._playbackManager.muted) {
                if (this._playbackManager.muted) {
                    this._actions.mute();
                } else {
                    this._actions.unmute();
                }
            }
        }

        this.onResize();
        try {
            this.trigger(this._wrapper, 'onPlayerStateChange', {
                isPlaying: this.eIsPlaying(),
                isFullscreen: isFullScreen
            });
        } catch (e) {
            this.log(e);
        }
    },

    onSubscribeClicked: function () {
        // fallback subscribe screen tracker
        this._tracker.trackSubscribeClick();
    },

    onSubscribeShown: function () {
        this._tracker.trackSubscribeShown();
    },

    onSaveChange: function (isSaved) {
        this._tracker.trackSaveChange(isSaved);
    },

    onClosedCaptionsClicked: function (isEnabled) {
        const { isClosedCaptionsVisible } = this._store.getState();
        if (isClosedCaptionsVisible) {
            this.showHideClosedCaptions(false, true);
        } else {
            this.showHideClosedCaptions(true, true);
        }
    },

    muteUnMute: function (mute, setCookie, trackEvent, clickEvent) {
        var muteVal = mute == null ? !this._store.getState().isMuted : mute;

        if (muteVal) {
            this._actions.mute();
            this._playbackManager.setMute(true);
            if (setCookie) utils.setCookie('djvideomute', '1');
        } else {
            this._actions.unmute();
            this._playbackManager.setMute(false);
            if (setCookie) utils.setCookie('djvideomute', '0');
        }
        const { isMuted } = this._store.getState();
        if (this._videodata && trackEvent) {
            this._tracker.trackMuteUnMute(isMuted);
        }
        this.trigger(this._wrapper, 'onMuteUnMute', { isMuted: this._store.getState().isMuted });
    },

    onMuteUnMute: function (scope) {
        this._actions.hideClickForSound();
    },

    setVolume: function (v, userSet) {
        try {
            v = Math.min(Math.max(parseFloat(v), 0), 1);
            this._volume = v;
            this._playbackManager.setVolume(v);
            this._actions.setVolume(v);
            if (userSet) {
                this._volume = v;
                utils.setCookie('djvideovol', v);
                if (this._store.getState().isMuted) this.muteUnMute(false, true, true);
            }
        } catch (e) {
            this.log(e);
        }
    },

    playSuggestion: function (e) {
        this._actions.updateFormatOverride('user initiated next');
        if (!this._settings.disableChainPlay) {
            this.ePlayVideo(e);
        } else {
            this.trigger(this._wrapper, 'onSuggestionPlay', e);
        }
    },

    clickForSoundHandler: function (event) {
        this.muteUnMute(false, true, true, event);
        this.setVolume(this._volume, false);
        if (this._controls && !this._store.getState().isStickyModeEnabled) this._controls.style.display = 'block';
        this._settings.clickForSound = false;
        this._actions.unmute();

        // return cc false if it was turned off by the user
        var cCC = utils.getCookie('djvideocaptions');
        if (cCC == 0) {
            this.showHideClosedCaptions(false, false);
        }
    },

    onAdClicked: function (e, self) {
        this._tracker.trackAdClick(e.detail.imaAd);
        this._playbackManager.pause();
    },

    onAdStarted: function (e) {
        const currentAd = e.detail.imaAd;
        if (this._store.getState().isPlaybackInitializing) {
            this._actions.endVideoPlaybackInit();
        }

        this.onResize();
        this.trigger(this._wrapper, 'adStarted');

        this._actions.playAd();

        this._playbackManager.showHideClosedCaptions(false, false);

        if (currentAd.isLinear()) {
            if (this._controls) {
                if (this._isMobile) {
                    // TODO: Move this to the playback manager when ads are implemented
                    this.showControls(3000);
                }

                if (!this._store.getState().isAdPlaying) {
                    this._actions.playAd();
                }
            }
            try {
                this.trigger(this._wrapper, 'onPlayerStateChange', {
                    isPlaying: true,
                    isFullscreen: this._playbackManager.fullscreen
                });
            } catch (e) {
                this.log(e);
            }

            if (!this._store.getState().isAdMode) {
                this._actions.startAdMode();
            }

            this._actions.updateAdDuration(currentAd.getDuration());
            this.trackAdStarted(currentAd);
        } else {
            this._playbackManager.play();
        }
    },

    handleLiveUpdates() {
        if (!this._liveStateController) {
            console.error('liveStateController is not initialized');
            return;
        }

        const channelID = this._store.getState().videoData.mediaLiveChannelId;
        if (!channelID) {
            console.error('mediaLiveChannelId was not found');
            return;
        }

        this._liveStateController.getStatus(channelID).then((status) => {
            let prevState = status.state;
            if (prevState === 'RUNNING' && this._store.getState().videoData.state === 'future') {
                // channel is already running and we're not live yet
                this.onLiveStateChanged('live');
            }

            this._liveStateController.subscribe(channelID, (data) => {
                const newState = data.state;
                const videoState = this._store.getState().videoData.state;
                if (prevState !== 'RUNNING' && newState === 'RUNNING' && videoState !== 'live') {
                    // encoder is now running and event isn't live yet
                    this.onLiveStateChanged('live');
                } else if (
                    prevState !== 'IDLE' &&
                    newState === 'IDLE' &&
                    (videoState !== 'processing' || videoState !== 'vod')
                ) {
                    // encoder has stopped and event is still live
                    if (this._store.getState().hasAutoplayed) {
                        // prevent live stream from restarting
                        this._actions.setAutoplay(false);
                    }
                    // TODO: Need to update the video src to the non live url
                    this.onLiveStateChanged('processing');
                    this._liveStateController.unsubscribe();
                }
                prevState = newState;
            });
        });
    },

    trackAdStarted: function (currentAd) {
        this._tracker.trackAdStarted(currentAd);
    },

    trackShare: function (shareType) {
        if (shareType === 'Facebook' || shareType === 'Twitter') {
            this._tracker.trackShare(shareType);
        }
    },

    trackMoreVideosSlide: function (evt) {
        this._tracker.trackMoreVideosSlide();
    },

    trackEndscreenSlide: function (evt) {
        this._tracker.trackEndscreenSlide();
    },

    onAdSkipped: function () {
        this._tracker.trackAdSkipped();
        this.onAdRemoved();
    },

    onAdComplete: function (evt) {
        const currentAd = evt.detail.imaAd;
        this.onAdRemoved();
        const { adPlaysCount } = this._store.getState();
        const newCount = adPlaysCount > 3 ? 1 : adPlaysCount + 1;
        this._actions.updateAdPlaysCount(newCount);

        utils.setCookie('djadplaynum', newCount.toString());
        this.trigger(this._wrapper, 'adComplete');

        this._tracker.trackAdComplete(currentAd);
    },

    onAdResumed: function () {
        const { isAdMode, isAdPlaying } = this._store.getState();
        if (isAdMode) {
            if (!isAdPlaying) {
                this._actions.playAd();
            }
        }

        this.showControls(3000);

        this._tracker.trackAdPlay();

        try {
            this.trigger(this._wrapper, 'onPlayerStateChange', {
                isPlaying: true,
                isFullscreen: this._playbackManager.fullscreen
            });
        } catch (e) {
            this.log(e);
        }
    },

    onAdPaused: function () {
        const { isAdMode, isAdPlaying } = this._store.getState();
        if (isAdMode) {
            if (isAdPlaying) {
                this._actions.pauseAd();
            }

            this._tracker.trackAdPause();
        }

        this.showControls(3000);

        try {
            this.trigger(this._wrapper, 'onPlayerStateChange', {
                isPlaying: false,
                isFullscreen: this._playbackManager.fullscreen
            });
        } catch (e) {}
    },

    onAdProgress(e) {
        const { currentTime, duration, imaAd } = e.detail;
        if (this._store.getState().currentAdPosition !== currentTime) {
            this._actions.updateAdProgress(currentTime);

            const adProg = currentTime / duration;
            this._tracker.trackAdProgress(adProg, imaAd);

            try {
                this.trigger(this._wrapper, 'onAdTimeUpdate', {
                    percent: adProg,
                    duration,
                    time: currentTime
                });
            } catch (e) {}
        }
    },

    onAdRemoved: function () {
        if (this._store.getState().isAdMode) {
            this._actions.stopAdMode();
        }

        if (this._store.getState().isClickForSoundVisible) {
            this.showHideClosedCaptions(true, false);
        } else {
            const cc = utils.getCookie('djvideocaptions');
            if (cc == 1) this.showHideClosedCaptions(true, false);
        }

        this._tracker.trackAdRemoved();
    },

    onAdLoaded: function (e) {
        const currentAd = e.detail;
        this.trigger(this._wrapper, 'adLoaded', currentAd);
    },

    onContentPauseRequested: function () {
        // race condition where IMA calls pause before the video.play promise completes
        if (this._playbackManager.readyState !== 0) this._playbackManager.pause();
        if (!this._store.getState().isAdMode) {
            this._actions.startAdMode();
        }
    },

    onContentResumeRequested: function () {
        this.onAdRemoved();
    },

    onAdError: function (e) {
        const omnitureErrorObj = e.detail;
        this.log('AdError', e);

        if (this._store.getState().adInitState === loadState.STARTED) {
            this._actions.setAdInitState(loadState.FAILED);
        }

        if (this._adErrorTracked) {
            this._adErrorEvent = e;
            return;
        }

        this._adErrorTracked = true;

        this.trigger(this._wrapper, 'adError', omnitureErrorObj);
        console.warn(`Video ad error: ${omnitureErrorObj.code} ${omnitureErrorObj.msg}`);

        this._tracker.trackError('Ad_' + omnitureErrorObj.msg);
        addPageAction('video-ad-error', {
            'video-ad-error-type': omnitureErrorObj.type,
            'video-ad-error-code': omnitureErrorObj.code,
            'video-ad-error-msg': omnitureErrorObj.msg,
            'video-ad-id': omnitureErrorObj.ad?.id
        });
    },

    // COMMANDS //

    toggleFullScreen: function (e) {
        this._playbackManager.setFullscreen(!this._playbackManager.fullscreen);
    },

    playPause: function (e) {
        const { isAdMode, isAdPlaying, isPlaying: isContentPlaying } = this._store.getState();
        const isPlaying = isAdMode ? isAdPlaying : isContentPlaying;
        if (!isPlaying) {
            this._playbackManager.play()?.catch(() => '');
            this._playRequested = true;
        } else {
            this._playbackManager.pause();
            this._playRequested = false;
        }
    },

    // EXTERNAL
    eResumeVideo: function (e) {
        this._playRequested = true;
        if (!this._contentInitialized) {
            this.startVideo();
        } else {
            this._playbackManager.play();
        }
    },

    eSeek: function (tm, perc) {
        var sec = tm;

        if (perc) sec = Math.round(this._store.getState().duration * (tm / 100));

        if (!this._store.getState().isAdMode && this._contentInitialized) {
            if (sec + 5 < this._playbackManager.duration) this._playbackManager.currentTime = sec;
        } else {
            this._actions.updateStartPosition(parseInt(tm));
            if (!this._contentInitialized) this.startVideo();
        }
    },

    ePauseVideo: function (e) {
        this._self._playRequested = false;
        if (!this._self._playbackManager.paused) {
            this._self._playbackManager.pause();
        }
    },

    ePlayPauseVideo: function (e) {
        if (!this._self._contentInitialized && !this._self._playRequested) {
            this._self.startVideo();
        } else {
            this._self.playPause(e);
        }
    },

    eKillVideo: function (e) {
        this._self._playbackManager.pause();
    },

    eIsPlaying: function () {
        const { isAdMode, isAdPlaying } = this._self._store.getState();
        if (!this._self._playbackManager.paused || (isAdMode && isAdPlaying)) {
            return true;
        } else {
            if (isAdMode && this._self._playRequested) return true;
            else return false;
        }
    },

    ePlayVideo: async function (param) {
        this.log('External play', param);

        if (!isNaN(parseInt(param, 10))) {
            this._actions.updateStartPosition(parseInt(param));
            this.eResumeVideo();
            return;
        }

        if (typeof param?.guid !== 'string') {
            // Maybe we should throw here? I didn't want to potentially break existing code
            this.log('Cannot play video without a guid');
            return;
        }

        if (typeof param?.startAction === 'string') {
            this._actions.updateFormatOverride(param.startAction);
        }

        if (
            typeof param?.userInteracted === 'boolean' &&
            param.userInteracted &&
            this._store.getState().isClickForSoundVisible
        ) {
            // There was a user interaction to play this video so we should unmute when click for sound is visible
            this.muteUnMute(false, false, false);
        }

        // Check for updated settings
        if (param?.settings && typeof param.settings === 'object') {
            if (this._settings.enableAutoplayMutedBehavior !== param.settings.enableAutoplayMutedBehavior) {
                // Autoplay muted behavior has changed so we need to update some extra state
                if (param.settings.enableAutoplayMutedBehavior) {
                    this._actions.setAutoplay('muted');
                    this.muteUnMute(true, false, false);
                } else {
                    this.muteUnMute(false, false, false);
                }
            }

            // Autoplay settings has changed so update the store value
            if (this._store.getState().autoplay !== param.settings.autoplay) {
                this._actions.setAutoplay(param.settings.autoplay);
            }

            // Merge new settings
            Object.assign(this._settings, param.settings);
        }

        this._settings.guid = param.guid;
        // Update the guid in the video data so things like the endscreen update quicker
        this._actions.updateVideoData({ ...this._store.getState().videoData, guid: param.guid });

        this._playbackAbort?.abort();
        this._playbackManager.stop();
        try {
            const start = Date.now();
            // Wait for playback to completely stop before moving on. This fixes a few race conditions when switching videos
            await waitForStateUpdate(
                this._store,
                (state) => !state.isPlaying && !state.isAdPlaying && !state.isPlaybackInitializing,
                { timeout: 5000 }
            );
            this.log(`Waited for video to stop. Took ${Date.now() - start}ms`);
        } catch (e) {
            console.error('Failed to wait for video to stop', e);
        }

        this._playbackAbort = new AbortController();
        try {
            await this.loadVideo({ signal: this._playbackAbort.signal });
            this.startVideo({ signal: this._playbackAbort.signal });
        } catch (e) {
            console.error('Failed to load video', e);
        }
    },

    eLoadVideo: function (d) {
        this._self._settings.guid = d.guid;
        if (
            !this._self._contentInitialized &&
            this._self._isMobile &&
            document.location.href.indexOf('wsj.com/video') !== -1
        ) {
            window.location.href = d.linkURL;
        } else {
            this._self.loadVideo().then(() => this.startVideo());
            if (this._self._store.getState().isSharescreenVisible) this._self._actions.hideShareScreen();
        }
    },

    eUpdatePlaylist: function (p) {
        let playlist;
        try {
            if (p.items && typeof p.items != 'undefined') {
                playlist = p.items;
            } else {
                playlist = p;
            }
        } catch (e) {
            playlist = p;
        }
        this._self._actions.replacePlaylist(playlist);
    },

    eMute: function (m) {
        this._self.muteUnMute(m, false, true);
    },

    eGetStatus: function () {
        return {
            isPlaying: this._self.eIsPlaying(),
            isFullscreen: this._self._playbackManager.fullscreen
        };
    },

    displayVrMsg: function () {
        this._actions.hideControls();
        this._actions.hideLoadingSpinner();
        const clonedWrapper = this._wrapper.cloneNode(true);
        this._wrapper.replaceWith(clonedWrapper);
        this._wrapper.innerHTML = `<div class="vr-alert"><p>360 videos are no longer supported.</p></div>`;
    },

    // TRACKING //

    trackInit: function () {
        trackView(this._videodata.guid, this._settings.larsId);
        this._tracker.trackInit();
    },

    trackContentStarted: function () {
        this._contentStartTracked = true;
        this._tracker.trackContentStart();
    },

    trackProgress: function (perc, tm) {
        if (!this._store.getState().isAdMode) {
            if (tm > 1 && !this._contentStartTracked) {
                this.trackContentStarted();
            }
            this._tracker.trackProgress(perc, tm);
        }

        if (Math.floor(tm / 15) > this._pingMilestone) {
            this._pingMilestone = Math.floor(tm / 15);
        }

        this.trigger(this._wrapper, 'onTimeUpdate', {
            percent: perc,
            time: tm,
            duration: this._store.getState().duration
        });
    },

    // END TRACKING FUNCTIONS //
    // UTILITY //

    trackInView: function () {
        const isPremiumPaywall = !utils.checkSubscriberStatusForPremiumVideo(
            this._store.getState().videoData.isSubscriberOnly ||
                this._store.getState().videoData.doctypeID === '227' ||
                this._store.getState().videoData.doctypeID === '30133'
        );
        if (isPremiumPaywall) {
            // only track if video is premium
            var distance = this._container.getBoundingClientRect();
            var rtn =
                distance.top >= 0 &&
                distance.left >= 0 &&
                distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                distance.right <= (window.innerWidth || document.documentElement.clientWidth);
            if (rtn) {
                window.removeEventListener('scroll', this._trackInViewListenerReference);
                this._tracker.trackInView();
            }
            return rtn;
        }
        return false;
    },

    trigger: function (obj, evt, data) {
        try {
            const evObj = new CustomEvent(evt, { bubbles: true, cancelable: true, detail: data });
            evObj.data = data;
            obj.dispatchEvent(evObj);
        } catch (err) {
            this.log(`Failed to dispatch event: ${err.message}`);
        }
    },

    createMark: function (name) {
        utils.createMark(name);
    }
};

export default WSJVideo;
