import { h, Component } from 'preact';
import { connect } from 'react-redux';
import styles from './styles.css';

class Title extends Component {
    render() {
        const { isControlsVisible, isCountdownVisible, isAdMode, isLive, isClickForSoundVisible, videoData } =
            this.props;

        const isVertical = videoData.aspectRatio < 1;

        if (isClickForSoundVisible && !isVertical) return;

        const classes = [
            styles.videoTitleContainer,
            isControlsVisible && !isCountdownVisible && !isAdMode ? 'video-fade-in' : 'video-fade-out',
            isVertical ? styles.verticalTitleContainer : ''
        ];
        return (
            <div id={`video-title-${this.props.videoId}`} className={classes.join(' ')}>
                <div className={styles.videoTitle}>{this.props.name}</div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isControlsVisible: state.isControlsVisible,
        name: state.videoData.name,
        isCountdownVisible: state.isCountdownVisible,
        isAdMode: state.isAdMode,
        isLive: state.videoData.state == 'live',
        isClickForSoundVisible: state.isClickForSoundVisible,
        videoData: state.videoData
    };
}

const ConnectedTitle = connect(mapStateToProps)(Title);

export default ConnectedTitle;
